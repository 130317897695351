import CheckOut from "../scenes/CheckOut/services/CheckOut.service";
import analytics from "./analytics";

const API_DOMAIN = {
  DEV: "smu-labs.cl",
  PROD: "smu-service.cl",
};

const TIME_CHECKOUT = 3600;

const ENVIRONMENTS = {
  PROD: "production",
  DEV: "development",
  LOCAL: "local",
  STG: "staging",
};

const API_ROUTES = {
  REFACTOR: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://refactor-checkout-backend.smu-labs.cl",
    [ENVIRONMENTS.DEV]: "https://refactor-checkout-backend.smu-labs.cl",
    [ENVIRONMENTS.PROD]: "https://api-checkout.unimarc.cl",
  },
  BFF: {
    error: "error",
    [ENVIRONMENTS.LOCAL]:
      "https://bff-unimarc-web.smu-labs.cl/bff-unimarc-web/v1",
    [ENVIRONMENTS.DEV]:
      "https://bff-unimarc-web.smu-labs.cl/bff-unimarc-web/v1",
    [ENVIRONMENTS.PROD]: "https://api-checkout.unimarc.cl/bff-unimarc-web/v1",
    [ENVIRONMENTS.STG]:
      "https://bff-unimarc-web-stg.unimarc.cl/bff-unimarc-web/v1",
  },
  BFFV2: {
    error: "error",
    [ENVIRONMENTS.LOCAL]:
      "https://bff-unimarc-web-v2.smu-labs.cl/bff-api/checkout",
    [ENVIRONMENTS.DEV]:
      "https://bff-unimarc-web-v2.smu-labs.cl/bff-api/checkout",
    [ENVIRONMENTS.PROD]: "https://bff-unimarc-web.unimarc.cl/bff-api/checkout",
    [ENVIRONMENTS.STG]:
      "https://bff-unimarc-web-v2-stg.unimarc.cl/bff-api/checkout",
  },
  BFF_BROWSE_V2: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://bff-unimarc-web-v2.smu-labs.cl/bff-api",
    [ENVIRONMENTS.DEV]: "https://bff-unimarc-web-v2.smu-labs.cl/bff-api",
    [ENVIRONMENTS.PROD]: "https://bff-unimarc-web.unimarc.cl/bff-api",
    [ENVIRONMENTS.STG]: "https://bff-unimarc-web-v2-stg.unimarc.cl/bff-api",
  },

  PHILHARMONIC: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://api-philharmonic-mdw.smu-labs.cl",
    [ENVIRONMENTS.DEV]: "https://api-philharmonic-mdw.smu-labs.cl",
    [ENVIRONMENTS.PROD]: "https://api-philharmonic-mdw.smu-labs.cl",
  },

  KLAP_PAYMENT_SCRIPT: {
    error: "error",
    [ENVIRONMENTS.LOCAL]:
      "https://pagos-pasarela-sandbox.mcdesaqa.cl/checkout-frictionless/sandbox/v1/main.min.js",
    [ENVIRONMENTS.DEV]:
      "https://pagos-pasarela-sandbox.mcdesaqa.cl/checkout-frictionless/sandbox/v1/main.min.js",
    [ENVIRONMENTS.STG]:
      "https://pagos.pasarela.multicaja.cl/checkout-frictionless/v1/main.min.js",
    [ENVIRONMENTS.PROD]:
      "https://pagos.pasarela.multicaja.cl/checkout-frictionless/v1/main.min.js",
  },
  CONTENFUL: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://cdn.contentful.com/spaces",
    [ENVIRONMENTS.DEV]: "https://cdn.contentful.com/spaces",
    [ENVIRONMENTS.STG]: "https://cdn.contentful.com/spaces",
    [ENVIRONMENTS.PROD]: "https://cdn.contentful.com/spaces",
  },
};
const UNIMARC_ROUTES = {
  DESA_HOME: "https://fe-browse-unimarc.smu-labs.cl/",
  PROD_HOME: "https://unimarc.cl/",
  DESA_CART: "https://fe-cart-unimarc.smu-labs.cl/",
  PROD_CART: "https://carrito.unimarc.cl/",
  DESA_CART_SL: "https://fe-cart-unimarc.smu-labs.cl/?sl=true",
  PROD_CART_SL: "https://carrito.unimarc.cl/?sl=true",
  DESA_ORDERPLACED: "https://fe-orderplaced-unimarc.smu-labs.cl/",
  PROD_ORDERPLACED: "https://orderplaced.unimarc.cl/",
};

const DAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const SECTIONS = {
  PROFILE: "profile",
  TOTALIZER: "totalizer",
  PAYMENT: "payment",
  SHIPPING: "shipping",
};

const getFullElementHeight = (elem: any, parent?: any) => {
  const root = parent === undefined ? document : parent;
  elem = typeof elem === "string" ? root.querySelector(elem) : elem;

  let styles = getComputedStyle(elem),
    margin =
      parseFloat(styles["marginTop"] || "") +
      parseFloat(styles["marginBottom"] || "");

  return Math.ceil(elem.offsetHeight + margin);
};
const isNull = (element: any) => element === null;
const isUndefined = (element: any) => element === undefined;
const isNullOrUndefined = (element: any) =>
  element === undefined || element === null;
const capitalize = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

const timeFilter = (date: Date) => {
  return `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}
  `;
};

/**
 * Obtains the card type from the card number.
 * @param {string} cardNum - Card number.
 * @returns {string} Card type (VISA, MASTERCARD, MAESTRO) or empty string if no valid type is found.
 */
const getCardType = (cardNum) => {
  let payCardType = "";

  if (cardNum.startsWith("4")) {
    payCardType = "VISA";
  } else if (cardNum.startsWith("5")) {
    payCardType = "MASTERCARD";
  } else {
    let regexMap = [
      { regEx: /^4[0-9]{5}/gi, cardType: "VISA" },
      { regEx: /^5[1-5][0-9]{4}/gi, cardType: "MASTERCARD" },
      { regEx: /^(5[06-8]\d{4}|6\d{5})/gi, cardType: "MAESTRO" },
    ];

    for (const { regEx, cardType } of regexMap) {
      if (cardNum.match(regEx)) {
        payCardType = cardType;
        break;
      }
    }
  }

  return payCardType;
};

const timeUTCFilter = (date: Date) => {
  return `${("0" + date.getUTCHours()).slice(-2)}:${(
    "0" + date.getUTCMinutes()
  ).slice(-2)}
  `;
};

const getMonthNameFilter = (date: Date) => {
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  return monthNames[date.getUTCMonth()];
};

function priceFilter(n: any) {
  if (n === 0) return 0;
  if (!n) return null;
  var numParts: any = n.toString().slice(0, -2).split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numParts.join(".");
}

function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (let value of ca) {
    var c = value;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(
  cname: string,
  cvalue: string,
  exdays = 360,
  domain = "",
  props = {
    minutes: 0,
  }
) {
  const { minutes } = props;
  var d = new Date();
  if (minutes > 0) {
    d.setTime(d.getTime() + minutes * 60 * 1000);
  } else {
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  }
  var expires = "expires=" + d.toUTCString();
  var stringDomain = "";
  if (domain) {
    stringDomain = ";domain=" + domain;
  }
  console.log(
    "debug set cookie",
    cname + "=" + cvalue + ";" + expires + stringDomain + ";path=/"
  );
  document.cookie =
    cname + "=" + cvalue + ";" + expires + stringDomain + ";path=/";
}

const getOrderformId = () => {
  return getCookie("co_orderform_id") || "ca6799d06fdd4ba1997e41ec0d1d2071";
};

function rutFilter(rut: any) {
  var sRut1 = rut; //contador de para saber cuando insertar el . o la -
  var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
  var sInvertido = ""; //Guarda el resultado final del rut como debe ser
  var sRut = "";
  for (var i = sRut1.length - 1; i >= 0; i--) {
    sInvertido += sRut1.charAt(i);
    if (i === sRut1.length - 1) sInvertido += "-";
    else if (nPos === 3) {
      sInvertido += ".";
      nPos = 0;
    }
    nPos++;
  }
  for (var j = sInvertido.length - 1; j >= 0; j--) {
    if (
      sInvertido.charAt(sInvertido.length - 1) !== "." ||
      j !== sInvertido.length - 1
    )
      sRut += sInvertido.charAt(j);
  }
  //Pasamos al campo el valor formateado
  return sRut.toUpperCase();
}

function rutUnfilter(rut: string) {
  if (!rut) return "";
  return rut.replace(/\.|-/g, "");
}

function checkRut(rut: any) {
  // Divide el valor ingresado en dígito verificador y resto del RUT.
  if (!rut) return false;
  let valor = rut;
  let cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();
  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (cuerpo.length < 7) {
    return false;
  }

  // Calcular Dígito Verificador "Mét-odo del Módulo 11"
  let suma = 0;
  let multiplo = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= cuerpo.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente
    let index = multiplo * valor.charAt(cuerpo.length - i);

    // Sumar al Contador General
    suma = suma + index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiplo < 7) {
      multiplo = multiplo + 1;
    } else {
      multiplo = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  let dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  dv = dv === "K" ? 10 : dv;
  dv = dv === 0 ? 11 : dv;
  // Validar que el Cuerpo coincide con su Dígito Verificador
  if (dvEsperado !== Number(dv)) {
    return false;
  } else {
    return true;
  }
}
const cardNumberFilter = (cardNumber: string) => {
  if (!cardNumber) return 0;
  cardNumber = cardNumber.replace(/X/g, "").trim();
  return `**** ${cardNumber}`;
};

const withoutAccent = (string: string) => {
  if (!string) return "";
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const redirectToHome = () => {
  let redirectUrl = IS_PROD
    ? UNIMARC_ROUTES.PROD_HOME
    : UNIMARC_ROUTES.DESA_HOME;
  window.location.href = redirectUrl;
};

const redirectToHomeTokenExpired = () => {
  let redirectUrl = IS_PROD
    ? UNIMARC_ROUTES.PROD_HOME
    : UNIMARC_ROUTES.DESA_HOME;
  window.location.href = redirectUrl + "?logoutSession=true";
};

const redirectToCart = () => {
  analytics.interactiveEvent({
    action: "volver-carro",
    category: "checkout",
    event: "interacciones_checkout",
    label: "volver-carro-desde-checkout",
  });
  let redirectUrl = IS_PROD
    ? UNIMARC_ROUTES.PROD_CART
    : UNIMARC_ROUTES.DESA_CART;
  window.location.href = redirectUrl;
};

const redirectToCartWithSL = () => {
  let redirectUrl = IS_PROD
    ? UNIMARC_ROUTES.PROD_CART_SL
    : UNIMARC_ROUTES.DESA_CART_SL;
  window.location.href = redirectUrl;
};

const addCard = () => {
  const payload: any = {
    channel: "unimarc-web",
    inscriptionErrorUrl: `${window.location.origin}?registration=error`,
    inscriptionSuccessUrl: `${window.location.origin}?registration=ok`,
  };

  CheckOut.addCard(payload).then((response: any) => {
    const newWindow: any = window.open("", "_self");
    newWindow.document.write(response);
    newWindow.document.close();
    if (newWindow?.document?.f) {
      newWindow?.document?.f?.submit();
    }
  });
  return true;
  // addCard
};

const setStorage = (key: any, value: any, expires: any) => {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  } else {
    expires = Math.abs(expires); //make sure it's positive
  }

  var now = Date.now(); //millisecs since epoch time, lets deal only with integer
  var schedule: any = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
};
const removeStorage = (name: any) => {
  try {
    localStorage.removeItem(name);
    localStorage.removeItem(name + "_expiresIn");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        name +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
};

const getStorage = (key: any) => {
  var now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  var expiresIn: any = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      var value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
};

const validateQueryParam = (param: string): boolean => {
  let queryParams = new URLSearchParams(window.location.search);
  return Boolean(queryParams.get(param));
};

const formatText = (text: string): string => {
  if (!text) {
    return null;
  }

  return text?.replace(" ", "")?.toLocaleLowerCase();
};

const IS_PROD =
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "staging";

const REDIRECT_URL_HOME = IS_PROD
  ? UNIMARC_ROUTES.PROD_HOME
  : UNIMARC_ROUTES.DESA_HOME;

export default {
  addCard,
  capitalize,
  cardNumberFilter,
  checkRut,
  getCardType,
  getCookie,
  getFullElementHeight,
  getMonthNameFilter,
  getOrderformId,
  getStorage,
  isNull,
  isNullOrUndefined,
  isUndefined,
  priceFilter,
  redirectToCart,
  redirectToCartWithSL,
  redirectToHome,
  redirectToHomeTokenExpired,
  removeStorage,
  rutFilter,
  rutUnfilter,
  setCookie,
  setStorage,
  timeFilter,
  timeUTCFilter,
  validateQueryParam,
  withoutAccent,
  formatText,
  API_DOMAIN,
  ENVIRONMENTS,
  DAYS,
  SECTIONS,
  API_ROUTES,
  UNIMARC_ROUTES,
  TIME_CHECKOUT,
  REDIRECT_URL_HOME,
  IS_PROD,
};
