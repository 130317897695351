import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import CheckOut from '../scenes/CheckOut'
import { Klap } from '../scenes/Klap'
import Notfound from '../scenes/NotFound'


export default function Routes() {

  return (
    <BrowserRouter>
    <>
      <Switch>
        <Route
          component={CheckOut}
          exact
          path='/'
        />
        <Route
          component={Klap}
          exact
          path='/klap'
        />
        <Route  component={Notfound} />
      </Switch>
    </>
    </BrowserRouter>
  )
}
