import React, { useContext, useEffect } from "react";
import { OrderformContext } from "../contexts/orderform.provider";
import CheckOut from "../services/CheckOut.service";
import helpers from "../../../utils/helpers";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import "./without-stock.styles.scss";

const WithoutStock = (props: any) => {
  const {
    orderform,
    orderform: {
      exceptions,
      totalizerData,
      paymentData,
      shippingData: {
        logisticsInfo
      }},
  } = useContext(OrderformContext);
  const { isOpen, setIsOpen, isPay, isOpenWin, setIsOpenWin, flagWindow, selectedCard } = props;


  let withoutStockException =
    exceptions?.length > 0
      ? exceptions?.find((exception: any) => exception.code === "0001")
      : null;
  let windowsException =
    exceptions?.length > 0
      ? exceptions?.find((exception: any) => exception.code === "0005")
      : null;
  let itemsWithoutStock = withoutStockException?.data || [];

  const handleClose = async () => {
    let giftCardVariant = paymentData?.giftCards
      ? paymentData.giftCards.filter((x: any) => x.inUse === true)[0]
        ? paymentData.giftCards.filter((x: any) => x.inUse === true)[0].inUse
        : false
      : false;

    setIsOpen(false);
    if (isPay) {
      if (selectedCard === 'webpay') {
        await CheckOut.generateTransaction({ giftCardVariant: false })
        .then(async (responseTransaction: any) => {
          if (responseTransaction.url) return window.location.href = responseTransaction.data.url;
        })
        .catch((error) => console.log("Fallo al realizar pago"));
      } else {
        const transactionParams = {
          deliveryWindow: logisticsInfo[0]?.slas[0]?.deliveryWindow,
          value: totalizerData?.totalPayment?.total
        }
        const transactionHeaders = giftCardVariant ? 'giftcard' : 'one-click'
        await CheckOut.generateTransactionV2(transactionParams, transactionHeaders)
        .then((responseTransaction) => {
          const orderNumber = responseTransaction?.data?.orderId
          if (orderNumber) {
            if (giftCardVariant) helpers.removeStorage("giftCardStorage");
            const orderPlaceUrl = helpers.IS_PROD
              ? helpers.UNIMARC_ROUTES.PROD_ORDERPLACED
              : helpers.UNIMARC_ROUTES.DESA_ORDERPLACED;
            window.location.href = `${orderPlaceUrl}?og=${orderNumber.split('-')[0]}`
          }
        })
        .catch((error) => console.log("Fallo al realizar pago"));
      }
    }
  };

  const handleClosewindow = async () => {
    setIsOpenWin(false);
  };

  const handleSubmitSecond = () => {
    let redirectUrl = helpers.IS_PROD
      ? helpers.UNIMARC_ROUTES.PROD_CART
      : helpers.UNIMARC_ROUTES.DESA_CART;
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    if (withoutStockException && !windowsException) {
      setIsOpen(true);
    }
    if (windowsException && flagWindow) {
      setIsOpenWin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderform]);

  return (
    <>
      <AlertDialog
        title="Cambio en productos"
        isOpen={isOpen}
        handleSubmit={handleClose}
        handleSubmitSecond={handleSubmitSecond}
        btnText="Continuar sin estos productos"
        btnTextSecond="Ir al carro"
      >
        <div className="without-stock">
          <p className="without-stock__notice-text">
            Hemos notado que hubo cambios en los productos de tu carro.
          </p>
          <div className="horizontal-line" style={{ margin: "5px 0" }} />
          <ul className="without-stock__ul">
            {itemsWithoutStock?.map((items: any, index: any) => {
              return (
                <li key={index} className="without-stock__li">
                  <div className="without-stock__text">
                    {items}
                  </div>
                  <div
                    className="horizontal-line"
                    style={{ margin: "5px 0" }}
                  />
                </li>
              );
            })}
          </ul>
          <div className="without-stock__total">
            <div className="text">Nuevo Total</div>
            <div className="price">
              ${helpers.priceFilter(totalizerData?.totalPayment?.total)}
            </div>
          </div>
        </div>
      </AlertDialog>

      <AlertDialog
        title="El horario de despacho seleccionado ya no se encuentra disponible"
        isOpen={isOpenWin}
        btnText="Aceptar"
        handleSubmit={handleClosewindow}
        handleClose={handleClosewindow}
      >
        Lo sentimos, el horario que elegiste para despachar tu compra ya no se
        encuentra disponible. Por favor, intenta seleccionando otro horario.
      </AlertDialog>
    </>
  );
};

export default WithoutStock;
