import helpers from "../../../utils/helpers";

const Header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const API_GATEWAY =
  helpers.API_ROUTES.BFF[process.env.REACT_APP_ENVIRONMENT || "error"];
const API_GATEWAY_V2 =
  helpers.API_ROUTES.BFFV2[process.env.REACT_APP_ENVIRONMENT || "error"];
const API_BFF_V2 =
  helpers.API_ROUTES.BFF_BROWSE_V2[
    process.env.REACT_APP_ENVIRONMENT || "error"
  ];
const CONTENFUL =
  helpers.API_ROUTES.CONTENFUL[process.env.REACT_APP_ENVIRONMENT || "error"];

const ToolsGet: any = {
  method: "GET",
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header),
};
const ToolsDelete: any = {
  method: "DELETE",
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header),
};
const ToolsPatch: any = {
  method: "PATCH",
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header),
};
const ToolsPost: any = {
  method: "POST",
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header),
};
const ToolsPut: any = {
  method: "PUT",
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header),
};

const CheckOut = {
  async ApiCheckout() {
    const toolsRequest = Object.assign({}, ToolsGet);
    toolsRequest.headers.append("source", "web");
    // toolsRequest.headers.append('store','web')

    return fetch(API_GATEWAY + "/checkout", toolsRequest).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async getCardsFromKlap() {
    return fetch(API_GATEWAY_V2 + "/klap/cards", ToolsGet).then(
      (response: any) => {
        if (response.status === 200) {
          return response.json();
        }
        return [];
      }
    );
  },

  async updatePhone(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/clientProfileData", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },
  async ApiCheckoutCardTransaction({
    pPayload,
    method = "one-click",
    payment_method = "oneclick-debit",
  }) {
    const toolData = Object.assign({}, ToolsPost);
    toolData.body = JSON.stringify(pPayload);
    toolData.headers = new Headers({
      ...Header,
      source: "1",
      method,
      payment_method,
    });

    return fetch(API_GATEWAY_V2 + "/transaction", toolData).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async updateDeliveryWindow(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/updateShipping", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async getStatusGiftCard(pPayload: any = {}) {
    const { rut } = pPayload || {};
    return fetch(
      `${API_GATEWAY_V2}/giftcard-retries-status/${rut}`,
      ToolsGet
    ).then((response: any) => {
      return response.json();
    });
  },

  async updateReceiver(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/receiverName", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async addCard(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/oneCLick/addCard", ToolsPost).then(
      (response: any) => {
        return response.text();
      }
    );
  },

  async deleteCard(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/oneClick", ToolsPost).then((response: any) => {
      return response.json();
    });
  },

  async updateSelectedPaymentType(pPayload: any) {
    pPayload = {
      ...pPayload,
      orderForm: helpers.getOrderformId(),
    };
    if (pPayload["idCard"] && pPayload["idCard"]?.length < 4)
      delete pPayload["idCard"];

    ToolsPost.body = JSON.stringify(pPayload);

    return fetch(API_GATEWAY + `/payment/selectedPaymentType`, ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async updateBill(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + `/bill/add`, ToolsPost).then((response: any) => {
      return response.json();
    });
  },

  async deleteBill() {
    return fetch(API_GATEWAY + `/bill/delete`, ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async generateTransaction(Payload: any) {
    ToolsPost.body = JSON.stringify(Payload);
    return fetch(API_GATEWAY + `/generate-transaction`, ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async updatePayment(Payload: any) {
    ToolsPatch.body = JSON.stringify(Payload);
    return fetch(API_GATEWAY_V2 + `/attachments/paymentData`, ToolsPatch).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async generateTransactionV2(Payload: object, paymentMethod: string) {
    const toolData = Object.assign({}, ToolsPost);
    toolData.body = JSON.stringify(Payload);
    toolData.headers.append("source", "1");
    toolData.headers.append("method", paymentMethod);

    return fetch(API_GATEWAY + `/generate-transaction/v2`, toolData).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async generateCoupon(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/coupon", ToolsPost).then((response: any) => {
      return response.json();
    });
  },

  async giftCardBalance(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard/balance", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async giftCardPayment(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard", ToolsPost).then((response: any) => {
      return response.json();
    });
  },

  async deleteGiftCard(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard/deleteGiftCard", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async deleteGiftCardV1({ orderFormId, gifcardId }: any) {
    return fetch(
      API_GATEWAY + `/giftcard/${orderFormId}/${gifcardId}`,
      ToolsDelete
    );
  },

  async regenerateOrderForm(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY_V2 + "/regenerate-orderform", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async getOrderForm() {
    return fetch(API_BFF_V2 + "/orderform", ToolsGet).then((response: any) => {
      if (response.status === 200) {
        return response.json();
      }
      return {};
    });
  },

  async updateCriteriaItems(pPayload: any) {
    const formmatedBody = {
      items: pPayload,
      substituteType: "2",
    };
    ToolsPut.body = JSON.stringify(formmatedBody);

    return fetch(API_GATEWAY_V2 + `/custom-data/substitution`, ToolsPut).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async getInstallments() {
    const {
      REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      REACT_APP_CONTENTFUL_BASEURL,
      REACT_APP_CONTENTFUL_SPACE_ID,
      REACT_APP_CONTENTFUL_ENVIRONMENT,
      REACT_APP_CONTENTFUL_INSTALLMENTS,
    } = process.env;
    const getOptions = {
      //...ToolsGet,
      headers: new Headers({
        ...Header,
        Authorization: `Bearer ${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
      }),
    };
    return fetch(
      `${REACT_APP_CONTENTFUL_BASEURL}/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/${REACT_APP_CONTENTFUL_ENVIRONMENT}/entries?sys.id[in]=${REACT_APP_CONTENTFUL_INSTALLMENTS}`,
      getOptions
    ).then((response: any) => {
      return response.json();
    });
  },
  async getOnOffCucardaBancoEstado() {
    const {
      REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      REACT_APP_CONTENTFUL_BASEURL,
      REACT_APP_CONTENTFUL_SPACE_ID,
      REACT_APP_CONTENTFUL_ENVIRONMENT,
      REACT_APP_CONTENTFUL_CUCARDA,
    } = process.env;
    const getOptions = {
      //...ToolsGet,
      headers: new Headers({
        ...Header,
        Authorization: `Bearer ${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
      }),
    };
    return fetch(
      `${REACT_APP_CONTENTFUL_BASEURL}/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/${REACT_APP_CONTENTFUL_ENVIRONMENT}/entries?sys.id[in]=${REACT_APP_CONTENTFUL_CUCARDA}`,
      getOptions
    ).then((response: any) => {
      return response.json();
    });
  },
  async getContentFullAssets(id: string) {
    const {
      REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      REACT_APP_CONTENTFUL_BASEURL,
      REACT_APP_CONTENTFUL_SPACE_ID,
      REACT_APP_CONTENTFUL_ENVIRONMENT_ASSETS,
    } = process.env;
    const getOptions = {
      //...ToolsGet,
      headers: new Headers({
        ...Header,
        Authorization: `Bearer ${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
      }),
    };
    return fetch(
      `${REACT_APP_CONTENTFUL_BASEURL}/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/${REACT_APP_CONTENTFUL_ENVIRONMENT_ASSETS}/assets/${id}`,
      getOptions
    ).then((response: any) => {
      return response.json();
    });
  },
  async getOrders() {
    return fetch(API_BFF_V2 + "/orders", ToolsGet).then((response: any) => {
      if (response.status === 200) {
        return response.json();
      }
      return {};
    });
  },
};

export default CheckOut;
