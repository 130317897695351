import React from 'react'
import './backbone.styles.scss'

export const Backbone = () => {
    return (
        <>
            <div className='card-loader card' title='loading' />
            <div style={{ height: 50 }} />
            <div className='card-loader-container__inputs'>
                <div className='card-loader' title='loading' style={{ height: 30, width: 300 }} />
                <div className='card-loader-container__inputs_flex'>
                    <div className='card-loader' title='loading' style={{ height: 30, width: 120 }} />
                    <div className='card-loader' title='loading' style={{ height: 30, width: 120 }} />
                </div>
                <div style={{ height: 50 }} />
            </div>
            <div className='card-loader' title='loading' style={{ height: 60, width: 300 }} />
            <div style={{ height: 30 }} />
            <div className='card-loader' title='loading' style={{ height: 50, width: 250 }} />
        </>
    )
}
