import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CoButton from "../Buttons/CoButton";
import { useWindowSize } from "../windowSize/use-window-size";
import "./alert-dialog.styles.scss";

const useStyles = makeStyles((theme) => ({
  md: {
    "& .MuiDialog-paper": {
      overflowY: 'unset',
      borderRadius: '12px',
    },
    "& .MuiDialog-paperWidthMd": {
      maxWidth: '516px'
    }
  },
  xs: {
    "& .MuiDialog-paper": {
      overflowY: 'unset',
      borderRadius: '12px',
    },
    "& .MuiDialog-paperWidthXs": {
      maxWidth: '328px'
    }
  },
  root: {
    "& .MuiDialog-paper": {
      margin: "20px",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px",
    },
    "&.MuiButtonBase-root": {
      float: "right",
    },
  },
}));

const errorIconStyle = {
  backgroundSize: "20px",
  height: "20px",
  marginRight: "1px",
  width: "40px",
};

const errorIconStyleMobile = {
  backgroundSize: "20px",
  height: "24px",
  marginRight: "10px",
  width: "30px",
};

const AlertDialog = (props: any) => {
  const {
    btnText,
    btnTextSecond,
    disabled = false,
    handleClose,
    handleSubmit,
    handleSubmitSecond,
    customTextTAC,
    isLoading,
    isOpen,
    disablePortal,
    modalType,
    title,
  } = props;
  const classes = useStyles();
  const isMobile = useWindowSize().width <= 711;

  const render = () => {
    switch (modalType) {
      case 'form':
        return (
          <Dialog
            onClose={handleClose}
            open={isOpen}
            disablePortal={disablePortal}
            maxWidth={isMobile ? "xs" : "md"}
            className={isMobile ? classes.xs : classes.md}
          >
            {!btnTextSecond ? (
              <div
                className="dialog-alert__close-icon"
                style={{ position: 'absolute', right: '5px', top: '5px' }}
              >
                <IconButton
                  onClick={handleClose}
                  style={{ float: "right", marginTop: handleClose ? "" : "20px" }}
                >
                  {handleClose && <CloseIcon />}
                </IconButton>
              </div>
            ) : null}
            <div className="dialog-alert">
              <div className="dialog-alert__content">
                {title && (
                  <div className="dialog-alert__title-section">
                    <div className={`dialog-alert__title-text ${customTextTAC && 'title-terms-and-conditions'}`}>{title}</div>
                  </div>
                )}
                <div className="dialog-alert__body-text">{props.children}</div>
              </div>
              {(btnText || btnTextSecond) && (
                <div className="dialog-alert__btn-wrapper">
                  {btnText && (
                    <CoButton
                      className='__radius btn_primary'
                      disabled={disabled}
                      handleClick={handleSubmit}
                      isLoading={isLoading}
                    >
                      {btnText}
                    </CoButton>
                  )}
                  {btnTextSecond && (
                    <CoButton
                      className='__radius'
                      handleClick={handleSubmitSecond}
                      isLoading={isLoading}
                      secondary
                    >
                      {btnTextSecond}
                    </CoButton>
                  )}
                </div>
              )}
            </div>
          </Dialog>
        )
      case 'alert':
        return (
          <Dialog
            onClose={handleClose}
            open={isOpen}
            maxWidth='xs'
            className={classes.xs}
            onClick={(e) => e.stopPropagation()}
          >
            <span className='error-icon'></span>
            {!btnTextSecond ? (
              <div
                className="dialog-alert__close-icon"
                style={{ position: 'absolute', right: '5px', top: '5px' }}
              >
                <IconButton
                  onClick={handleClose}
                  style={{ float: "right", marginTop: handleClose ? "" : "20px" }}
                >
                  {handleClose && <CloseIcon />}
                </IconButton>
              </div>
            ) : null}
            <div className="dialog-alert">
              <div className="dialog-alert__content">
                {title && (
                  <div className="dialog-alert__title-section">
                    <div className="dialog-alert__title-text">{title}</div>
                  </div>
                )}
                <div className="dialog-alert__body-text">{props.children}</div>
              </div>
              {(btnText || btnTextSecond) && (
                <div className="dialog-alert__btn-wrapper">
                  {btnText && (
                    <CoButton
                      className='__radius btn_primary'
                      disabled={disabled}
                      handleClick={handleSubmit}
                      isLoading={isLoading}
                    >
                      {btnText}
                    </CoButton>
                  )}
                  {btnTextSecond && (
                    <CoButton
                      className='__radius'
                      handleClick={handleSubmitSecond}
                      isLoading={isLoading}
                      secondary
                    >
                      {btnTextSecond}
                    </CoButton>
                  )}
                </div>
              )}
            </div>
          </Dialog>
        )
      default:
        return (
          <Dialog
            onClose={handleClose}
            open={isOpen}
            maxWidth={isMobile ? "xl" : "sm"}
            className={isMobile ? classes.root : ""}
          >
            <div
              className="dialog-alert__close-icon"
              style={{ position: 'absolute', right: '5px', top: '5px' }}
            >
              <IconButton
                onClick={handleClose}
                style={{ float: "right", marginTop: handleClose ? "" : "20px" }}
              >
                {handleClose && <CloseIcon />}
              </IconButton>
            </div>
            <div className="dialog-alert">
              <div className="dialog-alert__content">
                {title && (
                  <div className="dialog-alert__title-section">
                    <div
                      className="icon-error"
                      style={isMobile ? errorIconStyleMobile : errorIconStyle}
                    ></div>
                    <div className="dialog-alert__title-text">{title}</div>
                  </div>
                )}
                <div className="dialog-alert__body-text">{props.children}</div>
              </div>
              {(btnText || btnTextSecond) && (
                <div className="dialog-alert__btn-wrapper">
                  {btnText && (
                    <CoButton
                      className="btn_primary"
                      disabled={disabled}
                      handleClick={handleSubmit}
                      isLoading={isLoading}
                    >
                      {btnText}
                    </CoButton>
                  )}
                  {btnTextSecond && (
                    <CoButton
                      handleClick={handleSubmitSecond}
                      isLoading={isLoading}
                      secondary
                    >
                      {btnTextSecond}
                    </CoButton>
                  )}
                </div>
              )}
            </div>
          </Dialog>
        )
    }
  }

  return render()
};

export default AlertDialog;
