export const TransactionOptions = [
  {
    "name": "transfer",
    "paymentSystem": "200",
    "displayName": "Transferencia Bancaria",
  },
  {
    "name": "klap-debit",
    "paymentSystem": "203",
    "displayName": "Klap Debito",
  },
  {
    "name": "klap-credit",
    "paymentSystem": "205",
    "displayName": "Klap Credito"
  },
  {
    "name": "oneclick-debit",
    "paymentSystem": "204",
    "displayName": "OneClick Debito"
  },
  {
    "name": "oneclick-credit",
    "paymentSystem": "202",
    "displayName": "OneClick Credito"
  },
  {
    "name": "giftcard",
    "paymentSystem": "16",
    "displayName": "Giftcard"
  },
  {
    "name": "unipay",
    "paymentSystem": "206",
    "displayName": "Unipay"
  },
  {
    "name": "unipay-quotas",
    "paymentSystem": "207",
    "displayName": "Unipay con cuotas"
  },
  {
    "name": "webpay",
    "paymentSystem": "30",
    "displayName": "Crédito con cuotas"
  }
]

export const BANCO_ESTADO = 'Banco Estado'
export const BANCO_ESTADO_CREDIT= 'Banco Estado Credito'
export const BANCO_ESTADO_DEBIT = 'Banco Estado Debito'

export enum TypeCard {
  credit = "credit"
}