import { useEffect, useState } from "react"
import CheckOut from "../scenes/CheckOut/services/CheckOut.service"

export const useOnOffCudardaBancoEstado = () => {
    const [data, setData] = useState()

    useEffect(() => {
        const getData = async () => {
            const response = await CheckOut.getOnOffCucardaBancoEstado()
            const onOrOffcucarda = response?.items[0]?.fields?.cucardaBancoEstado

            setData(onOrOffcucarda)
        }

        getData()
    }, [])

    return data
}

export const useContentFulAssets = (id: string) => {
    const [data, setData] = useState<string>()

    useEffect(() => {
        const getData = async () => {
            const response = await CheckOut.getContentFullAssets(id)
            const urlImg = response?.fields?.file.url
            setData(`https:${urlImg}`)
        }

        getData()
    }, [])

    return data
}