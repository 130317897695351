import React from 'react'

interface FrontProps {
    ccv?: string;
}

export const Reverse = ({
    ccv = 'xxx'
}: FrontProps) => {
    return (
        <div className='card-reverse'>
            <div className='card-reverse__content-line'>
            </div>
            <div className='card-reverse__content__info'>
                <h3 className='card-reverse__due text'>
                    {ccv}
                </h3>
            </div>
        </div>
    )
}