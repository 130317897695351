import { useEffect, useState } from "react"
import CheckOut from "../scenes/CheckOut/services/CheckOut.service"

export const useInstallments = () => {
    const [installments, setInstallments] = useState([])

    useEffect(() => {
        const getData = async () => {
            const response = await CheckOut.getInstallments()
            const result = response?.items[0]?.fields?.cuotas
        
            if(result?.length) {
                const dataMapper = result
                .map(value => parseInt(value))
                .filter(value => value > 0)
                .sort((a, b) => a - b)
                .map(valueParse => ({ id: valueParse, value: valueParse }))

                setInstallments(dataMapper)
            }
        }

        getData()
    }, [])

    return installments
}