import React, { useContext } from "react";
import { OrderformContext } from "../../contexts/orderform.provider";
import "./style.scss";
import Close from "@material-ui/icons/Close";

const Toast = () => {
  const { error, setError } = useContext(OrderformContext);

  const handleClose = () => {
    return setError(null);
  };

  if (error) {
    const { message, IconColor, statusOk } = error;
    const color = IconColor ? `color--${IconColor}` : "";
    const colorStatus = {
      info: "background--info",
    };
    return (
      <div
        className={`${statusOk ? "" : "failed"} registration-alert ${
          colorStatus[IconColor] || ""
        }`}
      >
        <div
          className={`${
            statusOk ? "success-toast" : "error-toast"
          } icon-style ${color}`}
        />
        <p className="registration-message">{message}</p>
        <div
          className="x-toast"
          onClick={() => {
            return handleClose();
          }}
        />
        <Close />
      </div>
    );
  }

  return <></>;
};
export { Toast };
