/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import Container from "../Container";
import PaymentMethodCard from "./PaymentMethodCard/index";
import { OrderformContext } from "../../contexts/orderform.provider";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";
import helpers from "../../../../utils/helpers";
import { IPaymentMethod } from "./INewPayment";
import analytics from "../../../../utils/analytics";
import styles from "./PaymentMethodCard/PaymentMethodCard.module.css";
import { ContainerCard } from "../ContainerCard";
import PaymentsModal from "./PaymentsModal";
import { ModalCard } from "../ModalCard";
import { ModalAlert } from "../ModalCard/ModalAlert";
import CheckOut from "../../services/CheckOut.service";
import { NewPaymentLoader } from "./NewPaymentLoader";
import { RuleObject } from "../../../CheckOut";
import { useLocation } from "react-router-dom";
import "../payment.styles.scss";
import { BANCO_ESTADO, TypeCard } from "../../../../ constants";

interface IIdPaymentCard {
  inscriptionId: string;
  inscriptionType: string;
}

interface PaymentSystem {
  name: string;
  status: boolean;
  paymentSystem: string;
  displayName: string;
  isWebActive: boolean;
  isAndroidActive: boolean;
  isIosActive: boolean;
}
interface PaymentSystemsResponse {
  message: string;
  error: boolean;
  data: PaymentSystem[];
}

interface IPaymentProps {
  handleDeleteGc: ({
    url,
    callback,
  }: {
    url: string;
    callback: () => void;
  }) => void;
  setSelectedPaymentMethod: (paymentMethod: string) => void;
  setRulerObjext: React.Dispatch<React.SetStateAction<RuleObject>>;
  setShowModalTermsConditions: (value: boolean) => void;
  showModalTermsConditions: boolean;
}

const NewPayment: React.FC<IPaymentProps> = ({
  showModalTermsConditions,
  setShowModalTermsConditions,
  setSelectedPaymentMethod = () => {},
  handleDeleteGc = () => {},
  setRulerObjext = () => {},
}) => {
  const {
    isValidSections,
    dataKlap,
    modalAlertKlap,
    setOpenModalCard,
    setDataKlap,
    updateValidSections,
    setIsLoadingSelectCard: setIsLoading,
    isLoadingSelectCard: isLoading,
    orderform: {
      middlewarecard,
      clientProfileData,
      configurationFiles: { methodsPayment },
      totalizerData,
      paymentData,
      gifcardData,
    },
    activeToast,
  } = useContext(OrderformContext);

  const [blocked, setBlocked] = useState<boolean>(false);
  const [isKlap, setIsKlap] = useState(false);
  const [methodsPaymentFiltered, setMethodsPaymentFiltered] =
    useState<IPaymentMethod[]>(null);
  const [modalCard, setModalCard] = useState(false);
  const [openMethods, setOpenMethods] = useState<boolean>(false);
  const [paymentSelected, setPaymentSelected] = useState<IPaymentMethod>(null);
  const idPaymentCard: IIdPaymentCard = middlewarecard;
  const isMobile = useWindowSize().width <= 711;
  const haveSavedCards =
    (isKlap && paymentData?.cardsKlap?.length > 0) ||
    (!isKlap && paymentData?.cardsOneclick?.length > 0);
  const activeCardMethod = methodsPaymentFiltered?.find(
    (card) => card?.isCard && paymentSelected?.paymentId === card?.paymentId
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isKlapMethod = query.get("klap");

  const paymentTags = ({
    action,
    label,
  }: {
    action: string;
    label: string;
  }) => {
    return analytics.interactiveEvent({
      event: "interacciones_checkout",
      category: "checkout",
      action: action,
      label: label,
    });
  };

  const handleSelectPayment = (newMethod: IPaymentMethod) => {
    if (!newMethod) return;
    changeMethodPayment(newMethod);
    if (newMethod.isMenu) {
      if (!isValidSections?.totalizer) {
        const prevData = JSON.parse(
          helpers.getCookie("flagTermsConditions") || "{}"
        );
        helpers.setCookie(
          "flagTermsConditions",
          JSON.stringify({ ...prevData, value: "false", continue: "true" })
        );
        setShowModalTermsConditions(true);
      } else {
        setOpenModalCard(true);
        setOpenMethods(true);
      }
    }
  };

  const changeMethodPayment = (method: IPaymentMethod) => {
    if (!method) return;
    setPaymentSelected(method);
    paymentTags({ action: "seleccionar-medio-pago", label: method?.id });
  };

  const getActiveGiftCard = () =>
    paymentData.giftCards.filter((x: any) => x.inUse === true)[0];

  const filterPayments = (activeKlap = isKlap) => {
    const tempMethodsPayments = [];
    if (activeKlap) {
      if (paymentData?.cardsKlap && paymentData?.cardsKlap?.length > 0) {
        paymentData?.cardsKlap?.forEach((card) => {
          tempMethodsPayments.push({
            code: card?.type === "debit" ? [203] : [205],
            description: helpers.cardNumberFilter(card?.cardNumber),
            id: card?.cardType,
            isCard: true,
            image:
              "https://unimarcdev.vteximg.com.br/arquivos/LogoWebpay-oneclick.png",
            name:
              card?.cardType === "Prepago"
                ? "Prepago"
                : card?.type === "debit"
                ? "Débito"
                : "Crédito",
            paymentId: card?._id,
            state: card?._id === idPaymentCard?.inscriptionId,
            total: true,
            type: card?.type,
            entity: card?.entity?.replace(" ", "")?.toLocaleLowerCase(),
          });
        });
        const activeKlapCard = tempMethodsPayments.find(
          (payment) => payment.state
        );
        if (activeKlapCard) handleSelectPayment(activeKlapCard);
      }
    } else {
      if (
        paymentData?.cardsOneclick &&
        paymentData?.cardsOneclick?.length > 0
      ) {
        paymentData?.cardsOneclick?.forEach((card) => {
          let type = card?.type === "credit" ? [202] : [204];
          if (
            helpers.formatText(card.entity) === helpers.formatText(BANCO_ESTADO)
          ) {
            type = [
              card?.type === TypeCard.credit
                ? parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_CREDIT)
                : parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_DEBIT),
            ];
          }
          tempMethodsPayments.push({
            code: type,
            description: helpers.cardNumberFilter(card?.cardNumber),
            id: card?.cardType,
            isCard: true,
            image:
              "https://unimarcdev.vteximg.com.br/arquivos/LogoWebpay-oneclick.png",
            name:
              card?.cardType === "Prepago"
                ? "Prepago"
                : card?.type === "credit"
                ? "Crédito"
                : "Débito",
            paymentId: card?._id,
            state: card?._id === idPaymentCard?.inscriptionId,
            total: true,
            type: card?.type,
            entity: card?.entity?.replace(" ", "")?.toLocaleLowerCase(),
          });
        });
        handleSelectPayment(
          tempMethodsPayments.find((payment) => payment.state)
        );
      }
    }

    if (
      paymentData?.cardsKlap?.length === 0 ||
      paymentData?.cardsOneclick?.length === 0
    ) {
      tempMethodsPayments.push({
        code: [0],
        description: "",
        id: "newCard",
        image:
          "https://unimarcdev.vteximg.com.br/arquivos/LogoWebpay-oneclick.png",
        isMenu: true,
        name: "Selecciona un método de pago",
        paymentId: 0,
        state: false,
      });
    }
    tempMethodsPayments.push(
      methodsPayment.find((method) => {
        return method.id === "giftcard";
      })
    );
    const activeGiftCard = getActiveGiftCard();
    if (activeGiftCard && helpers.getStorage("giftCardStorage")) {
      changeMethodPayment(
        methodsPayment.find((method) => {
          return method.id === "giftcard";
        })
      );
    }
    const activePayment = tempMethodsPayments.find(
      (method) =>
        method.paymentId &&
        `${method.paymentId}` === paymentData?.payments[0]?.paymentSystem
    );
    if (activePayment) changeMethodPayment(activePayment);
    setMethodsPaymentFiltered(tempMethodsPayments);
    setIsLoading(false);
  };

  const validateSection = (valor: string) => {
    setSelectedPaymentMethod(valor);
    switch (`${valor}`.toLowerCase()) {
      case "banco_estado":
      case "oneclick":
        if (paymentData?.cardsOneclick?.length > 0) {
          updateValidSections(helpers.SECTIONS.PAYMENT, true);
        } else {
          updateValidSections(helpers.SECTIONS.PAYMENT, false);
        }
        break;
      case "webpay":
        updateValidSections(helpers.SECTIONS.PAYMENT, true);
        break;
      case "klap":
        if (paymentData?.cardsKlap?.length > 0) {
          updateValidSections(helpers.SECTIONS.PAYMENT, true);
          setDataKlap({ ...dataKlap, cardSlected: paymentSelected });
        }
        break;
      case "giftcard":
        const activeGiftCard = getActiveGiftCard();
        updateValidSections(helpers.SECTIONS.PAYMENT, Boolean(activeGiftCard));
        break;
      default:
        updateValidSections(helpers.SECTIONS.PAYMENT, false);
        break;
    }
  };

  /**
    @description Fetches the status of a gift card for a given client and updates the state accordingly.
    @async
    @function fetchGiftCardStatus
    @returns {Promise<void>}
    */
  const fetchGiftCardStatus = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const responseStatusBlocked = await CheckOut.getStatusGiftCard({
        rut: clientProfileData?.document,
      });
      const statusBlocked = await responseStatusBlocked;
      if (!statusBlocked?.data?.isBlocked) {
        return setBlocked(false);
      }
      if (statusBlocked?.error) {
        setBlocked(false);
        setIsLoading(false);
      }
      if (statusBlocked?.data?.isBlocked) {
        setBlocked(true);
      }
    } catch (_error) {
      setBlocked(false);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChangePayment = () => {
    setOpenMethods(true);
    setModalCard(true);
    setOpenModalCard(true);
  };

  const handleChangePayment = () => {
    if (!isValidSections?.totalizer) {
      const prevData = JSON.parse(
        helpers.getCookie("flagTermsConditions") || "{}"
      );
      helpers.setCookie(
        "flagTermsConditions",
        JSON.stringify({ ...prevData, value: "false", continue: "true" })
      );
      setShowModalTermsConditions(true);
      setOpenMethods(false);
      setModalCard(true);
      setOpenModalCard(false);
    } else {
      setModalCard(false);
    }
  };

  const ComponentTitle = () => {
    if (!isLoading && !haveSavedCards) return null;
    return (
      <p className={styles.paymentCard__add} onClick={handleOpenChangePayment}>
        Cambiar
      </p>
    );
  };

  useEffect(() => {
    const getCookieValue = JSON.parse(
      helpers.getCookie("flagTermsConditions") || "{}"
    );

    if (!showModalTermsConditions && getCookieValue?.value === "true") {
      if (getCookieValue?.continue === "true") {
        setModalCard(false);
        setOpenMethods(true);
        setOpenModalCard(true);
      }

      helpers.setCookie(
        "flagTermsConditions",
        JSON.stringify({ ...getCookieValue, continue: "false" })
      );
    }
  }, [showModalTermsConditions]);

  useEffect(() => {
    const find = (paymentSystems = []) => {
      const regexKlap = /(Klap)/i;
      const regexOneClick = /(OneClick Debito|OneClick Debito)/i;
      return {
        klap: paymentSystems?.some((payment) => regexKlap.test(payment.name)),
        oneClick: paymentSystems?.some((payment) =>
          regexOneClick.test(payment.name)
        ),
      };
    };
    const { klap, oneClick } = find(paymentData?.paymentSystems || []);
    const isActiveKlap = (klap && !oneClick) || Boolean(isKlapMethod);

    setIsKlap(isActiveKlap);
    filterPayments(isActiveKlap);
  }, [paymentData, isKlapMethod]);

  useEffect(() => {
    const url =
      helpers.API_ROUTES.KLAP_PAYMENT_SCRIPT[
        process.env.REACT_APP_ENVIRONMENT || "error"
      ];
    const script = window.document.createElement("script");
    script.src = url;

    const handleScriptLoad = (): void => {
      if (window?.["KLAP"]) {
        window["KLAP"]?.init({ useBinLookup: true });
      }
    };

    script.addEventListener("load", handleScriptLoad);
    window.document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      if (window?.["KLAP"]) {
        window["KLAP"]?.init({ useBinLookup: true });
      }
      window.document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (paymentSelected?.id) {
      if (
        paymentSelected?.code?.includes(202) ||
        paymentSelected?.code?.includes(204)
      ) {
        validateSection("oneClick");
      } else if (
        paymentSelected?.code?.includes(203) ||
        paymentSelected?.code?.includes(205)
      ) {
        validateSection("KLAP");
      } else if (
        paymentSelected?.code?.includes(
          parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_CREDIT)
        ) ||
        paymentSelected?.code?.includes(
          parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_DEBIT)
        )
      ) {
        validateSection("Banco_Estado");
      } else {
        validateSection(paymentSelected.id);
      }
    }
  }, [paymentSelected]);

  useEffect(() => {
    fetchGiftCardStatus();
  }, [clientProfileData.document]);

  useEffect(() => {
    //validate and choose the first card of the list
    const handleDefaultCard = async () => {
      if (!isLoading && !paymentSelected) {
        const payment = methodsPaymentFiltered.find((card) => card.isCard);
        if (payment) {
          setPaymentSelected(payment);
          handleSelectPayment(payment);
          const typePayment =
            payment?.code?.includes(202) || payment?.code?.includes(204)
              ? "oneClick"
              : "Klap";
          await CheckOut.updatePayment({ paymentSystem: payment?.code[0] });
          await CheckOut.updateSelectedPaymentType({
            typeCard: payment?.type?.toLowerCase(),
            idCard: payment.paymentId,
            typePayment,
          });
        }
      }
    };

    handleDefaultCard();
    //eslint-disable-next-line
  }, [paymentSelected, isLoading]);

  useEffect(() => {
    // if (props.tagApplied) return
    const query = new URLSearchParams(location.search);
    const registrationStatus = query.get("registration");
    // if (registrationStatus === 'ok' && !props.flagMessage) {
    if (registrationStatus === "ok") {
      const addedCard = paymentData.cardsOneclick[0];
      const message = `La tarjeta terminada en ${addedCard.cardNumber.slice(
        -4
      )} ha sido guardada de forma segura. `;
      analytics.interactiveEvent({
        action: "Mensaje tarjeta guardada de forma segura",
        label: "Tarjeta guarda",
      });
      analytics.errorEventTag({
        cardType: addedCard.cardType,
        eventAction: "notificacion_exito",
        eventCategory: "checkout",
        eventLabel: "guardar_tarjeta",
        paymentType: "oneclick",
      });
      activeToast({ message, statusOk: true });
    }

    // if (registrationStatus && registrationStatus !== 'ok' && !props.flagMessage) {
    if (registrationStatus && registrationStatus !== "ok") {
      const message = "No fue posible guardar tu tarjeta, intenta nuevamente";
      analytics.interactiveEvent({
        action: "Mensaje no fue posible guardar la tarjeta",
        label: "No fue posible agregar la tarjeta",
      });

      analytics.errorEventTag({
        eventAction: "notificacion_error",
        eventCategory: "checkout",
        eventLabel: "guardar_tarjeta",
        paymentType: "oneclick",
      });
      activeToast({ message, statusOk: false });
    }
    // props.setTagApplied(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile) {
    return (
      <>
        {modalAlertKlap?.open && <ModalAlert />}
        <Container
          hasLine
          title="¿Cómo te gustaría pagar?"
          componentTittle={<ComponentTitle />}
        >
          {isLoading && <NewPaymentLoader />}
          {!isLoading && (
            <>
              <ContainerCard>
                {openMethods && (
                  <PaymentsModal
                    cardsPayments={methodsPaymentFiltered.filter(
                      (method) => method?.isCard
                    )}
                    changePayment={handleChangePayment}
                    haveSavedCards={haveSavedCards}
                    isCard={modalCard}
                    isKlap={isKlap}
                    setRulerObjext={setRulerObjext}
                    onBack={() => setModalCard(true)}
                    onContinue={() => setOpenMethods(false)}
                    onSelectPayment={(newMethod) => {
                      setOpenMethods(false);
                      handleSelectPayment(newMethod);
                      if (modalCard && isKlap) {
                        setOpenModalCard(false);
                      }
                    }}
                    setPaymentSelected={setPaymentSelected}
                    handleSelectPayment={handleSelectPayment}
                    paymentSelected={paymentSelected}
                  />
                )}
                {!openMethods && (
                  <ModalCard
                    haveSavedCards={haveSavedCards}
                    handleDeleteGc={({ url, callback }) =>
                      handleDeleteGc({ url, callback })
                    }
                    onBack={() => setOpenMethods(true)}
                  />
                )}
              </ContainerCard>
              {activeCardMethod && activeCardMethod.id !== "giftcard" ? (
                <PaymentMethodCard
                  key={`payment__method__${activeCardMethod?.id}__${activeCardMethod?.paymentId}`}
                  payment={activeCardMethod}
                  selected={
                    paymentSelected?.paymentId === activeCardMethod?.paymentId
                  }
                  onPaymentMethodCard={handleSelectPayment}
                  setBlocked={setBlocked}
                  setRulerObjext={setRulerObjext}
                  blocked={blocked}
                  total={totalizerData?.totalPayment?.total}
                />
              ) : (
                methodsPaymentFiltered &&
                methodsPaymentFiltered[0] && (
                  <PaymentMethodCard
                    key={`payment__method__${methodsPaymentFiltered[0]?.id}__${methodsPaymentFiltered[0]?.paymentId}`}
                    payment={methodsPaymentFiltered[0]}
                    selected={
                      paymentSelected?.id === methodsPaymentFiltered[0]?.id
                    }
                    onPaymentMethodCard={handleSelectPayment}
                    setBlocked={setBlocked}
                    setRulerObjext={setRulerObjext}
                    blocked={blocked}
                    total={totalizerData?.totalPayment?.total}
                  />
                )
              )}
            </>
          )}
        </Container>
        {!isLoading &&
          methodsPaymentFiltered?.map((payment) => {
            if (payment.paymentId || payment.paymentId == 0) return;
            return (
              <PaymentMethodCard
                key={`payment__method__${payment.id}__${payment?.paymentId}`}
                payment={{
                  paymentCard: paymentData.cardsOneclick.find(
                    (card) =>
                      card.type === payment.id &&
                      card._id === idPaymentCard.inscriptionId
                  ),
                  ...payment,
                }}
                selected={paymentSelected?.id === payment.id}
                onPaymentMethodCard={(newMethod) => {
                  handleSelectPayment(newMethod);
                  changeMethodPayment(newMethod);
                  setOpenMethods(false);
                }}
                setBlocked={setBlocked}
                setRulerObjext={setRulerObjext}
                blocked={blocked}
                total={totalizerData?.totalPayment?.total}
              />
            );
          })}
      </>
    );
  }

  return (
    <>
      {modalAlertKlap?.open && <ModalAlert />}
      <Container
        hasLine
        title="¿Cómo te gustaría pagar?"
        componentTittle={<ComponentTitle />}
      >
        {isLoading && <NewPaymentLoader />}
        {!isLoading && (
          <>
            <ContainerCard>
              {openMethods && (
                <PaymentsModal
                  cardsPayments={methodsPaymentFiltered.filter(
                    (method) => method?.isCard
                  )}
                  changePayment={handleChangePayment}
                  haveSavedCards={haveSavedCards}
                  isCard={modalCard}
                  isKlap={isKlap}
                  setRulerObjext={setRulerObjext}
                  onBack={() => setModalCard(true)}
                  onContinue={() => setOpenMethods(false)}
                  onSelectPayment={() => {
                    setOpenMethods(false);
                    if (modalCard && isKlap) {
                      setOpenModalCard(false);
                    }
                  }}
                  setPaymentSelected={setPaymentSelected}
                  handleSelectPayment={handleSelectPayment}
                  paymentSelected={paymentSelected}
                />
              )}
              {!openMethods && (
                <ModalCard
                  handleDeleteGc={({ url, callback }) =>
                    handleDeleteGc({ url, callback })
                  }
                  haveSavedCards={haveSavedCards}
                  onBack={() => setOpenMethods(true)}
                />
              )}
            </ContainerCard>
            <div className="payment-containers">
              {activeCardMethod && activeCardMethod.id !== "giftcard" ? (
                <PaymentMethodCard
                  key={`payment__method__${activeCardMethod?.id}__${activeCardMethod?.paymentId}`}
                  blocked={blocked}
                  onLoading={setIsLoading}
                  onPaymentMethodCard={handleSelectPayment}
                  payment={activeCardMethod}
                  selected={
                    paymentSelected?.paymentId === activeCardMethod?.paymentId
                  }
                  setBlocked={setBlocked}
                  setRulerObjext={setRulerObjext}
                  total={totalizerData?.totalPayment?.total}
                />
              ) : (
                methodsPaymentFiltered?.[0] && (
                  <PaymentMethodCard
                    key={`payment__method__${methodsPaymentFiltered[0]?.id}__${methodsPaymentFiltered[0]?.paymentId}`}
                    payment={methodsPaymentFiltered[0]}
                    selected={
                      paymentSelected?.paymentId ===
                      methodsPaymentFiltered[0]?.paymentId
                    }
                    paymentSelected={paymentSelected}
                    setPaymentSelected={setPaymentSelected}
                    handleSelectPayment={handleSelectPayment}
                    onPaymentMethodCard={handleSelectPayment}
                    setBlocked={setBlocked}
                    setRulerObjext={setRulerObjext}
                    onLoading={setIsLoading}
                    blocked={blocked}
                    total={totalizerData?.totalPayment?.total}
                  />
                )
              )}
              {methodsPaymentFiltered?.map((payment) => {
                if (payment.paymentId || payment.paymentId == 0) return;
                return (
                  <PaymentMethodCard
                    blocked={blocked}
                    key={`payment__method__${payment.id}__${payment?.paymentId}`}
                    payment={payment}
                    paymentSelected={paymentSelected}
                    selected={paymentSelected?.id === payment.id}
                    total={totalizerData?.totalPayment?.total}
                    setBlocked={setBlocked}
                    setRulerObjext={setRulerObjext}
                    setPaymentSelected={setPaymentSelected}
                    handleSelectPayment={handleSelectPayment}
                    onLoading={setIsLoading}
                    onPaymentMethodCard={handleSelectPayment}
                  />
                );
              })}
            </div>
          </>
        )}
      </Container>
      {modalAlertKlap?.open && <ModalAlert />}
    </>
  );
};

export default NewPayment;
