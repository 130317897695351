import { useEffect, useRef } from "react"
import helpers from "./helpers"

export const useTimeOut = ({ tempRedirect, errorService, setRulerObjext }) => {
  const contador = useRef(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !tempRedirect &&
        !errorService.openErrorService &&
        (contador.current === 0 || contador.current > 0)
      ) {
        contador.current += 1
        if (contador.current === helpers.TIME_CHECKOUT) {
          setRulerObjext({
            isOpen: true,
            title: 'Para proteger tus datos, te enviaremos el Inicio',
            action: helpers.redirectToHome,
            redirectTo: 'home',
            justify: '',
            message:
              'Hemos notado que has permanecido en el checkout más tiempo del permitido. Por seguridad, te enviaremos al inicio del flujo.',
          })
        }
      }

      if (contador.current > 4 && !helpers.getCookie('co_sc')) {
        setRulerObjext({
          isOpen: true,
          title: 'Ingreso Incorrecto',
          action: helpers.redirectToHome,
          redirectTo: 'home',
          message:
            'Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.',
        })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [contador, errorService.openErrorService, setRulerObjext, tempRedirect])


  return {
    contador: contador.current
  }
}