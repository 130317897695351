import React, {
  useContext,
  useEffect,
  CSSProperties,
  useState,
  useRef,
} from "react";
import Totalizer from "./components/Totalizer";
import { Toast } from "./components/Toast/Toast";
import { useWindowSize } from "../../components/windowSize/use-window-size";
import helpers from "../../utils/helpers";
import CheckOut from "./services/CheckOut.service";
import { OrderformContext } from "./contexts/orderform.provider";
import AlertDialogRedirect from "../../components/AlertDialogRedirect/AlertDialogRedirect";
import { useLocation } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import ReactGA from "react-ga";
import analytics from "../../utils/analytics";
import { CircularProgress } from "@material-ui/core";
import NewPayment from "./components/NewPayment/index";
import { Header } from "../../components/Header/Header";
import { useTimeOut } from "../../utils/useTimeOut";
import "./styles.scss";
import {
  BANCO_ESTADO,
  BANCO_ESTADO_CREDIT,
  BANCO_ESTADO_DEBIT,
  TypeCard,
} from "../../ constants";
import { AlertTermAndConditions } from "../../components/AlertTermAndConditions";

ReactGA.plugin.require("ec");

export interface RuleObject {
  action: () => void;
  isOpen: boolean;
  justify?: CSSProperties["textAlign"];
  message: string;
  redirectTo: string;
  title: string;
}

interface Card {
  _id: string;
  cardNumber: string;
  cardType: string;
  channel: string;
  createdAt: string;
  gateway: string;
  isDefault: boolean;
  paymentSystem: Array<number>;
  provider: string;
  sequence: number;
  type: string;
  userReference: string;
}

const Inventory = (props: any) => {
  const {
    updateOrderform,
    isLoading,
    updateLoading,
    orderform,
    orderform: {
      totalizerData,
      isLoginTemp,
      shippingData,
      gifcardData,
      paymentData,
    },
    errorService,
    updateErrorService,
    isLoadingSelectCard,
    setIsLoadingSelectCard,
  } = useContext(OrderformContext);

  const location = useLocation();

  const [openDialogAlertPayments, setOpenDialogAlertPayments] = useState({
    message: "",
    state: false,
  });
  const isDelivery =
    shippingData?.selectedAddresses[0]?.addressType === "residential";
  const [tempRedirect, setTempRedirect] = useState(false);
  const [PaymentModalCodeError, setPaymentModalCodeError] = useState("" as any);
  const [flagService, setFlagService] = useState(false);
  const [openHealOrderFormModal, setOpenHealOrderFormModal] = useState(false);
  const userIdGTM = useRef("");
  const [gtmInit, setGtmInit] = useState(false);
  const query = new URLSearchParams(location.search);
  const [rulesObject, setRulerObjext] = useState<RuleObject>({} as RuleObject);
  const [selectingDate, setSelectingDate] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [showModalTermsConditions, setShowModalTermsConditions] =
    useState(false);

  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [termsConditions, setTermsConditions] = useState("");
  const [isOpenPrivacy, setIsOpenPrivacy] = useState(false);
  const [textPrivacyTerms, setTextPrivacyTerms] = useState("");
  const isMobile = useWindowSize().width <= 1024;
  //validation of session
  useTimeOut({ errorService, setRulerObjext, tempRedirect });

  const rulesEngine = async (payload: any) => {
    let response = true;
    if (
      payload &&
      (payload.message === "No token" ||
        payload.message === "No Autorizado" ||
        (payload.data && payload.data.totalizers.total_items < 1))
    ) {
      setRulerObjext({
        isOpen: true,
        title: "Ingreso Incorrecto",
        action: helpers.redirectToHome,
        redirectTo: "home",
        justify: "justify",
        message:
          " Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.",
      });

      response = false;
    } else if (
      payload &&
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0002"
      )
    ) {
      setRulerObjext({
        isOpen: true,
        title: "No hay capacidad de despacho",
        action: helpers.redirectToCartWithSL,
        redirectTo: "carro",
        justify: "initial",
        message:
          " Lo sentimos, por ahora no tenemos capacidad para atender tu pedido. Por favor, intenta con otra dirección.",
      });
      response = false;
    } else if (
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0003"
      ) &&
      !payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0002"
      )
    ) {
      setRulerObjext({
        isOpen: true,
        title: "No tenemos cobertura de despacho",
        action: helpers.redirectToCartWithSL,
        redirectTo: "carro",
        justify: "justify",
        message: `Lo sentimos, por ahora no tenemos cobertura de despacho para tu dirección.
           Por favor prueba con una distinta.`,
      });
      response = false;
    }

    return response;
  };

  /**
   * Find the current card based on the provided ID.
   * @param {Card[]} cardsKlap - Array of Klap cards.
   * @param {Card[]} cardsOneclick - Array of Oneclick cards.
   * @param {string} id - ID of the card to search for.
   * @returns {Card | null} - Card object found, or null if no match was found.
   */
  function findCurrentCard(
    cardsKlap: Card[],
    cardsOneclick: Card[],
    id: string
  ): Card | null {
    const isKlapMethod = Boolean(query.get("klap"));

    if (isKlapMethod && cardsKlap?.[0]?.type) {
      return {
        ...cardsKlap?.[0],
        paymentSystem: cardsKlap?.[0]?.type === "debit" ? [203] : [205],
      };
    }

    const foundCardKlap: Card | undefined = cardsKlap.find(
      (card) => card._id === id
    );
    if (foundCardKlap) {
      return {
        ...foundCardKlap,
        paymentSystem: foundCardKlap?.type === "debit" ? [203] : [205],
      };
    }

    const foundCardInOneclick: Card | undefined = cardsOneclick.find(
      (card) => card._id === id
    );
    if (foundCardInOneclick) {
      return {
        ...foundCardInOneclick,
        paymentSystem: foundCardInOneclick?.type === "credit" ? [202] : [204],
      };
    }

    return null;
  }
  const processResponse = async (responseCheckout: any) => {
    const rulesResult = await rulesEngine(responseCheckout);
    updateOrderform(responseCheckout.data);
    if (rulesResult) {
      if (gtmInit === false) {
        userIdGTM.current = responseCheckout.data.userProfileId;
        analytics.step2ChekcoutMark(responseCheckout.data);
        analytics.paginaVirtualCheckoutMark(responseCheckout.data);
        analytics.addShipingInfo(responseCheckout.data);
      }

      const asCard = responseCheckout?.data?.customData?.customApps?.find(
        (customApp: any) => customApp.id === "middlewarecard"
      );
      const arrayKlap: Card[] =
        responseCheckout?.data?.paymentData?.cardsKlap || [];
      const arrayCardsOneclick: Card[] =
        responseCheckout?.data?.paymentData?.cardsOneclick || [];
      const existCards =
        arrayKlap?.length > 0 || arrayCardsOneclick?.length > 0;
      const registrationStatus = query.get("registration");
      if (asCard && existCards && !/error/.test(registrationStatus)) {
        const findCard: Card = findCurrentCard(
          arrayKlap,
          arrayCardsOneclick,
          asCard?.fields?.inscriptionId
        );
        const middleware = responseCheckout.data.customData.customApps.find(
          (item) => item.id === "middlewarecard"
        );
        const paymentData = responseCheckout.data.paymentData;
        const cardSelected = paymentData?.cardsOneclick?.find(
          (item) => item._id === middleware.fields.inscriptionId
        );
        const isBancoEstado =
          helpers.formatText(cardSelected?.entity) ===
          helpers.formatText(BANCO_ESTADO);
        const typeCard =
          cardSelected?.type === TypeCard.credit
            ? BANCO_ESTADO_CREDIT
            : BANCO_ESTADO_DEBIT;
        const bancoEstado = paymentData?.paymentSystems?.find(
          (item) => item.name === typeCard
        );
        const findGiftCartInUse = paymentData.giftCards.filter(
          (x: any) => x.inUse === true
        )[0];
        const isGiftcardInUse = findGiftCartInUse
          ? findGiftCartInUse.inUse
          : false;

        let paymentSystem = findCard?.paymentSystem[0];

        if (isBancoEstado) {
          paymentSystem = bancoEstado?.id;
        }
        let typePayment =
          paymentSystem === 202 || paymentSystem === 204 ? "oneClick" : "Klap";
        if (isBancoEstado) {
          typePayment = "Banco_Estado";
        }
        if (!isGiftcardInUse) {
          await Promise.all([
            CheckOut.updatePayment({ paymentSystem }),
            CheckOut.updateSelectedPaymentType({
              typeCard: findCard?.type?.toLowerCase(),
              idCard: findCard?._id,
              typePayment,
            }),
          ]);
          const checkoutData = await CheckOut.ApiCheckout();
          updateOrderform(checkoutData.data);
        }
      }
      MessagesPaymentsError(responseCheckout.data?.configurationFiles);
      setGtmInit(true);
      setIsLoadingSelectCard(false);
    }
  };

  const modalErrorService = () => {
    updateErrorService({ ...errorService, openErrorService: true });
    const countRefresh: any = window.localStorage.getItem("refrehCount");
    let newContRefresh: any = parseInt(countRefresh) + 1;
    if (newContRefresh > 3) {
      updateErrorService({ ...errorService, openErrorService: false });
      window.localStorage.setItem("refrehCount", "1");
    } else {
      window.localStorage.setItem("refrehCount", newContRefresh);
    }
    setTempRedirect(true);
  };

  const healOrderForm = async (data: object) => {
    setOpenHealOrderFormModal(true);
    await regenerateOrderForm(data);
  };

  const regenerateOrderForm = async (params: any) => {
    CheckOut.regenerateOrderForm(params)
      .then(async (response: any) => {
        window.location.replace(response.url);
      })
      .catch((error) => {
        console.error(error, "Fallo al regenerar el OrderForm");
      });
  };

  const initMode = async () => {
    await CheckOut.ApiCheckout()
      .then(async (response: any) => {
        if (response.error) {
          helpers.setCookie("flagTermsConditions", "", 0);

          if (response.error.name === "TokenExpiredError") {
            setRulerObjext({
              isOpen: true,
              title: "Ingreso Incorrecto",
              action: helpers.redirectToHomeTokenExpired,
              redirectTo: "home",
              message:
                " Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.",
            });
          } else {
            if (response.error.code === 424) {
              const dataSendRegenerate = response.error.data;
              await healOrderForm(dataSendRegenerate);
            } else {
              modalErrorService();
            }
          }
        } else if (!response.data.clientProfileData) {
          const ordderFormData = await CheckOut.getOrderForm();

          const items =
            (ordderFormData?.data?.items?.length > 0 &&
              ordderFormData.data.items.map(({ id, quantity, seller }) => {
                return {
                  id,
                  quantity,
                  seller,
                };
              })) ||
            [];

          const address =
            helpers.getCookie("selectedAddresses") ||
            JSON.stringify(response.data.shippingData.address);

          const dataOrderForm = {
            items: items,
            shippingData: {
              address: JSON.parse(address),
              logisticsInfo: [],
            },
          };

          await healOrderForm(dataOrderForm);
        } else {
          const document = response?.data?.clientProfileData?.document;

          if (document) {
            const prevDataCookie = JSON.parse(
              helpers.getCookie("flagTermsConditions") || "{}"
            );

            const newValue =
              document !== prevDataCookie?.document
                ? "false"
                : prevDataCookie?.value;

            helpers.setCookie(
              "flagTermsConditions",
              JSON.stringify({
                document,
                value: newValue,
              }),
              1
            );
          }

          await handleGetOrders();
          await processResponse(response);
        }
      })
      .catch((response) => {
        modalErrorService();
        console.error("catch response checkout", response);
      });
  };

  const handleSaveCookie = (value: string) => {
    const prevDataCookie = helpers.getCookie("flagTermsConditions");
    const prevData = JSON.parse(prevDataCookie || "{}");

    helpers.setCookie(
      "flagTermsConditions",
      JSON.stringify({ ...prevData, value }),
      1
    );
  };

  const handleGetOrders = async () => {
    try {
      const [response, getHTMLPrivacyPolicities, resultTermsAndConditions] =
        await Promise.all([
          CheckOut.getOrders(),
          fetch("/privacy_policies/index.html"),
          fetch("/terms-and-conditions/index.html"),
        ]).then(async (response) => {
          return [
            await response[0],
            await response[1].text(),
            await response[2].text(),
          ];
        });
      const hasOrders = response?.data?.total > 0;

      setTextPrivacyTerms(getHTMLPrivacyPolicities?.toString());
      if (resultTermsAndConditions) {
        setTermsConditions(resultTermsAndConditions?.toString());
      }
      const prevDataCookie = helpers.getCookie("flagTermsConditions");
      const prevData = JSON.parse(prevDataCookie || "{}");

      if (prevData.value === "false") {
        handleSaveCookie(hasOrders?.toString());
      }
    } catch (error) {
      console.error("Failed to get orders", error);
    }
  };

  /**
   * Deletes a gift card.
   * @param {Object} params - Parameters for deleting the gift card.
   * @param {string} params.url - URL for redirection.
   * @param {Function} params.callback - Callback function to be called after deleting the gift card.
   */
  const handleDeleteGc = async ({
    url = "",
    callback = () => {
      return;
    },
  }) => {
    const valueGiftCard = {
      ...gifcardData,
      ...paymentData.giftCards?.find((gc) => gc.id === gifcardData?.id),
    };

    try {
      const response = await CheckOut.deleteGiftCardV1({
        gifcardId: valueGiftCard.id,
        orderFormId: orderform.orderFormId,
      }).finally(() => {
        callback();
        window.location.href = url;
      });

      helpers.removeStorage("giftCardStorage");

      return response;
    } catch (error) {
      console.error("Failed to delete gift card", error);
      throw error;
    }
  };

  const MessagesPaymentsError = async (error: any = []) => {
    const registrationStatus = query.get("error");
    setPaymentModalCodeError(registrationStatus);
    if (registrationStatus) {
      if (
        error.paymentsError?.find(
          (valor: any) => valor.code === parseInt(registrationStatus)
        )
      ) {
        const messageError = error.paymentsError?.find(
          (valor: any) => valor.code === parseInt(registrationStatus)
        ).message;
        console.log(messageError);
        setOpenDialogAlertPayments({ state: true, message: messageError });
        analytics.errorEventTag({
          /*cardType: selectedCardType,*/
          eventCategory: "checkout",
          eventLabel: "error_transaccion",
          paymentType: selectedPaymentMethod,
        });
        analytics.modalPaymentError(registrationStatus, userIdGTM.current);
      }
    }
  };

  const closePaymentErrorModal = () => {
    analytics.acceptPaymentError("Cerrar", PaymentModalCodeError);
  };

  const acceptPaymentErrorModal = () => {
    analytics.acceptPaymentError("Aceptar", PaymentModalCodeError);
  };

  useEffect(() => {
    updateLoading(true);
    if (!window.localStorage.getItem("refrehCount")) {
      window.localStorage.setItem("refrehCount", "1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  useEffect(() => {
    if (isLoading && parseInt(helpers.getCookie("co_sc")) > 0 && !flagService) {
      setFlagService(true);
      initMode();
    }
  }, [flagService, initMode, isLoading]);

  useEffect(() => {
    if (!gtmInit) {
      setIsLoadingSelectCard(!gtmInit);
    }
  }, [gtmInit, isLoadingSelectCard, setIsLoadingSelectCard]);

  let placerHolder: any = <div className="placeholder-item-totalizer-temp" />;

  return (
    <main className="checkOut" id="checkOut">
      <Header />

      <div className="wrapper">
        <AlertDialog
          title="Términos y condiciones"
          btnText="Aceptar"
          handleClose={() => {
            setIsOpenTerms(false);
          }}
          handleSubmit={() => {
            setIsOpenTerms(false);
          }}
          isOpen={isOpenTerms}
          modalType="form"
        >
          <div
            className="temrinos_condiciones"
            dangerouslySetInnerHTML={{
              __html: termsConditions,
            }}
          ></div>
        </AlertDialog>

        <AlertDialog
          title="Políticas de privacidad"
          btnText="Aceptar"
          handleClose={() => {
            setIsOpenPrivacy(false);
          }}
          handleSubmit={() => {
            setIsOpenPrivacy(false);
          }}
          isOpen={isOpenPrivacy}
          modalType="form"
        >
          <div
            className="temrinos_condiciones"
            dangerouslySetInnerHTML={{
              __html: textPrivacyTerms,
            }}
          ></div>
        </AlertDialog>

        <AlertTermAndConditions
          open={showModalTermsConditions}
          onSubmit={() => {
            handleSaveCookie("true");
            setShowModalTermsConditions(false);
          }}
          onClose={() => {
            handleSaveCookie("false");
            setShowModalTermsConditions(false);
          }}
          onOpenTerms={() => {
            setIsOpenTerms(true);
          }}
          onOpenPrivacy={() => {
            setIsOpenPrivacy(true);
          }}
        />
        {rulesObject.isOpen && (
          <AlertDialogRedirect
            isOpen={rulesObject.isOpen}
            title={rulesObject.title}
            timeout={5}
            handleDialogAction={rulesObject.action}
            redirectTo={rulesObject.redirectTo}
          >
            <p
              style={{
                textAlign: rulesObject.justify,
                whiteSpace: "pre-line",
              }}
            >
              {rulesObject.message}
            </p>
          </AlertDialogRedirect>
        )}

        <AlertDialogRedirect
          isOpen={totalizerData?.total_items <= 0}
          title={"No tienes productos en tu carro"}
          timeout={5}
          handleDialogAction={helpers.redirectToHome}
          redirectTo={"home"}
        >
          <p
            style={{
              textAlign: "justify",
              whiteSpace: "pre-line",
            }}
          >
            {`Lo sentimos no pudimos cargar la información de los productos de tu carro. Por favor intenta agregando algunos.`}
          </p>
        </AlertDialogRedirect>
        <AlertDialogRedirect
          isOpen={tempRedirect}
          title="Problemas al cargar"
          timeout={5}
          handleDialogAction={helpers.redirectToHome}
          redirectTo="home"
        >
          <p
            style={{
              textAlign: "justify",
              whiteSpace: "pre-line",
              margin: 0,
            }}
          >
            Lo sentimos tenemos problemas al cargar la información de compra.
            Por favor intenta más tarde.
          </p>
        </AlertDialogRedirect>

        <AlertDialog
          btnText="Aceptar"
          btnTextSecond="Cancelar"
          handleClose={() => {
            setOpenDialogAlertPayments({ state: false, message: "" });
            closePaymentErrorModal();
          }}
          handleSubmit={() => {
            setOpenDialogAlertPayments({ state: false, message: "" });
            acceptPaymentErrorModal();
          }}
          handleSubmitSecond={() => {
            setOpenDialogAlertPayments({ state: false, message: "" });
            closePaymentErrorModal();
          }}
          isOpen={openDialogAlertPayments.state}
          modalType="alert"
          title="Error en la transacción"
        >
          <p style={{ textAlign: "left" }}>{openDialogAlertPayments.message}</p>
        </AlertDialog>

        <div className="title-section">
          <h1 className="title-section__header">Mi compra</h1>
          <div className="horizonal-line" />
        </div>

        {isLoading ? (
          <>
            <div className="content">
              <div className="placeholder-item-profile" />
              <div className="loader424-container">
                {openHealOrderFormModal ? (
                  <p className="loader424-text">
                    <CircularProgress
                      className={`loader-card ${isLoading ? "is-loading" : ""}`}
                      size={40}
                    />
                    Seguimos procesando tu solicitud, por favor no cierres la
                    página
                  </p>
                ) : null}
              </div>
            </div>
            <div className="placeholder-item-totalizer" />
          </>
        ) : (
          <>
            <div className="content">
              {isMobile ? <Toast /> : null}
              {!isLoginTemp ? (
                <NewPayment
                  showModalTermsConditions={showModalTermsConditions}
                  setShowModalTermsConditions={setShowModalTermsConditions}
                  handleDeleteGc={({ url, callback }) =>
                    handleDeleteGc({ url, callback })
                  }
                  setRulerObjext={setRulerObjext}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                />
              ) : (
                placerHolder
              )}
            </div>
            <Totalizer
              handleDeleteGc={({ url, callback }) =>
                handleDeleteGc({ url, callback })
              }
              showModalTermsConditions={showModalTermsConditions}
              isLoginTemp={isLoginTemp}
              isSelectingDate={selectingDate}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectingDate={setSelectingDate}
            />
          </>
        )}
      </div>
    </main>
  );
};

export default Inventory;
