import React, { useContext, useEffect, useState } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import CoButton from "../../../components/Buttons/CoButton";
import CheckOut from "../services/CheckOut.service";
import { OrderformContext } from "../contexts/orderform.provider";
import helpers from "../../../utils/helpers";
import WaitMessage from "../components/Oneclick/WaitMessage";
import WithoutStock from "../components/WithoutStock";
import { useWindowSize } from "../../../components/windowSize/use-window-size";
import ReactGA from "react-ga";
import analytics from "../../../utils/analytics";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Toast } from "./Toast/Toast";
import storageNotes from "../../../utils/storageNotes";
import { TotalizerRow } from "./TotalizerRow";
import { PayBtn } from "./PayBtn";
import cartIcon from "../../../assets/images/Cart.svg";
import { Toggle } from "../../../components/Toogle";
import { BANCO_ESTADO, TransactionOptions } from "../../../ constants";
import "./totalizer.styles.scss";
import Container from "./Container";
import { RuleObject } from "..";
import AlertDialogRedirect from "../../../components/AlertDialogRedirect/AlertDialogRedirect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 400,
      marginBottom: 4,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: 16,
      boxShadow: "none",
    },
    button: {
      width: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    buttonOn: {
      width: 300,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: "#DA291C",
      color: "#fff",
    },
    text: {
      color: "#DA291C",
    },
  })
);

ReactGA.plugin.require("ec");

const formatNumber = (n: any) => {
  let numParts: any = n.toString().split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numParts.join(".");
};
interface CuponMessageState {
  state: boolean;
  text: string;
}

interface DataSend {
  value: number;
  deliveryWindow: string;
  cardId: string;
  quotas?: number;
}

const Totalizer = (props: any) => {
  const {
    isReadyToPay,
    setOpenAlertModalKlap,
    isLoadingSelectCard,
    updateOrderform,
    error,
    updateValidSections,
    orderform: {
      middlewarecard,
      marketingData,
      paymentData,
      totalizerData,
      shippingData: { logisticsInfo },
    },
    errorService,
    updateErrorService,
    isOpenWaitMessage,
    setIsOpenWaitMessage,
    dataKlap,
  } = useContext(OrderformContext);
  const {
    isLoginTemp,
    isSelectingDate,
    selectedCardType,
    handleDeleteGc,
    selectedPaymentMethod,
    showModalTermsConditions,
  } = props;

  const classes = useStyles();
  const [cupon, setCupon] = useState("");
  const [flagInsufficient, setFlagInsufficient] = useState(false);
  const [flagPay, setFlagPay] = useState(false);
  const [flagWindow, setFlagWindow] = useState(false);
  const [isLoadingCupon, setIsLoadingCupon] = useState(false);
  const [isOpenDeleteDialogCupon, setIsOpenDeleteDialogCupon] = useState(false);
  const [modalCupon, setModalCupon] = useState(false);
  const [modalPrivacyPolicies, setModalPrivacyPolicies] = useState(false);
  const [openDialogWithoutStock, setOpenDialogWithoutStock] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openWindowNot, setOpenWindowNot] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [spinnerCupon, setSpinnerCupon] = useState(false);
  const [state, setState] = useState(false);
  const [textPrivacyTerms, setTextPrivacyTerms] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [rulesObject, setRulerObjext] = useState<RuleObject>({} as RuleObject);
  const placeHolderCupon = "Ingresar código";

  const [cuponMessage, setCuponMessage] = useState<CuponMessageState>({
    state: false,
    text: "",
  });

  let el_coupon: any = document.querySelector(".MuiPaper-elevation1");
  if (el_coupon) {
    el_coupon.classList.remove("MuiPaper-elevation1");
  }
  const handleCloseCupon = () => {
    setIsOpenDeleteDialogCupon(false);
  };

  const isMobile = useWindowSize().width <= 1024;

  const arrayShipping = totalizerData.totalizers?.find(
    (x: any) => x.id === "Shipping"
  );

  const arrayCoupons = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsCoupon" && x.value !== 0
  );

  const arrayClubAhorro = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsClubUnimarc" && x.value !== 0
  );

  const arrayBancoEstado = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsBcoEstado" && x.value !== 0
  );

  const arrayCollaborator = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsCollaborator" && x.value !== 0
  );

  const arrayOthers = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsCatalog" && x.value !== 0
  );

  const DiscountsReferral = totalizerData.totalizers.find(
    (x: any) => x.id === "DiscountsReferral" && x.value !== 0
  );

  const arrayItems = totalizerData.totalizers.find(
    (x: any) => x.id === "Items"
  );

  const arrayTaxes = totalizerData.totalizers.find((x: any) => x.id === "Tax");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const prevDataCookie = JSON.parse(
      helpers.getCookie("flagTermsConditions") || "{}"
    );
    helpers.setCookie(
      "flagTermsConditions",
      JSON.stringify({
        ...prevDataCookie,
        value: event.target.checked.toString(),
      }),
      1
    );

    setState(!state);
  };

  const paymentTotalMount = totalizerData.totalPayment?.total
    .toString()
    .slice(0, -2);

  const totalizerTags = ({
    action,
    label,
  }: {
    action: string;
    label: string;
  }) => {
    return analytics.interactiveEvent({
      action: action,
      category: "checkout",
      event: "interacciones_checkout",
      label: label,
    });
  };

  const terminosCondiciones = () => {
    totalizerTags({ action: "pagar", label: "terminos-condiciones" });
    setOpenModal(true);
  };

  const cerrarModal = () => {
    setOpenModal(false);
  };

  const openModalPrivacyPolicies = () => {
    setModalPrivacyPolicies(true);
  };

  const closeModalPrivacyPolicies = () => {
    setModalPrivacyPolicies(false);
  };

  const verifyWindow = async (payload: any) => {
    let response = true;

    if (
      payload &&
      payload.exceptions?.length > 0 &&
      payload.exceptions?.find((exception: any) => exception.code === "0005")
    ) {
      console.error("error de ventana");
      setFlagWindow(true);
      setIsOpenWaitMessage(false);
      response = false;
    }

    return response;
  };

  const setter = () => {
    setIsOpenWaitMessage(helpers.addCard);
    analytics.interactiveEvent({
      action: "Click",
      label: "Botón agregar nueva tarjeta",
    });
  };

  const saveProductNotes = () => {
    const productNotes = storageNotes.getAllProductNotes();
    if (productNotes && productNotes.length > 0) {
      CheckOut.updateCriteriaItems(productNotes);
      storageNotes.removeAllNotes();
    }
  };

  const handleSubmit = async () => {
    setIsOpenWaitMessage(true);

    CheckOut.ApiCheckout()
      .then(async (response: any) => {
        saveProductNotes();
        const updatedOrderform = response.data;
        const cardSelected = updatedOrderform.paymentData.payments[0]
          ? updatedOrderform.paymentData?.paymentSystems.find(
              (element: any) =>
                element.id ===
                parseInt(updatedOrderform.paymentData.payments[0].paymentSystem)
            )
          : null;
        setSelectedCard(cardSelected);
        const typeCard = updatedOrderform.customData.customApps
          ? updatedOrderform.customData.customApps.find(
              (element: any) => element.id === "middlewarecard"
            )
          : null;

        if (cardSelected && updatedOrderform) {
          analytics.step4CheckoutMark(updatedOrderform, typeCard, cardSelected);
          analytics.addPaymentInfo({
            orderForm: updatedOrderform,
            paymentType: selectedPaymentMethod,
            typeCard: selectedCardType,
          });
        }

        setFlagPay(true);
        const verifyWindowFlag = await verifyWindow(updatedOrderform);
        const orderFormExceptions =
          updatedOrderform.exceptions?.length > 0 &&
          updatedOrderform.exceptions?.find(
            (exception: any) => exception.code === "0001"
          );
        await updateOrderform(updatedOrderform);
        if (verifyWindowFlag && !orderFormExceptions) {
          if (cardSelected?.name === "Webpay") {
            await CheckOut.generateTransaction({ giftCardVariant: false })
              .then((responseTransaction) => {
                if (responseTransaction.data.url) {
                  saveProductNotes();
                  if (helpers.getStorage("giftCardStorage")) {
                    handleDeleteGc({
                      url: responseTransaction.data.url,
                      callback: setIsOpenWaitMessage(false),
                    });
                  } else {
                    window.location.href = responseTransaction.data.url;
                  }
                } else {
                  updateErrorService({
                    ...errorService,
                    openErrorService: true,
                  });
                }
              })
              .catch(() => handleErrorTransaction());
          } else if (`${selectedPaymentMethod}`.toLowerCase() === "klap") {
            const getPaymentSystem = parseInt(
              updatedOrderform?.paymentData?.payments?.[0]?.paymentSystem
            );
            const getTransacctionMethod = TransactionOptions.find(
              (option) =>
                parseInt(option?.["paymentSystem"]) === getPaymentSystem
            );
            const payment_method = getTransacctionMethod?.["name"];
            const isKlapCredit = getTransacctionMethod?.paymentSystem === "205";

            CheckOut.updatePayment({ paymentSystem: getPaymentSystem })
              .then(async (res) => {
                if (!res.error) {
                  const { slas } = logisticsInfo[0];
                  const { deliveryWindow } = slas[0];
                  const dataSend: DataSend = {
                    value: parseInt(totalizerData.totalPayment?.total),
                    deliveryWindow: deliveryWindow,
                    cardId: middlewarecard.inscriptionId,
                  };
                  if (isKlapCredit && dataKlap?.installments > 0) {
                    dataSend.quotas = dataKlap?.installments;
                  }
                  await CheckOut.updateSelectedPaymentType({
                    typeCard: middlewarecard.inscriptionType?.toLowerCase(),
                    idCard: middlewarecard.inscriptionId,
                    typePayment: "Klap",
                  });
                  await CheckOut.ApiCheckoutCardTransaction({
                    pPayload: dataSend,
                    method: "klap",
                    payment_method,
                  })
                    .then(async (response: any) => {
                      if (response) {
                        const orderNumber = response?.orderId;
                        if (orderNumber) {
                          const orderPlaceUrl = helpers.IS_PROD
                            ? helpers.UNIMARC_ROUTES.PROD_ORDERPLACED
                            : helpers.UNIMARC_ROUTES.DESA_ORDERPLACED;
                          saveProductNotes();
                          if (helpers.getStorage("giftCardStorage")) {
                            handleDeleteGc({
                              url: `${orderPlaceUrl}?og=${
                                orderNumber.split("-")[0]
                              }`,
                              callback: setIsOpenWaitMessage(false),
                            });
                          } else {
                            window.location.href = `${orderPlaceUrl}?og=${
                              orderNumber.split("-")[0]
                            }`;
                          }
                        }
                        if (response?.error) {
                          return setOpenAlertModalKlap(() => {
                            return {
                              description:
                                "No fue posible pagar con tu tarjeta, por favor reintenta nuevamente",
                              open: true,
                              title: "Error de datos",
                            };
                          });
                        }
                      }
                    })
                    .catch((response) => {
                      setIsOpenWaitMessage(false);
                      if (response?.error) {
                        return setOpenAlertModalKlap(() => {
                          return {
                            description:
                              "No fue posible pagar con tu tarjeta, por favor reintenta nuevamente",
                            open: true,
                            title: "Error de datos",
                          };
                        });
                      }
                    });
                }
              })
              .catch(() => handleErrorTransaction());
          } else {
            const cardSelected = paymentData?.cardsOneclick?.find(
              (item) => item._id === middlewarecard?.inscriptionId
            );
            const isBancoEstado =
              helpers.formatText(cardSelected?.entity) ===
              helpers.formatText(BANCO_ESTADO);
            const value = totalizerData?.totalPayment?.total;
            const giftcardInUse = paymentData.giftCards.filter(
              (x: any) => x.inUse === true
            )[0];
            const existGiftCard = giftcardInUse ? giftcardInUse.inUse : false;

            if (existGiftCard) {
              analytics.addPaymentInfo({
                orderForm: updatedOrderform,
                paymentType: selectedPaymentMethod,
                typeCard: selectedCardType,
              });
              if (giftcardInUse.balance < value) {
                setFlagInsufficient(true);
                setIsOpenWaitMessage(false);
                return false;
              }
            }
            const transactionParams = {
              deliveryWindow: logisticsInfo[0]?.slas[0]?.deliveryWindow,
              value,
            };

            let transactionHeaders = existGiftCard ? "giftcard" : "one-click";
            if (isBancoEstado) {
              transactionHeaders = "Banco_Estado";
            }

            await CheckOut.generateTransactionV2(
              transactionParams,
              transactionHeaders
            )
              .then((responseTransaction) => {
                const orderNumber = responseTransaction?.data?.orderId;
                const orderPlaceUrl = helpers.IS_PROD
                  ? helpers.UNIMARC_ROUTES.PROD_ORDERPLACED
                  : helpers.UNIMARC_ROUTES.DESA_ORDERPLACED;
                if (orderNumber) {
                  saveProductNotes();
                  if (helpers.getStorage("giftCardStorage")) {
                    handleDeleteGc({
                      url: `${orderPlaceUrl}?og=${orderNumber.split("-")[0]}`,
                      callback: setIsOpenWaitMessage(false),
                    });
                  } else {
                    window.location.href = `${orderPlaceUrl}?og=${
                      orderNumber.split("-")[0]
                    }`;
                  }
                } else {
                  updateErrorService({
                    ...errorService,
                    openErrorService: true,
                  });
                }
              })
              .catch(() => handleErrorTransaction());
          }
        }
      })
      .catch(() => handleErrorTransaction());
  };

  const handleErrorTransaction = () => {
    setIsOpenWaitMessage(false);
    setRulerObjext({
      isOpen: true,
      title: "Ha ocurrido un error, intentelo más tarde",
      action: () => {
        window.location.reload();
      },
      redirectTo: "la pasarela de pagos",
      justify: "justify",
      message: `Lo sentimos, ha ocurrido un error en el proceso de pago.`,
    });
  };

  /**
   * Handles the submission of the second form and redirects to the corresponding URL.
   */
  const handleSubmitSecond = (): void => {
    /**
     * The redirect URL depends on the application's environment.
     * In production, UNIMARC_ROUTES.PROD_CART is used, otherwise UNIMARC_ROUTES.DESA_CART is used.
     * @type {string}
     */
    const redirectUrl: string = helpers.IS_PROD
      ? helpers.UNIMARC_ROUTES.PROD_CART
      : helpers.UNIMARC_ROUTES.DESA_CART;

    saveProductNotes();
    window.location.href = redirectUrl;
  };

  /**
   * Sends a coupon for validation and performs necessary actions based on the response.
   * @param {string} action - The action to perform (optional). If 'delete' is provided, the coupon will be deleted.
   * @returns {Promise<void>}
   */
  const sendCoupon = async (action: string = ""): Promise<void> => {
    const dataSend: any = {
      coupon: action === "delete" ? "" : cupon || "",
    };

    if (action !== "delete") {
      totalizerTags({ action: "editar-cupon", label: "enviar-cupon" });
    }

    setIsLoadingCupon(true);

    try {
      const response = await CheckOut.generateCoupon(dataSend);

      const statusCode: number = response?.data?.code;

      if (dataSend.coupon !== "") {
        const label =
          response.status === "OK"
            ? "correcto-cupon-ingresado"
            : "error-editar-cupon";
        totalizerTags({ action: "editar-cupon", label });
      }

      if (statusCode === 410) {
        setCuponMessage({
          state: true,
          text: "El código promocional ha expirado",
        });
      }
      if (statusCode === 400) {
        setCuponMessage({
          state: true,
          text: "El código promocional ingresado no es válido",
        });
      }

      if (response?.status === "OK") {
        updateOrderform(response.data);
        setCupon("");
      }
    } catch (error) {
      setModalCupon(true);
      analytics.interactiveEvent({
        action: "modal_error",
        category: "checkout",
        event: "interacciones_checkout",
        label: "verifica_datos_ingresados",
      });
    } finally {
      setIsLoadingCupon(false);
      setIsOpenDeleteDialogCupon(false);

      setTimeout(() => {
        setCuponMessage({
          text: "",
          state: false,
        });
      }, 5000);
    }
  };

  useEffect(() => {
    const getFlagTermsConditions = JSON.parse(
      helpers.getCookie("flagTermsConditions") || "{}"
    );
    const statusTermsConditions = getFlagTermsConditions?.value === "true";

    setState(statusTermsConditions);
  }, [showModalTermsConditions]);

  useEffect(() => {
    updateValidSections(helpers.SECTIONS.TOTALIZER, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (openModal || modalPrivacyPolicies) {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    } else {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    };
  }, [openModal, modalPrivacyPolicies]);

  useEffect(() => {
    const getHTMLPrivacyPolicities = async () => {
      const result = await fetch("/privacy_policies/index.html").then(
        (response) => response.text()
      );
      setTextPrivacyTerms(result.toString());
    };
    (async () => {
      const result = await fetch("/terms-and-conditions/index.html").then(
        (response) => response.text()
      );
      setTermsConditions(result.toString());
    })();
    getHTMLPrivacyPolicities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  let spinnerOpacity = spinnerCupon ? 0.6 : 1;
  let spinnerPointer: any = spinnerCupon ? "none" : "all";
  let barra = isMobile && <hr className="totalizerhr" />;
  let cuponDisabled = cupon.length > 0 ? false : true;
  const validateShipping =
    arrayShipping && arrayShipping.value
      ? `$ ${helpers.priceFilter(arrayShipping.value)}`
      : "Gratis";
  let valueShipping =
    arrayShipping && arrayShipping.value && arrayShipping.value === 0
      ? "Gratis"
      : validateShipping;

  const LABEL_TOTALIZER = {
    Items: {
      label: "Subtotal",
      value: `$ ${helpers.priceFilter(Math.abs(arrayItems?.value))}`,
    },
    Tax: {
      label: "Impuestos",
      value: `$ ${helpers.priceFilter(arrayTaxes?.value)}`,
    },
    DiscountsBcoEstado: {
      label: "Descuento BancoEstado",
      value: `-$ ${helpers.priceFilter(Math.abs(arrayBancoEstado?.value))}`,
    },
    DiscountsClubUnimarc: {
      label: "Cupones Club Unimarc",
      value: `-$ ${helpers.priceFilter(Math.abs(arrayClubAhorro?.value))}`,
    },
    DiscountsCollaborator: {
      label: "Descuento Colaborador",
      value: `-$ ${helpers.priceFilter(Math.abs(arrayCollaborator?.value))}`,
    },
    DiscountsCoupon: {
      label: `Código ${marketingData.coupon ? marketingData.coupon : ""}`,
      value: `-$ ${helpers.priceFilter(Math.abs(arrayCoupons?.value))}`,
    },
    DiscountsCatalog: {
      label: "Otros ahorros",
      value: `-$ ${helpers.priceFilter(Math.abs(arrayOthers?.value))}`,
    },
    DiscountsReferral: {
      label: "Descuento Referidos",
      value: `-$ ${helpers.priceFilter(Math.abs(DiscountsReferral?.value))}`,
    },
    Shipping: {
      label: "Despacho",
      value: valueShipping,
    },
  };

  return (
    <div className="totalizer">
      {!isMobile ? <Toast /> : null}
      {rulesObject?.isOpen && (
        <AlertDialogRedirect
          isOpen={rulesObject.isOpen}
          title={rulesObject.title}
          timeout={5}
          handleDialogAction={rulesObject.action}
          redirectTo={rulesObject.redirectTo}
        >
          <p
            style={{
              textAlign: rulesObject.justify,
              whiteSpace: "pre-line",
            }}
          >
            {rulesObject.message}
          </p>
        </AlertDialogRedirect>
      )}
      <div
        className="totalizer-content"
        style={error?.message ? { marginTop: "0" } : { marginTop: "73px" }}
      >
        <>
          {spinnerCupon && (
            <div
              style={{
                position: "relative",
                opacity: spinnerOpacity,
                pointerEvents: spinnerPointer,
              }}
            >
              <CircularProgress
                className={classes.text}
                size={60}
                style={{
                  position: "absolute",
                  top: "80px",
                  left: "50%",
                  zIndex: 1,
                }}
              ></CircularProgress>
            </div>
          )}
          {barra}
          {isMobile && (
            <Container
              hasLine
              title={
                marketingData.coupon && marketingData.coupon.length > 0
                  ? "Código Activo"
                  : "¿Tienes un código de descuento?"
              }
            >
              {marketingData.coupon && marketingData.coupon.length > 0 ? (
                <div>
                  <li
                    className="busqueda__tag"
                    key={0}
                    style={{ marginTop: "10px" }}
                  >
                    <div key={0}>
                      <span
                        className="cupon-logo"
                        style={{ paddingLeft: "20px" }}
                      ></span>
                      <label className="busqueda__input">
                        Código: {marketingData.coupon}
                      </label>
                      <span
                        className="cupon-delete"
                        onClick={() => setIsOpenDeleteDialogCupon(true)}
                      ></span>
                    </div>
                  </li>
                </div>
              ) : (
                <div>
                  <Paper className={"papperCoupon"}>
                    <div className="cupon-logo" aria-label="menu"></div>
                    <InputBase
                      className={classes.input}
                      placeholder={placeHolderCupon}
                      value={cupon}
                      onChange={(e: any) =>
                        setCupon(e.target.value.toUpperCase())
                      }
                    />
                    <CoButton
                      className={"button_cupon"}
                      disabled={cuponDisabled}
                      handleClick={() => sendCoupon()}
                      isLoading={isLoadingCupon}
                    >
                      Enviar
                    </CoButton>
                  </Paper>
                </div>
              )}

              {cuponMessage?.state && (
                <p className={classes.text}>{cuponMessage?.text}.</p>
              )}
            </Container>
          )}
          <div className="summary">
            <Container hasLine={false}>
              <h3 className="container__title-resumen">Resumen</h3>
              {totalizerData.totalizers?.map((totalizer) => {
                const values = LABEL_TOTALIZER[totalizer.id];

                if (!values || values?.value.includes("null")) return null;
                return (
                  <TotalizerRow
                    key={values.label}
                    item={values.label}
                    value={values.value}
                  />
                );
              })}

              {paymentTotalMount && (
                <TotalizerRow
                  isTotal
                  item="Total"
                  value={`$ ${formatNumber(paymentTotalMount)}`}
                />
              )}

              <hr className="totalizerhr" />
              {!isMobile && (
                <>
                  {marketingData.coupon && marketingData.coupon.length > 0 ? (
                    <div>
                      <p>Código Activo</p>
                      <li
                        className="busqueda__tag"
                        key={0}
                        style={{ marginTop: "10px" }}
                      >
                        <div key={0}>
                          <span
                            className="cupon-logo"
                            style={{ paddingLeft: "20px" }}
                          ></span>
                          <label className="busqueda__input">
                            Código: {marketingData.coupon}
                          </label>
                          <span
                            className="cupon-delete"
                            onClick={() => setIsOpenDeleteDialogCupon(true)}
                          ></span>
                        </div>
                      </li>
                    </div>
                  ) : (
                    <div>
                      <p>¿Tienes un código de descuento?</p>

                      <Paper className="papperCoupon">
                        <div className="cupon-logo" aria-label="menu"></div>
                        <InputBase
                          className={classes.input}
                          placeholder={placeHolderCupon}
                          value={cupon}
                          onChange={(e: any) =>
                            setCupon(e.target.value.toUpperCase())
                          }
                        />
                        <CoButton
                          className={"button_cupon"}
                          disabled={cuponDisabled}
                          handleClick={() => sendCoupon()}
                          isLoading={isLoadingCupon}
                        >
                          Enviar
                        </CoButton>
                      </Paper>
                    </div>
                  )}

                  {cuponMessage.state && (
                    <p className={classes.text}>{cuponMessage.text}.</p>
                  )}
                  <hr className="totalizerhr" style={{ marginTop: "20px" }} />
                </>
              )}

              {isMobile && (
                <div className="back-to-cart">
                  <ShoppingCartOutlinedIcon />
                  <div
                    className="btn-look-as-a-link"
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      paddingLeft: "5px",
                    }}
                    onClick={helpers.redirectToCart}
                  >
                    Volver al carro
                  </div>
                </div>
              )}
            </Container>
          </div>

          <div className="">
            {!isLoginTemp && (
              <div className="terms-and-pay">
                <div className="wrapper__terms-and-conditions">
                  <Toggle state={state} onChange={handleChange} />
                  <p className="terms-and-conditions">
                    Acepto los&nbsp;
                    <button
                      className="btn-look-as-a-link link_underline"
                      onClick={terminosCondiciones}
                    >
                      términos y condiciones
                    </button>
                    {` y las `}
                    <button
                      className="btn-look-as-a-link link_underline"
                      onClick={openModalPrivacyPolicies}
                    >
                      políticas de privacidad
                    </button>
                  </p>
                </div>
                <div className="btn-pay-wrapper">
                  <PayBtn
                    selectedPaymentMethod={selectedPaymentMethod}
                    isReadyToPay={isReadyToPay}
                    isLoadingSelectCard={isLoadingSelectCard}
                    isSelectingDate={isSelectingDate}
                    state={state}
                    paymentData={paymentData}
                    handleSubmit={handleSubmit}
                    setter={setter}
                  />
                </div>
              </div>
            )}
            {!isMobile && (
              <div className="back-to-cart">
                <img src={cartIcon} alt="" />
                <div
                  className="btn-look-as-a-link"
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "5px",
                  }}
                  onClick={helpers.redirectToCart}
                >
                  Volver al carro
                </div>
              </div>
            )}
          </div>
        </>
      </div>
      <AlertDialog
        btnText="Entendido"
        customTextTAC={true}
        disablePortal={true}
        handleClose={cerrarModal}
        handleSubmit={cerrarModal}
        isOpen={openModal}
        maxWidth="md"
        modalType="form"
        title="Términos y Condiciones"
      >
        <div
          className="temrinos_condiciones"
          dangerouslySetInnerHTML={{
            __html: termsConditions,
          }}
        ></div>
      </AlertDialog>
      <AlertDialog
        btnText="Entendido"
        customTextTAC={true}
        disablePortal={true}
        handleClose={closeModalPrivacyPolicies}
        handleSubmit={closeModalPrivacyPolicies}
        isOpen={modalPrivacyPolicies}
        maxWidth="md"
        modalType="form"
        title="Políticas de privacidad"
      >
        <div
          className="temrinos_condiciones"
          dangerouslySetInnerHTML={{ __html: textPrivacyTerms }}
        />
      </AlertDialog>

      <WaitMessage title="Espera un poco" isOpen={isOpenWaitMessage}>
        Nos estamos conectando de forma segura con tu medio de pago.
      </WaitMessage>

      <WithoutStock
        flagWindow={flagWindow}
        isOpen={openDialogWithoutStock}
        isOpenWin={openWindowNot}
        isPay={flagPay}
        selectedCard={selectedCard}
        setIsOpen={setOpenDialogWithoutStock}
        setIsOpenWin={setOpenWindowNot}
      />
      <AlertDialog
        title="Problemas al cargar información"
        isOpen={modalCupon}
        btnText="Recarga"
        handleSubmit={refreshPage}
        isLoading={false}
      >
        Lo sentimos, tuvimos un error al cargar tu información por favor <br />
        intenta de nuevo.
      </AlertDialog>
      <AlertDialog
        btnText="Eliminar"
        handleClose={handleCloseCupon}
        handleSubmit={() => {
          sendCoupon("delete");
        }}
        isLoading={isLoadingCupon}
        isOpen={isOpenDeleteDialogCupon}
        modalType="alert"
        title="¿Estás seguro que quieres eliminar este cupón?"
      >
        Si lo eliminas, deberás volver a ingresarlo para que el descuento
        aplique nuevamente.
      </AlertDialog>
      <AlertDialog
        title={"No podemos continuar con tu compra"}
        isOpen={flagInsufficient}
        btnText={"Elegir otro medio de pago"}
        btnTextSecond={"Ir al carro"}
        handleSubmit={() => {
          setFlagInsufficient(false);
        }}
        handleSubmitSecond={handleSubmitSecond}
        isLoading={false}
      >
        Lo sentimos, el total de tu compra excede el saldo de tu gift card. Por
        favor selecciona otro medio de pago.
      </AlertDialog>
    </div>
  );
};

export default Totalizer;
