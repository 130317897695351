import React, {
  useState,
  useEffect,
  useContext
} from 'react'
import helpers from '../../../../utils/helpers'
import analytics from '../../../../utils/analytics'
import { FlipCard } from './Card'
import CoButton from '../../../../components/Buttons/CoButton'
import { Front } from './Card/Front'
import { Reverse } from './Card/Reverse'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useWindowSize } from '../../../../components/windowSize/use-window-size'
import IconAlert from '../../../../assets/images/warningInfo.svg'
import ArrowLeft from '../../../../assets/images/arrowLeft.svg'
import IconEdit from '../../../../assets/images/edit.svg'
import IconClose from '../../../../assets/images/close.svg'
import { OrderformContext } from '../../contexts/orderform.provider'
import {
  InterfaceMessage,
  ShowProps,
  ValuesProps
} from './klapInterface'
import { useGetQuery } from './helpers'
import { Backbone } from './Backbone'
import './modalcard.styles.scss'
import CheckOut from '../../services/CheckOut.service'
import { TransactionOptions } from '../../../../ constants'
import { useInstallments } from '../../../../utils/useInstallments'

interface ModalCardProps {
  haveSavedCards?: boolean;
  onBack?: () => void;
  showTitle?: boolean;
  handleDeleteGc?: ({ url, callback }: { url: string; callback: () => void }) => void;
}

export const ModalCard: React.FC<ModalCardProps> = ({
  haveSavedCards = false,
  onBack = null,
  showTitle = true,
  handleDeleteGc = () => { return }
}) => {
  const {
    dataKlap,
    loadingKlap,
    orderform,
    setDataKlap,
    setOpenAlertModalKlap,
    setOpenModalCard,
    updateOrderform,
    orderform: {
      paymentData,
      shippingData,
      totalizerData
    },
  } = useContext(OrderformContext)
  const [flipped, setFlipped] = useState<boolean>(false)
  const [cardNum, setCardNum] = useState<string>('')
  const [numOnCard, setNumOnCard] = useState<string>('##### ##### ##### #####')
  const [cardType, setCardType] = useState<string>('VISA')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showInput] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [numberCard, setNumberCard] = useState<string>('')
  const isMobile: boolean = useWindowSize().width <= 1024
  const query = useGetQuery()
  const installmentsOptions = useInstallments()
  const removeQuotas = ["205"].includes(paymentData?.payments?.[0]?.paymentSystem) ? '' : 'hidden'

  const [messageForm, setMessageForm] = useState<InterfaceMessage>({
    messageNumberKlap: {
      message: '',
      state: false
    },
    messageCcvKlap: {
      message: '',
      state: false
    },
    messageDueDateKlap: {
      message: '',
      state: false
    },
  })

  const [showIconEdit, setShowIconEdit] = useState<ShowProps>({
    cardNum: false,
    dueDate: false,
    ccv: false,
    userInfo: false
  })
  const [values, setValues] = useState<ValuesProps>({
    ccv: '',
    dueDate: '',
    userInfo: '',
    quotas: '0'
  })
  // HANDLESS
  const handleClose = () => {
    setOpenModalCard(false)
  }
  const validateCcvFormat = (ccv): boolean => {
    const format = /^\d{3}$/
    return format.test(ccv)
  }
  const validateDueDateFormat = (dueDate): boolean => {
    const format = /^\d{2}\/\d{2}$/
    return format.test(dueDate)
  }
  const validatedNumberCard = (numberCard): boolean => {
    const format = /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/
    return format.test(numberCard)
  }
  const removeDoubleQuotes = (text = ''): string => {
    return text?.replace(/['"]+/g, '')
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const classNameWrapper = ({ state, asActive }: { state: boolean, asActive: boolean }): string => {
    return `${state ? 'form-container__action_error' : 'form-container__action'} ${asActive ? 'modal--input__cart--active' : ''}`
  }
  const { ccv, dueDate } = values
  const isValidCcv = validateCcvFormat(ccv)
  const isValidDueDate = validateDueDateFormat(dueDate)
  const isValidNumber = validatedNumberCard(cardNum)

  const formatExpiry = (string = ''): string => {
    return string.replace(
      /[^0-9]/g, ''
    ).replace(
      /^([2-9])$/g, '0$1'
    ).replace(
      /^(1{1})([3-9]{1})$/g, '0$1/$2'
    ).replace(
      /^0{1,}/g, '0'
    ).replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2'
    )
  }

  const handleNumChange = e => {
    let { value } = e.target
    const userRemovingNum = value.length < cardNum.length
    if ((isNaN(value.split(' ').join('')) && value !== '') || e.nativeEvent.data === ' ') {
      return
    }
    if (userRemovingNum) {
      setCardNum(value)
      let num = numOnCard
      const arr = num.split('')
      if (arr[value.length] !== ' ') {
        arr.splice(value.length, 1, '#')
      }
      num = arr.join('')
      setNumOnCard(num)
      if (!value.length) {
        setCardType('')
      }
      return
    }

    if (value.split(' ').join('').length >= 1) {
      // Get the card type
      const type = helpers.getCardType(value.split(' ').join(''))
      setCardType(type)
    }

    if (value.length) {
      let num
      if (value.length <= 4) {
        num = value + numOnCard.slice(value.length)
      } else if (value.length >= 6 && value.length <= 15) {
        num =
          numOnCard.slice(0, value.length - 1) +
          '*' +
          numOnCard.slice(value.length)
      } else {
        num = numOnCard.split('')
        num.splice(value.length - 1, 1, value[value.length - 1])
        num = num.join('')
      }
      setNumOnCard(num)
      if (value.length === 4) {
        value = value + ' '
      }
      if (value.length === 9) {
        value = value + ' '
      }
      if (value.length === 14) {
        value = value + ' '
      }
      setCardNum(value)
    }
  }


  /**
   * Update the URL with a new query param.
   * @param {string} name - Name  of query param.
   */
  const setQueryParams = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('error', name || 'card');

    const newSearchParams = searchParams.toString();

    const newUrl = `${window.location.pathname}?${newSearchParams}`;
    window.location.href = newUrl
  };


  /**
   * Clear the input value for the specified field name.
   * @param {string} name - The name of the field to clear.
   * @returns {void}
   */
  const onClickClearInput = (name) => {
    setValues({
      ...values,
      [name]: ''
    })
    setMessageForm((prevState): InterfaceMessage => {
      if (name === 'dueDate') return {
        ...prevState,
        messageDueDateKlap: {
          message: '',
          state: false
        }
      }
      if (name === 'ccv') return {
        ...prevState,
        messageCcvKlap: {
          message: '',
          state: false
        }
      }
    })
  }

  const propsFrontCard = {
    ...values,
    cardType,
    disabled,
    image: cardNum.length >= 1,
    numberCard,
    numOnCard: cardNum,
    setNumberCard,
  }

  const formatNumberCart = (value: string): void => {
    let num = value.split(' ').join('')
    if (num.length > 16) {
      num = num.slice(0, 16)
    }
    if (num.length >= 4 && num.length <= 8) {
      num = num.slice(0, 4) + ' ' + num.slice(4)
    } else if (num.length >= 9 && num.length <= 12) {
      num = num.slice(0, 4) + ' ' + num.slice(4, 8) + ' ' + num.slice(8)
    } else if (num.length >= 13 && num.length <= 16) {
      num =
        num.slice(0, 4) +
        ' ' +
        num.slice(4, 8) +
        ' ' +
        num.slice(8, 12) +
        ' ' +
        num.slice(12)
    }
    return setCardNum(num)
  }

  const cleanCardNumber = (): void => {
    setNumberCard('')
    setCardNum('')
    setMessageForm((prevState): InterfaceMessage => {
      return {
        ...prevState,
        messageNumberKlap: {
          message: '',
          state: false
        }
      }
    })
  }

  /**
   * Handle the form submission.
   * @returns {void}
   */
  const handleSubmit = async () => {
    try {
      const getPaymentSystem = paymentData.payments[0].paymentSystem;
      const getTransacctionMethod = TransactionOptions.find(
        (option) => parseInt(option?.['paymentSystem']) === getPaymentSystem
      );
      const payment_method = getTransacctionMethod?.['name'];
      const { slas } = shippingData?.logisticsInfo[0];
      const { deliveryWindow } = slas[0];
      const paymentTotalMount: string = totalizerData?.totalPayment?.total.toString()
      const dataSend = { value: parseInt(paymentTotalMount), deliveryWindow };
      if (parseInt(values.quotas) > 0) dataSend['quotas'] = parseInt(values.quotas)

      setLoadingSubmit(true)
      await CheckOut.ApiCheckoutCardTransaction({
        pPayload: dataSend,
        method: 'klap',
        payment_method
      }).then(async (response) => {
        if (response) {
          const { orderId, paymentOrderId } = response;
          setDataKlap({ orderId, paymentOrderId });
          if (orderId && paymentOrderId) {
            window["klap"]?.init({ useBinLookup: true });
          }
        }
      })
      await CheckOut.ApiCheckout().then((response) => {
        updateOrderform(response.data)
      })

      if (window?.['KLAP'] && typeof window['KLAP']?.payOrder === 'function') {
        window['KLAP']?.payOrder()
      } else {
        setOpenModalCard(false)
        if (isMobile) setQueryParams('payment')
        return setOpenAlertModalKlap(() => {
          return {
            description: 'No fue posible pagar con tu tarjeta, por favor reintenta nuevamente',
            open: true,
            title: 'Error de datos'
          }
        })
      }
    } catch (error) {
      setOpenModalCard(false)
      setLoadingSubmit(false)
      if (isMobile) setQueryParams('payment')
      return setOpenAlertModalKlap(() => {
        return {
          description: 'No fue posible pagar con tu tarjeta, por favor reintenta nuevamente',
          open: true,
          title: 'Error de datos'
        }
      })
    }
  }

  const propsCad = {
    backChild: <Reverse ccv={values?.ccv} />,
    disabled: !isValidNumber,
    flipped,
    frontChild: <Front {...propsFrontCard} />,
    setFlipped
  }

  const findErrorKlap = (data, code) => {
    const error = data?.some(res => { return res.code === code })
    return error
  }


  window['errorKlap'] = function errorKlap(data): void {
    setLoadingSubmit(false)
    setOpenModalCard(false)
    const isErrorCode = findErrorKlap(data, 400005)
    const isErrorFormatCard = findErrorKlap(data, 400009)
    const isInvalidNumber = findErrorKlap(data, 400002)
    const isErrorCcv = findErrorKlap(data, 400007)
    const isErrorDueDate = findErrorKlap(data, 400006)
    const isErrorDue = findErrorKlap(data, 400003)
    const isErrorPaymentOrderId = findErrorKlap(data, 400001)
    const rejectPay = findErrorKlap(data, 100001)
    const isUserCancelPay = findErrorKlap(data, 100002)
    const isErrorTransaction = findErrorKlap(data, 404001)
    const isErrorTryingPay = findErrorKlap(data, 500005)
    const isErrorUnknow = data.some(request => request.status === 'REJECT')
    const isErrorValidations = isErrorCode || isErrorFormatCard || isInvalidNumber
    const showParamErrors = isErrorPaymentOrderId || rejectPay || isErrorTransaction || isErrorTryingPay || isUserCancelPay || isErrorUnknow
    let errorValidation = { message: '', key: '' }

    if (isErrorCcv) errorValidation = { message: 'Ingresa un cvv válido', key: 'messageCcvKlap' }
    if (isErrorDueDate || isErrorDue) errorValidation = { message: 'Ingresa una fecha válida', key: 'messageDueDateKlap' }
    if (isInvalidNumber || isErrorFormatCard) errorValidation = { message: 'Ingresa un número válido', key: 'messageNumberKlap' }

    if (errorValidation.message !== '') {
      setMessageForm((prevState): InterfaceMessage => {
        return {
          ...prevState,
          [errorValidation.key]: {
            message: errorValidation.message,
            state: true
          }
        }
      })
    }

    if (isMobile) {
      if (isErrorValidations) setQueryParams('card')
      if (showParamErrors) setQueryParams('payment')
    }
    if (!isMobile && showTitle) {
      setDataKlap({ orderId: '', paymentOrderId: '' })
    }

    return setOpenAlertModalKlap(() => {
      return {
        description: 'No fue posible pagar con tu tarjeta, por favor reintenta nuevamente',
        open: true,
        title: 'Error de datos'
      }
    })
  }
  const { orderId } = dataKlap || {}
  const formarOrder = () => {
    if (orderId) {
      return orderId?.split('-')[0]
    }
    return null
  }
  const og: string = removeDoubleQuotes(query.get('og'))

  window['successKlap'] = async function successKlap(data) {
    const { status } = data || {}
    const order = formarOrder()
    const webViewCase = paymentOrderId && status === 'SUCCESS'
    if (webViewCase && og) {
      analytics.addPaymentInfo({
        orderForm: orderform,
        paymentType: 'Klap',
        typeCard: 'Klap'
      });
      setOpenModalCard(false)
      if (webViewCase && !og) {
        if (isMobile) setQueryParams('card')
      }
      if (!og) return setOpenAlertModalKlap(() => {
        return {
          description: 'No fue posible pagar con tu tarjeta, por favor reintenta nuevamente',
          open: true,
          title: 'Error de datos'
        }
      })
      const orderPlaceUrl = helpers.IS_PROD ?
        helpers.UNIMARC_ROUTES.PROD_ORDERPLACED :
        helpers.UNIMARC_ROUTES.DESA_ORDERPLACED

      window.location.href = `${orderPlaceUrl}?og=${og}`
      setDataKlap({ orderId: '', paymentOrderId: '' })
    }
    if (status === 'SUCCESS' && order) {
      analytics.addPaymentInfo({
        orderForm: orderform,
        paymentType: 'Klap',
        typeCard: 'Klap'
      });
      const orderPlaceUrl = helpers.IS_PROD ?
        helpers.UNIMARC_ROUTES.PROD_ORDERPLACED :
        helpers.UNIMARC_ROUTES.DESA_ORDERPLACED
      if (helpers.getStorage('giftCardStorage')) {
        await handleDeleteGc({ url: `${orderPlaceUrl}?og=${order}`, callback: setOpenModalCard(false) })
      } else {
        window.location.href = `${orderPlaceUrl}?og=${order}`
        setOpenModalCard(false)
      }
      setDataKlap({ orderId: '', paymentOrderId: '' })
    }
  }

  const paymentOrderId: string = removeDoubleQuotes(query.get('orderId'))

  const existOrderId = paymentOrderId !== '' && paymentOrderId

  const cardValidationTag = () => {
    analytics.interactiveEvent({
      event: 'interacciones_checkout',
      category: 'checkout',
      action: 'notificacion_exito',
      label: 'validar_tarjeta',
      additionalInfo: {
        payment_type: 'Klap',
        context: `tarjeta_${haveSavedCards ? 'existente' : 'nueva'}`
      }
    })
  }

  useEffect(() => {
    if (existOrderId) {
      return window?.["KLAP"]?.init({ useBinLookup: true });
    }
    if (dataKlap?.paymentOrderId && !loadingKlap) {
      return window?.["KLAP"]?.init({ useBinLookup: true });
    }
  }, [dataKlap, dataKlap.paymentOrderId, existOrderId, loadingKlap, paymentOrderId])

  useEffect(() => {
    (() => {
      const isValid = isValidNumber && isValidDueDate && isValidCcv

      if (ccv) {
        setMessageForm((prevState): InterfaceMessage => {
          return {
            ...prevState,
            messageCcvKlap: {
              message: isValidCcv ? '' : 'Ingresa un número válido',
              state: !isValidCcv
            }
          }
        })
      }

      if (dueDate) {
        setMessageForm((prevState): InterfaceMessage => {
          return {
            ...prevState,
            messageDueDateKlap: {
              message: isValidDueDate ? 'Ingresa un número válido' : '',
              state: !isValidDueDate
            }
          }
        })
      }
      if (!isValidNumber) {
        setDisabled(true)
        return setMessageForm((prevState): InterfaceMessage => {
          return {
            ...prevState,
            messageNumberKlap: {
              message: 'Ingresa un número válido',
              state: true
            }
          }
        })
      }
      setDisabled(!isValid)
      if (cardType === '' || !cardType) {
        setDisabled(true)
      }
      if (isValidNumber) cardValidationTag()
      return setMessageForm((prevState): InterfaceMessage => {
        return {
          ...prevState,
          messageNumberKlap: {
            message: '',
            state: false
          }
        }
      })

    })()
    // eslint-disable-next-line
  }, [cardNum, values])

  const stateNumberError: boolean = messageForm.messageNumberKlap.state
  const stateDueDateError: boolean = messageForm.messageDueDateKlap.state
  const stateCcvError: boolean = messageForm.messageCcvKlap.state
  const asValueNumber: boolean = (cardNum?.length > 0 && !stateNumberError)
  const asValueDueDate: boolean = (dueDate?.length > 0 && !stateDueDateError)
  const asValueDueCcv: boolean = (ccv?.length > 0 && !stateCcvError)
  const classNameWrapperNumber: string = classNameWrapper({ state: stateNumberError && cardNum?.length > 0, asActive: asValueNumber })
  const classNameWrapperDueDate: string = classNameWrapper({ state: stateDueDateError && values?.dueDate?.length > 0, asActive: asValueDueDate })
  const classNameWrapperCcv: string = classNameWrapper({ state: stateCcvError && values?.ccv?.length > 0, asActive: asValueDueCcv })
  const showMessage: boolean = messageForm.messageNumberKlap.state
  const showMessageDueDate: boolean = dueDate ? !isValidDueDate : false
  const showMessageCcv: boolean = ccv ? !isValidCcv : false

  return (
    <div className='modal'>
      {<>
        {!paymentOrderId &&
          <div className='modal-header'>
            <div className='modal-header_title'>
              {onBack && (
                <IconButton className='modal-header_button' onClick={onBack}>
                  <img src={ArrowLeft} alt='' className='modal-image__card' />
                </IconButton>
              )}
              {showTitle && (
                <div className='modal-title--container'>
                  <h2 className='modal-title'>
                    Agregar tarjeta
                  </h2>
                </div>
              )}
            </div>
            {!isMobile &&
              <IconButton className='modal-header_button' onClick={handleClose} style={{ float: 'right', backgroundColor: '#F5F5F5' }} >
                <CloseIcon />
              </IconButton>
            }
          </div>
        }
        {loadingKlap && !dataKlap?.paymentOrderId ?
          <>
            <div className='modal-backbone'>
              <Backbone />
            </div>
          </> :
          <>
            <div className='modal_wrapper--content'>
              <div className='modal_wrapper'>
                <FlipCard {...propsCad} />
              </div>
              <div className='modal-form_content'>
                <form
                  className='form-control-card'
                  data-klap-fn-error='errorKlap'
                  data-klap-fn-success='successKlap'
                  data-klap-order-id={dataKlap.paymentOrderId ?? paymentOrderId}
                  id='checkout-klap'
                >
                  <div
                    className='wrapper-input'
                    onMouseEnter={() => {
                      return setShowIconEdit((prev) => {
                        return {
                          ...prev,
                          cardNum: false
                        }
                      })
                    }}
                    onMouseLeave={() => {
                      return setShowIconEdit((prev) => {
                        return {
                          ...prev,
                          cardNum: true
                        }
                      })
                    }}
                  >
                    <label htmlFor='cardNumber' className='form-label__input'>Número de tarjeta</label>
                    <div className={classNameWrapperNumber}>
                      <input
                        maxLength={19}
                        className='modal--input__cart'
                        data-klap-card-number
                        id="cardNumber"
                        name='cardNumber'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumChange(e)}
                        onFocus={() => {
                          return setShowIconEdit((prev) => {
                            return { ...prev, cardNum: false }
                          })
                        }}
                        onBlur={() => {
                          return formatNumberCart(cardNum)
                        }}
                        placeholder='Ingresa el n° de tu tarjeta'
                        value={cardNum}
                      />
                      <div className='wrapper-input__image-container' onClick={() => { return cleanCardNumber() }}>
                        {(!showIconEdit.cardNum && cardNum.length > 0) && <img className='wrapper-input--image__close' src={IconClose} alt='' />}
                        {(showIconEdit.cardNum && cardNum.length > 0) && <img className='wrapper-input--image__edit' src={IconEdit} alt='' />}
                      </div>
                    </div>
                    <span className={(showMessage && cardNum.length > 0) ? 'error-message' : 'error-hidden'}>Ingresa un número válido </span>
                  </div>
                  {showInput && <div
                    className='wrapper-input'
                    onMouseEnter={() => {
                      return setShowIconEdit((prev) => {
                        return { ...prev, userInfo: false }
                      })
                    }}
                    onMouseLeave={() => {
                      return setShowIconEdit((prev) => {
                        return { ...prev, userInfo: true }
                      })
                    }}
                  >
                    <label className='form-label__input'>Nombre y apellido</label>
                    <div className={classNameWrapperNumber}>
                      <input
                        className='modal--input__cart'
                        name='userInfo'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        placeholder='Ingrésalo tal y como sale en tu tarjeta'
                        value={values.userInfo}
                      />
                      <div className='wrapper-input__image-container' onClick={() => { return onClickClearInput('userInfo') }}>
                        {(!showIconEdit.userInfo && values.userInfo?.length > 0) && <img className='wrapper-input--image__close' src={IconClose} alt='' />}
                        {(showIconEdit.userInfo && values?.userInfo?.length > 0) && <img className='wrapper-input--image__edit' src={IconEdit} alt='' />}
                      </div>
                    </div>
                  </div>}
                  <div className='form-group__info'>
                    <div
                      className='wrapper_input__info'
                      onMouseEnter={() => {
                        return setShowIconEdit((prev) => {
                          return { ...prev, dueDate: false }
                        })
                      }}
                      onMouseLeave={() => {
                        return setShowIconEdit((prev) => {
                          return { ...prev, dueDate: true }
                        })
                      }}
                    >
                      <label className='form-label__input' htmlFor='cardExpiryDate' >Fecha de vencimiento</label>
                      <div className={classNameWrapperDueDate}>
                        <input
                          className='modal--input__cart'
                          data-klap-expiry-date
                          id='cardExpiryDate'
                          placeholder='mm/aa'
                          maxLength={5}
                          minLength={5}
                          name='dueDate'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                          onFocus={() => {
                            return setShowIconEdit((prev) => {
                              return { ...prev, dueDate: false }
                            })
                          }}
                          value={formatExpiry(values.dueDate)}
                        />
                        <div className='wrapper-input__image-container' onClick={() => { return onClickClearInput('dueDate') }}>
                          {(!showIconEdit.dueDate && values.dueDate?.length > 0) && <img className='wrapper-input--image__close' src={IconClose} alt='' />}
                          {(showIconEdit.dueDate && values?.dueDate?.length > 0) && <img className='wrapper-input--image__edit' src={IconEdit} alt='' />}
                        </div>
                      </div>
                      <span className={`error-due ${(showMessageDueDate || messageForm.messageDueDateKlap.state) && values?.dueDate?.length > 0 ? 'error-message' : 'error-hidden'}`}>
                        Ingresa una fecha válida
                      </span>
                    </div>
                    <div
                      className='wrapper_input__info'
                      onMouseEnter={() => {
                        return setShowIconEdit((prev) => {
                          return { ...prev, ccv: false }
                        })
                      }}
                      onMouseLeave={() => {
                        return setShowIconEdit((prev) => {
                          return { ...prev, ccv: true }
                        })
                      }}
                    >
                      <input id='klap-card-type' type='hidden' data-klap-card-type='1' />
                      <label htmlFor='cardCvv' className='form-label__input'>CVV</label>
                      <div className={classNameWrapperCcv}>
                        <input
                          className='modal--input__cart'
                          data-klap-card-cvv
                          id='cardCvv'
                          maxLength={3}
                          minLength={3}
                          name='ccv'
                          onBlur={() => setFlipped(false)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                          onFocus={() => setFlipped(true)}
                          placeholder='CVV'
                          value={values.ccv}
                        />
                        <div className='wrapper-input__image-container' onClick={() => { return onClickClearInput('ccv') }}>
                          {(!showIconEdit.ccv && values.ccv?.length > 0) && <img className='wrapper-input--image__close' src={IconClose} alt='' />}
                          {(showIconEdit.ccv && values?.ccv?.length > 0) && <img className='wrapper-input--image__edit' src={IconEdit} alt='' />}
                        </div>
                      </div>
                      <span className={`cvv-error ${(showMessageCcv && values?.ccv?.length > 0) ? 'error-message' : 'error-hidden'}`}>Ingresa un cvv válido </span>
                    </div>
                  </div>
                  <div className={`wrapper-input ${removeQuotas}`}>
                    <label htmlFor="quotas" className='form-label__input'>Selecciona tu cuota</label>
                    <div className='form-container__action'>
                      <select
                        name="quotas"
                        id="quotas"
                        className="modal--input__cart"
                        data-klap-quotas
                        value={values.quotas}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e)}
                      >
                        {installmentsOptions.map(installement => {
                          if ([0, 1].includes(installement.id)) return <option value='1'>Sin cuotas</option>
                          return (
                            <option value={installement.id} key={installement.id}>
                              {installement.value} cuotas
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-check hidden">
                    <input id="generateToken" checked type="checkbox" name="generateToken" data-klap-generate-token />
                    <label className="form-check-label" defaultChecked={true} htmlFor="generateToken">Recordar tarjeta</label>
                  </div>
                </form>
                <div className='wrapper_toast'>
                  <img src={IconAlert} className='wrapper_toast-image' alt='Al inscribir la tarjeta se va a realizar el pago y quedará guardada para futuras compras' />
                  <p>Al inscribir la tarjeta se va a realizar el pago y quedará guardada para futuras compras</p>
                </div>
              </div>
              <div className='form-group__submit' style={(isMobile && paymentOrderId) ? { position: 'fixed' } : {}}>
                <CoButton
                  className='form-group__btn'
                  disabled={disabled}
                  handleClick={handleSubmit}
                  id='payBtn'
                  isLoading={(!disabled && loadingKlap) || loadingSubmit}
                >
                  Pagar
                </CoButton>
              </div>
            </div>
          </>
        }
      </>
      }

    </div>
  )
}
