import helpers from "./helpers";

const PRODUCT_NOTES = 'productNotes'

type OrderFormItems = {
  id: string;
  note?: string;
  productId: string;
  substituteType: string;
}

const getCurrentDomain = () => {
  return typeof window !== 'undefined' && window.location.hostname.split('.').slice(-2).join('.')
}

const getStorage = (): string => {
  const coProductsNotes = helpers.getCookie(PRODUCT_NOTES)
  if (!coProductsNotes)
    return '{}'
  return coProductsNotes
}

const getAllProductNotes = (): OrderFormItems[] => {
  const productNotes = Object.entries(JSON.parse(getStorage()))
  return productNotes.map((productNote) => {
    return {
      id: productNote[0],
      note: `${productNote[1]}`,
      productId: productNote[0],
      substituteType: '2'
    }
  })
}

const removeAllNotes = () => {
  const domain = getCurrentDomain()
  return helpers.setCookie(PRODUCT_NOTES, '{}', 1, domain)
}

export default {
  getAllProductNotes,
  removeAllNotes
}