import React from "react";
import "./styles.css";

interface ToggleProps {
  id?: string;
  state?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: React.FC<ToggleProps> = ({
  id = "toggle-id",
  state = false,
  onChange = () => {},
}) => {
  return (
    <div className="wrapper-toggle">
      <div className="check-box-wrapper">
        <input
          id={id}
          type="checkbox"
          checked={state}
          value={state ? "on" : "off"}
          onChange={onChange}
          className="check-box"
        />
        <label htmlFor={id} className="check-box-label"></label>
      </div>
    </div>
  );
};
