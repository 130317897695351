import React, { useContext, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CoButton from '../../../../components/Buttons/CoButton';
import analytics from '../../../../utils/analytics';
import { OrderformContext } from '../../contexts/orderform.provider';
import CheckOut from '../../services/CheckOut.service';
import GiftCard from '../GiftCard/GiftCard';
import { RuleObject } from '../..';
import { IPaymentMethod } from '../NewPayment/INewPayment';
import helpers from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 16,
    boxShadow: 'none',
  },
  giftcard: {
    '& label.Mui-focused': {
      color: '#282828',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
        border: '1px solid',
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.12rem',
      fontFamily: "Plus Jakarta Sans",
    },
  },
  giftcardMobile: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
        border: '1px solid',
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.12rem',
      fontFamily: "Plus Jakarta Sans",
    },
  },
  texContainer: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#282828',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
        border: '1px solid',
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-root': {
      fontSize: '11px',
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiIconButton-root': {
      padding: '0px !important',
    },
  },
  texContainer_error: {
    width: '100%',

    '& .MuiFormLabel-root': {
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-root': {
      fontSize: '11px',
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '4px !important',
    },
    '& .MuiIconButton-root': {
      padding: '0px !important',
    },
  },
  full_texContainer: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#282828',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
        border: '1px solid',
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiFormHelperText-root': {
      fontSize: '11px',
      fontFamily: "Plus Jakarta Sans",
    },
    '& .MuiIconButton-root': {
      padding: '0px !important',
    },
    '& .MuiInputBase-fullWidth': {
    },
  },
}));

const placeHolderNumGift = 'Ingresa número de la Gift card';
const placeHolderPinGift = 'Ingresa el Pin de la Gift card';
const maxLengthNumGift = 22;
const maxLengthNumGiftCard = 18;
const maxLengthPinGift = 6;
const maxLengthPinGiftCard = 4;

interface GiftCardFormProps {
  activeToast: ({ message, statusOk, timeOutVal }: { message: string, statusOk: boolean, timeOutVal: number, IconColor?: string }) => void
  blocked: boolean;
  isMobile?: boolean;
  payment?: IPaymentMethod;
  isOpenGiftcard: boolean;
  handleSelectPayment?: (payment: IPaymentMethod) => void
  onAddGiftCard: () => void
  onPaymentMethodCard: (payment: IPaymentMethod) => void
  setBlocked: (state) => void
  setIsOpenForm: (state) => void
  setPaymentSelected?: (payment: IPaymentMethod) => void
  setRulerObjext: React.Dispatch<React.SetStateAction<RuleObject>>
}

const GiftCardForm = ({
  blocked = false,
  isMobile = false,
  isOpenGiftcard,
  payment,
  activeToast,
  onAddGiftCard,
  handleSelectPayment = () => { return },
  onPaymentMethodCard = () => { return },
  setBlocked = () => { return },
  setIsOpenForm = () => { return },
  setPaymentSelected = () => { return },
  setRulerObjext = () => { return },
}: GiftCardFormProps) => {

  const {
    orderform,
    orderform: {
      clientProfileData,
      totalizerData,
      gifcardData
    },
    updateGiftCard,
    updateOrderform
  } = useContext(OrderformContext);
  const [showTooltipGiftNumber, setShowTooltipGiftNumber] = useState<boolean>(false)
  const [showTooltipGiftPin, setShowTooltipGiftPin] = useState<boolean>(false)
  const [attempts, setAttempts] = useState(0);
  const [giftCard, setGiftCard] = useState({
    numberGiftCard: '',
    passWordGiftCard: '',
    loading: false,
  } as any);
  const giftCardStorage = gifcardData;
  
  const classes = useStyles();
  const validatorGiftCard = () => {
    if (
      giftCard.passWordGiftCard.length >= 4 &&
      giftCard.numberGiftCard.length === 18
    ) {
      return false;
    } else {
      return true;
    }
  };

  function addDashes(valor: any) {
    let a: any = valor.replace(/-/g, '');

    let res = '';
    if (a.length <= 16) {
      const separate = 4;
      res = a
        .split('')
        .map((x: any, i: any) => (i > 0 && i % separate === 0 ? '-' + x : x))
        .join('');
    } else if (a.length > 16) {
      const separate = 4;
      const temp_a = a;
      a = a.substring(0, 16);
      let temp_length = temp_a.length - 16;
      let res_temp = a
        .split('')
        .map((x: any, i: any) => (i > 0 && i % separate === 0 ? '-' + x : x))
        .join('');
      res = `${res_temp}-${temp_a.substring(temp_a.length - temp_length)}`;
    }

    return res;
  }

  const paymentTags = ({ action, label }: { action: string, label: string }) => {
    return analytics.interactiveEvent({
      event: 'interacciones_checkout', category: 'checkout', action: action, label: label
    })
  }

  const giftCardTag = (eventAction = 'notificacion_error') => {
    analytics.errorEventTag({
      eventAction: eventAction,
      eventCategory: 'checkout',
      eventLabel: 'guardar_giftcard',
      paymentType: 'giftcard',
      cardType: 'giftcard'
    })
  }

  const errorReponse = (err) => {
    const statusBlocked = err?.code === 429
      if(err?.code === 500 || err?.code === 400 || err?.code === 429) {
        if (attempts + 1 >= 2) {
          setIsOpenForm(false)
          activeToast({
            IconColor: 'red',
            message: 'Por seguridad la Giftcard fue bloqueada, vuelve a intentar en 30 min.',
            statusOk: false,
            timeOutVal: 5000
          });
          setBlocked(true)
        } else {
          activeToast({
            message: statusBlocked ? 'Por seguridad la Giftcard fue bloqueada, vuelve a intentar en 30 min.' : 'No fue posible cargar tu Giftcard, intenta nuevamente.',
            statusOk: false,
            IconColor: 'red',
            timeOutVal: 5000
          })
          if (statusBlocked) {
            setBlocked(true)
            setIsOpenForm(false)
          }
        }
        setAttempts((prev: number) => prev + 1);
      } 
      paymentTags({ action: 'editar-giftcard', label: 'error-agregar-giftcard' })
      giftCardTag()
      setGiftCard({ ...giftCard, loading: false });
  }

  /**
   * Adds a gift card service.
   */
  const addGiftCardService = (): void => {
    analytics.interactiveEvent({ action: 'Click', label: 'Botón agregar gift card' })
    paymentTags({ action: 'editar-giftcard', label: 'agregar' })
    setGiftCard({ ...giftCard, loading: true });

    const sendService = {
      cardNumber: giftCard.numberGiftCard,
      cardPin: giftCard.passWordGiftCard,
      rut: clientProfileData.document,
    };

    helpers.setStorage(
      'giftCardStorage',
      JSON.stringify(sendService),
      null
    );

    CheckOut.giftCardPayment({
      cardNumber: giftCard.numberGiftCard,
      value: orderform.totalizerData.totalPayment.total,
      rut: orderform?.clientProfileData?.document,
      orderForm: orderform?.orderFormId,
      cardPin: giftCard.passWordGiftCard
    }).then(async (response) => {
      if(response?.error?.code) { 
        errorReponse(response.error);
        return
      }

      paymentTags({ action: 'editar-giftcard', label: 'correcto' });
      giftCardTag('notificacion_exito');
      updateGiftCard({ data: response?.balance, sendService: sendService });
    
      setPaymentSelected(payment);
      handleSelectPayment(payment);
      onPaymentMethodCard(payment);
    
      await CheckOut.ApiCheckout().then((response) => {
        if (response.status !== 'OK') {
          console.error(response.status);
        } else {
          updateOrderform(response.data);
        }
      });

      onAddGiftCard()
      setGiftCard({
        ...giftCard,
        passWordGiftCard: '',
        numberGiftCard: '',
        loading: false
      });
    }).catch((err) => {
      errorReponse(err);
    })
  };

  const isValidGiftCard = validatorGiftCard()
  const classMobile = isMobile ? '-mobile' : ''
  const texContainerClass = giftCard.numberGiftCard.length === maxLengthNumGiftCard ? classes.full_texContainer : classes.texContainer;
  const passwordContainerClass = giftCard.passWordGiftCard.length >= maxLengthPinGiftCard ? classes.full_texContainer : classes.texContainer;
  const isGiftCardNumberValid = (giftCard?.numberGiftCard?.length > 0 && giftCard?.numberGiftCard?.length < maxLengthNumGiftCard)
  const messageTooltipGiftNumber = isGiftCardNumberValid
    ? 'Ingresa un número válido de 18 dígitos'
    : ''
  const isGiftCardPasswordValid = (giftCard.passWordGiftCard.length > 0 && giftCard.passWordGiftCard.length < maxLengthPinGiftCard);
  const messageTooltipGiftPint = isGiftCardPasswordValid
    ? 'Ingresa un Pin válido de mínimo 4 dígitos'
    : ''
  const isBlocked = blocked ? 'blocked-disable' : 'color-error'
  const buttonClass = giftCard.passWordGiftCard.length >= 4 && giftCard.numberGiftCard.length === 18 ? isBlocked : `button_giftcard${classMobile}`

  return (
    <>
      {
        (giftCardStorage?.balance && !isOpenGiftcard && !blocked) ?
          <div style={{ paddingTop: '10px' }}>
            <GiftCard setGiftCard={setGiftCard} />
          </div>
          : (
            isOpenGiftcard && (
              <div style={{ paddingTop: '10px' }}>
                <div className={!isMobile ? 'giftCardContainer' : ''}>
                  <div
                    className={
                      giftCard.numberGiftCard.length > 0 &&
                        giftCard.numberGiftCard.length <
                        maxLengthNumGiftCard
                        ? classes.texContainer_error
                        : texContainerClass
                    }
                  >
                    <TextField
                      id='numero'
                      error={
                        showTooltipGiftNumber && !!isGiftCardNumberValid
                      }
                      label={placeHolderNumGift}
                      variant='outlined'
                      size='small'
                      type='text'
                      helperText={
                        showTooltipGiftNumber && messageTooltipGiftNumber
                      }
                      inputProps={{ maxLength: maxLengthNumGift }}
                      className={`input-giftcard-numero${classMobile}`}
                      value={addDashes(giftCard.numberGiftCard)}
                      onFocus={() => {
                        if (isGiftCardNumberValid) {
                          return setShowTooltipGiftNumber(true)
                        }
                        setShowTooltipGiftNumber(false)
                      }}
                      onBlur={() => {
                        if (isGiftCardNumberValid) {
                          return setShowTooltipGiftNumber(true)
                        }
                        setShowTooltipGiftNumber(false)
                      }}
                      onChange={(e: any) => {
                        let valor = e.target.value.replace(/-/g, '');
                        if (/^(\d*)$/.test(valor)) {
                          setGiftCard({
                            ...giftCard,
                            numberGiftCard: valor,
                          });
                        }
                      }}
                      InputProps={{
                        fullWidth: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            {giftCard.numberGiftCard.length > 0 && (
                              <IconButton
                                onClick={() => {
                                  setGiftCard({
                                    ...giftCard,
                                    numberGiftCard: '',
                                  });
                                }}
                              >
                                <CancelOutlinedIcon
                                  className={
                                    giftCard.numberGiftCard.length >
                                      0 &&
                                      giftCard.numberGiftCard.length <
                                      maxLengthNumGiftCard
                                      ? 'textoError'
                                      : 'textoOk'
                                  }
                                ></CancelOutlinedIcon>
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className={
                      giftCard.passWordGiftCard.length > 0 &&
                        giftCard.passWordGiftCard.length <
                        maxLengthPinGiftCard
                        ? classes.texContainer_error
                        : passwordContainerClass
                    }
                  >
                    <TextField
                      id='numero'
                      error={
                        showTooltipGiftPin && !!isGiftCardPasswordValid
                      }
                      label={placeHolderPinGift}
                      variant='outlined'
                      size='small'
                      type='text'
                      helperText={
                        showTooltipGiftPin && messageTooltipGiftPint
                      }
                      inputProps={{ maxLength: maxLengthPinGift }}
                      className={`input-giftcard-numero${classMobile}`}
                      value={giftCard.passWordGiftCard}
                      onFocus={() => {
                        if (isGiftCardPasswordValid) {
                          return setShowTooltipGiftPin(true)
                        }
                        setShowTooltipGiftPin(false)
                      }}
                      onBlur={() => {
                        if (isGiftCardPasswordValid) {
                          return setShowTooltipGiftPin(true)
                        }
                        setShowTooltipGiftPin(false)
                      }}
                      onChange={(e: any) => {
                        let valor = e.target.value.replace(/-/g, '');
                        if (/^(\d*)$/.test(valor)) {
                          setGiftCard({
                            ...giftCard,
                            passWordGiftCard: valor,
                          });
                        }
                      }}
                      InputProps={{
                        fullWidth: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            {giftCard.passWordGiftCard.length > 0 && (
                              <IconButton
                                onClick={() => {
                                  setGiftCard({
                                    ...giftCard,
                                    passWordGiftCard: '',
                                  });
                                }}
                              >
                                <CancelOutlinedIcon
                                  className={
                                    giftCard.passWordGiftCard.length >
                                      0 &&
                                      giftCard.passWordGiftCard.length <
                                      maxLengthPinGiftCard
                                      ? 'textoError'
                                      : 'textoOk'
                                  }
                                ></CancelOutlinedIcon>
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={
                    isMobile ? { width: '100%' }
                      : { width: '35%' }}>
                    <CoButton
                      className={buttonClass}
                      disabled={isValidGiftCard && !blocked}
                      handleClick={() => {
                        addGiftCardService();
                      }}
                      isLoading={giftCard.loading}
                    >
                      Agregar
                    </CoButton>
                  </div>
                </div>
                <div className='giftcard-warning'>
                  <p className='text-giftcard'>
                    Al pagar con gift card, el saldo deberá cubrir el total de tu compra. No podrás agregar o sustituir productos después de realizar tu pedido.
                  </p>
                </div>
              </div>
            )
          )
      }
    </>
  )
}

export default GiftCardForm
