import React, { useContext, useState } from "react";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import helpers from "../../../../utils/helpers";
import { OrderformContext } from "../../contexts/orderform.provider";
import CheckOut from "../../services/CheckOut.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.scss";

const GiftCard = (props: any) => {
  const { updateOrderform, updateValidSections, orderform, setIsLoadingSelectCard } = useContext(OrderformContext);
  const [isLoading, setIsLoading] = useState(false);
  const valueGiftCard = {
    ...(orderform.paymentData.giftCards?.find(gc => gc.id === orderform?.gifcardData?.id) || []),
    ...orderform?.gifcardData,
  }
  
  const trashIconStyle = {
    width: "15.45px",
    height: "15.45px",
    backgroundSize: "15.45px",
    margin: "auto",
    paddingTop: "18.45px",
  };
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const selected = true;

  const handleClickDelete = () => {
    setIsOpenDeleteDialog(true);
  };

  const handleClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleSubmitDelete = async () => {
    setIsLoading(true);
    setIsLoadingSelectCard(true);

    await CheckOut.deleteGiftCardV1({
      gifcardId: valueGiftCard.id,
      orderFormId: orderform.orderFormId,
    })
      .then(() => {
        props.setGiftCard({
          ...props.giftCard,
          passWordGiftCard: "",
          numberGiftCard: "",
        });
        updateValidSections(helpers.SECTIONS.PAYMENT, false);
      })
      .catch(() => {
        console.log("Fallo al actualizar el metodo de pagos");
      });
    
    await CheckOut.ApiCheckout().then((response) => {
      if (response.status === 'OK') {
        updateOrderform(response.data);
      }
    });
      
    handleClose();
    setIsLoading(false);
    setIsLoadingSelectCard(false);
  };

  return (
    <>
      <AlertDialog
        btnTextSecond='Cancelar'
        btnText="Eliminar"
        handleClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClose()
        }}
        handleSubmit={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleSubmitDelete();
        }}
        handleSubmitSecond={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          handleClose()
        }}
        isLoading={isLoading}
        isOpen={isOpenDeleteDialog}
        modalType='alert'
        title="¿Estás seguro que quieres eliminar tu Gift card?"
      >
        {`Si la eliminas, deberás volver a agregarla para poder utilizarla en tu compra.`}
      </AlertDialog >

      <div className={`oneclick-card ${selected ? "active" : ""}`}>
        {isLoading && <CircularProgress className="loader-card" size={40} />}
        <div className="trash-icon__wrapper">
          <div className="oneclick-card__card-Title">Número de Gift card</div>
          <div
            className={`trash-icon__circle icon${selected ? "-active" : ""}`}
          >
            <div
              className="trash-icon"
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();

                handleClickDelete()
              }}
              style={trashIconStyle}
            >
            </div>
          </div>
        </div>
        <div className="oneclick-card__content">
          <div className="oneclick-card__card-number">
            {valueGiftCard.caption}
          </div>
          <div className="oneclick-card__card-TitleSaldo">Saldo</div>
          <div className="oneclick-card__card-number">
            ${helpers.priceFilter(valueGiftCard.balance)}
          </div>
        </div>
      </div >
      <div className="giftcard-warning">
        <div className='warning-icon' />
        <p className="text-giftcard">
          <b>Importante:</b> Recuerda que al pagar con tu Giftcard el monto de tu compra debe ser  <b> menor o igual al saldo de la Giftcard.
            No podrás utilizar ningún otro medio de pago para complementar tu compra.</b>
        </p>
      </div>
    </>
  );
};

export default GiftCard;
