import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useWindowSize } from '../windowSize/use-window-size'
import '../AlertDialog/alert-dialog.styles.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      margin: '20px',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
    },
    '&.MuiButtonBase-root': {
      float: 'right',
    },
  },
  align: {
    paddingTop: '10px',
  },
}))

const errorIconStyle = {
  width: '40px',
  height: '20px',
  backgroundSize: '20px',
  marginRight: '1px',
}

const errorIconStyleMobile = {
  width: '30px',
  height: '20px',
  backgroundSize: '20px',
  marginRight: '10px',
}

interface AlertDialogModalProps {
  children?: React.ReactNode
  isOpen?: boolean
  header?: boolean
  title?: string
  handleClose?: () => void
}
const AlertDialogModal = (props: AlertDialogModalProps) => {
  const {
    isOpen,
    header = true,
    title,
    handleClose
  } = props
  const classes = useStyles()
  const isMobile = useWindowSize().width <= 711

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth={isMobile ? 'xl' : 'sm'}
      className={isMobile ? classes.root : ''}
      disableEnforceFocus
      disableBackdropClick
    >
      {header && (
        <div className={classes.align}>
          <div className='dialog-alert__close-icon'>
            <IconButton
              onClick={handleClose}
              style={{ float: 'right', marginBottom: '-30px' }}
            >
              {handleClose && <CloseIcon />}
            </IconButton>
          </div>
        </div>
      )}
      <div className='dialog-alert'>
        <div className='dialog-alert__content'>
          {title && (
            <div className='dialog-alert__title-section'>
              <div
                className='icon-error'
                style={isMobile ? errorIconStyleMobile : errorIconStyle}
              ></div>
              <div className='dialog-alert__title-text'>{title}</div>
            </div>
          )}
          <div >{props.children}</div>
        </div>
      </div>
    </Dialog>
  )
}

export default AlertDialogModal
