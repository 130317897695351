import React, { MouseEvent } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import "./co-button.styles.scss"

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    marginTop: '3px',
  },
}));

interface CoButtonProps {
  type?: "button" | "submit" | "reset";
  children: React.ReactNode;
  disabled?: boolean;
  handleClick: () => void;
  secondary?: boolean;
  isLoading?: boolean;
  className?: string;
  id?: string;
}

const CoButton: React.FC<CoButtonProps> = ({
  type = "button",
  children,
  disabled = false,
  handleClick,
  secondary = false,
  isLoading = false,
  className,
  id
}) => {
  const classes = useStyles();

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!isLoading && !disabled) {
      handleClick();
    }
  };

  return (
    <button
      id={id}
      type={type}
      className={`btn ${disabled ? 'disabled' : 'enabled'} ${secondary ? 'secondary' : ''} ${className || ''}`}
      onClick={handleButtonClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircularProgress className={classes.root} size={20} />
      ) : (
        children
      )}
    </button>
  );
};

export default CoButton;
