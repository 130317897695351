import TagManager from "react-gtm-module";
import helpers from "./helpers";
import { Data, Item } from "./IOrderForm";

const step2ChekcoutMark = (orderForm: any) => {
  const productsToMark = processProducts(orderForm.totalizers?.items);

  const mark = {
    dataLayer: {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step: 2,
            option: "Datos Personales",
          },
          products: productsToMark,
        },
      },
      orderForm: {
        cartQuantity: orderForm?.totalizers?.items.length,
        city: orderForm?.shippingData?.selectedAddresses[0]?.city,
        isDisposable: orderForm?.shippingData?.selectedAddresses[0]?.isDisposable,
        neighborhood: orderForm?.shippingData?.selectedAddresses[0]?.neighborhood,
        notAvailableItems: orderForm?.notAvailableItems,
        salesChannel: helpers.getCookie("co_sc"),
        state: orderForm?.shippingData?.selectedAddresses[0]?.state,
        totalizers: orderForm?.totalizers?.totalizers,
        userProfileId: orderForm?.userProfileId
      }
    },
  };

  TagManager.dataLayer(mark);
};

const paginaVirtualCheckoutMark = (orderForm: any) => {
  const mark = {
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/datos-personales",
      pagename: "Checkout - datos personales",
      userID: orderForm.userProfileId,
    },
  };
  TagManager.dataLayer(mark);
};

const step4CheckoutMark = (orderForm: any, typeCard: any, card: any) => {
  const productsToMark = processProducts(orderForm.totalizers?.items);

  const shippingDate = new Date(
    orderForm.shippingData.logisticsInfo[0].slas[0].deliveryWindow.startDateUtc
  );
  const endUtc = new Date(
    orderForm.shippingData.logisticsInfo[0].slas[0].deliveryWindow.endDateUtc
  );

  TagManager.dataLayer({
    dataLayer: {
      event: "checkout",
      "shipping-date": `${shippingDate.getUTCFullYear()}-${
        shippingDate.getUTCMonth() < 10
          ? "0" + shippingDate.getUTCMonth()
          : shippingDate.getUTCMonth()
      }-${
        shippingDate.getUTCDate() < 10
          ? "0" + shippingDate.getUTCDate()
          : shippingDate.getUTCDate()
      }`,
      "shipping-hour": `${
        shippingDate.getUTCHours() < 10
          ? "0" + shippingDate.getUTCHours()
          : shippingDate.getUTCHours()
      }:${
        shippingDate.getUTCMinutes() < 10
          ? "0" + shippingDate.getUTCMinutes()
          : shippingDate.getUTCMinutes()
      }:${
        shippingDate.getUTCSeconds() < 10
          ? "0" + shippingDate.getUTCSeconds()
          : shippingDate.getUTCSeconds()
      } - ${
        endUtc.getUTCHours() < 10
          ? "0" + endUtc.getUTCHours()
          : endUtc.getUTCHours()
      }:${
        endUtc.getUTCMinutes() < 10
          ? "0" + endUtc.getUTCMinutes()
          : endUtc.getUTCMinutes()
      }:${
        endUtc.getUTCSeconds() < 10
          ? "0" + endUtc.getUTCSeconds()
          : endUtc.getUTCSeconds()
      }`,

      "region-entrega": orderForm.shippingData.address.state,

      "ciudad-entrega": orderForm.shippingData.address.city,

      "comuna-entrega": orderForm.shippingData.address.neighborhood,

      "tipo-documento": "boleta",

      "medio-pago": card.name,

      "forma-entrega": orderForm.shippingData.logisticsInfo[0].selectedSla,
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: "Pago" },
          products: productsToMark,
        },
      },
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/pago",
      pagename: "Checkout - pago",
      userID: orderForm.userProfileId,
    },
  });
};

const processProducts = (products: any) => {
  if (!products) return [];
  let productsToMark: {
    name: any;
    id: any;
    price: any;
    quantity: any;
    brand: any;
    category: string;
    dimension10: string;
    dimension11: string;
  }[] = [];
  products.forEach((product: any) => {
    let descuento: any = 0;
    descuento = product.price - product.sellingPrice;
    if (descuento > 0) descuento = descuento.toString().slice(0, -2);

    productsToMark.push({
      name: product.name || "",
      id: product.id || "",
      price: product.sellingPrice.toString().slice(0, -2) || "",
      quantity: product.quantity || "",
      brand: product.additionalInfo.brandName || "",
      category: Object.values(product?.productCategories).join("/") || "",
      dimension11: descuento < 0 ? 0 : descuento || 0,
      dimension10: descuento > 0 ? "con oferta" : "sin oferta",
    });
  });

  return productsToMark;
};

const acceptPaymentError = (action: any, code: any) => {
  type codeOptions = {
    [key: string]: string;
  };
  const CODE_CASES: codeOptions = {
    "-1": "Modal verifica datos ingresados",
    "-2": "Modal transaccion fallida",
    "-4": "Modal transaccion no autorizada",
    "-5": "Modal transaccion fallida sin intento",
    "-96": "Modal transaccion fallida eliminar tarjeta",
    "-97": "Modal transaccion fallida monto diario excedido",
    "-98": "Modal transaccion fallida monto pedido excedido",
    "-99": "Modal transacciones diarias alcanzadas",
    "-100": "Modal transaccion anulada"
  };
  const eventCategory = CODE_CASES[code] || null;
  const mark = {
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria": eventCategory,
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  };
  if (eventCategory != null) {
  TagManager.dataLayer(mark);
  }
};

const modalPaymentError = (code: any, userId: any) => {
  type codeOptions = {
    [key: string]: object;
  };
  const CODE_CASES: codeOptions = {
    "-1": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-verifica-datos-ingresados",
        pagename: "Checkout - error - modal verifica datos ingresados",
        userID: userId,
      },
    },
    "-2": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida",
        pagename: "Checkout - error - modal transaccion fallida",
        userID: userId,
      },
    },
    "-4": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-no-autorizada",
        pagename: "Checkout - error - transaccion no autorizada",
        userID: userId,
      },
    },
    "-5": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-sin-intento",
        pagename: "Checkout - error - modal transaccion fallida sin intento",
        userID: userId,
      },
    },
    "-96": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-eliminar-tarjeta",
        pagename:
          "Checkout - error - modal transaccion fallida eliminar tarjeta",
        userID: userId,
      },
    },
    "-97": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-monto-diario-excedido",
        pagename:
          "Checkout - error - modal transaccion fallida monto diario excedido",
        userID: userId,
      },
    },
    "-98": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-monto-pedido-excedido",
        pagename:
          "Checkout - error - modal transaccion fallida monto pedido excedido",
        userID: userId,
      },
    },
    "-99": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transacciones-diarias-alcanzadas",
        pagename: "Checkout - error - modal transacciones diarias alcanzadas",
        userID: userId,
      },
    },

  };
  const dataSet = CODE_CASES[code] || null;

  if (dataSet != null) {
    TagManager.dataLayer(dataSet);
  }
};

const modalRedirectPayment = (userId: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/redireccion-medio-pago",
      pagename: "Checkout - redireccion al medio de pago",
      userID: userId.userProfileId,
    },
  });
};

const changeDirectionHour = (action: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria": "Interacciones checkout",
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  });
};

const modalWithoutStock = (products: any) => {
  let formatedProducts: any = [];

  products.map((e: any, index: any) => {
    formatedProducts.push({
      name: e.name,
      id: e.id,
      price: e.price,
      quantity: e.quantity,
      brand: e.additionalInfo.brandName,
      category: e.productCategories,
      position: index + 1,
      list: "Checkout - Productos no disponibles - mal catalogados",
    });
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "ecommerce",
      impressions: formatedProducts,
    },
  });
};

const modalWithoutStockAction = (action: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria":
        "Modal productos no disponibles - mal catalogados",
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  });
};

interface InteractiveEventProps {
  action: string;
  additionalInfo?: object;
  category?: string;
  event?: string;
  label?: string;
}

const interactiveEvent = (dataLayerInfo: InteractiveEventProps) => {
  TagManager.dataLayer({
    dataLayer: {
      'event': dataLayerInfo.event || 'evento_interactivo',
      'event_category': dataLayerInfo.category || 'interacciones checkout',
      'evento_action': dataLayerInfo.action,
      'evento_label': dataLayerInfo.label,
      ...dataLayerInfo.additionalInfo
    },
  });
};

const arrangedProductList = ({product, index}: {product: Item, index: number}) => {
  const categories = Object.values(product.productCategories)
  const price = Math.round(product?.priceDefinition?.calculatedSellingPrice / 100)
  const listPrice = Math.round(parseInt(product?.listPrice.toString()) * product?.unitMultiplier / 100)
  const savings = listPrice - price
  return {
    'precio-lista': listPrice,
    ahorro: savings,
    index: index,
    item_brand: product?.additionalInfo?.brandName,
    item_id: product['sku'] || product?.id || product?.productId,
    item_list_id: 'checkout',
    item_list_name: 'checkout',
    item_name: product?.name,
    oferta: savings > 0 ? 1 : 0,
    price: price,
    quantity: product?.quantity ?? 1,
    item_category: categories[0],
    item_category2: categories[1],
    item_category3: categories[2],
    stock: 1
  }
}

const addShipingInfo = (orderForm: Data) => {
  const items = orderForm?.totalizers?.items
  if (!Array.isArray(items)) return

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_shipping_info',
      currency: 'CLP',
      value: orderForm?.totalizers?.totalPayment?.total/100,
      assigned_store: `${helpers.getCookie('co_sc')} Unimarc - ${orderForm?.shippingData?.address?.neighborhood}`,
      saleschannel: helpers.getCookie('co_sc'),
      orderform_id: orderForm?.orderForm || orderForm?.orderFormId,
      ecommerce: {
        items: items.map((product: Item, index: number) => {
          return arrangedProductList({product, index: index + 1})
        })
      },
    },
  });
};

const setPaymentType = (payment: string) =>{
  const paymentLowerCase = payment?.toLowerCase()
  if (!paymentLowerCase) return null

  const paymentTypes = {
    'giftcard': 'Giftcard',
    'klap': 'Klap',
    'oneclick': 'Oneclick',
    'webpay': 'Webpay Plus'
  }

  return paymentTypes[paymentLowerCase]
}
const setCardType = (card: string, payment: string) =>{
  const paymentLowerCase = payment?.toLowerCase()
  const cardLowerCase = card?.toLowerCase()
  if (!paymentLowerCase || !cardLowerCase) return null

  const cardTypes = {
    'americanexpress': 'Crédito',
    'giftcard': 'Giftcard',
    'klap': 'Klap',
    'mastercard': 'Crédito',
    'prepago': 'Prepago',
    'redcompra': 'Débito',
    'visa': 'Crédito',
    'webpay': 'Crédito'
  }

  const paymentTypes = {
    'webpay': 'Crédito',
    'giftcard': 'Giftcard'
  }

  return paymentTypes[paymentLowerCase] === undefined ? cardTypes[cardLowerCase] : paymentTypes[paymentLowerCase]
}

interface AddPaymentInfoProps {
  orderForm: Data,
  paymentType: string,
  typeCard: string
}

const addPaymentInfo = ({
  orderForm,
  paymentType,
  typeCard
}: AddPaymentInfoProps) => {
  const totalizer = orderForm?.totalizers || orderForm?.totalizerData
  const items = totalizer?.items.map((product: Item, index: number) => {
    return arrangedProductList({product, index: index + 1})
  })

  const isDelivery = orderForm.shippingData?.selectedAddresses[0]?.addressType === 'residential'
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_payment_info',
      card_type: setCardType(typeCard, paymentType),
      currency: 'CLP',
      value: totalizer?.totalPayment?.total/100,
      orderform_id: orderForm?.orderForm,
      assigned_store: helpers.getCookie('co_sc') ? `${helpers.getCookie('co_sc')} Unimarc - ${orderForm?.shippingData?.address?.neighborhood}` : '1',
      saleschannel: helpers.getCookie('co_sc') || '1',
      shipping_tier: isDelivery ? 'Despacho a domicilio' : 'Retiro en tienda',
      payment_type: setPaymentType(paymentType),
      coupon: orderForm?.marketingData?.coupon,
      ecommerce: {
        items: items
      },
    },
  });
};


interface ErrorEventTagProps {
  cardType?: string,
  event?: string,
  eventAction?: string,
  eventCategory: string,
  eventLabel: string,
  paymentType?: string,
  additionalInfo?: {}
}

const errorEventTag = ({
  cardType,
  event,
  eventAction,
  eventCategory,
  eventLabel,
  paymentType,
  additionalInfo
}: ErrorEventTagProps) => {
  const eventData = {
    event: event || 'interacciones_checkout',
    categoria_evento: eventCategory,
    accion_evento: eventAction || 'modal_error',
    etiqueta_evento: eventLabel,
    payment_type: setPaymentType(paymentType),
    card_type: setCardType(cardType, paymentType),
    ...additionalInfo
  }

  TagManager.dataLayer({dataLayer: eventData})
}


export default {
  acceptPaymentError,
  addPaymentInfo,
  addShipingInfo,
  changeDirectionHour,
  errorEventTag,
  interactiveEvent,
  modalPaymentError,
  modalRedirectPayment,
  modalWithoutStock,
  modalWithoutStockAction,
  paginaVirtualCheckoutMark,
  setCardType,
  setPaymentType,
  step2ChekcoutMark,
  step4CheckoutMark
};
