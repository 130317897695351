import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'
import helpers from '../../utils/helpers'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import analytics from '../../utils/analytics'
import { OrderformContext } from '../CheckOut/contexts/orderform.provider'
import CheckOut from '../CheckOut/services/CheckOut.service'
import { ModalCard } from './../CheckOut/components/ModalCard/index';
import { Backbone } from '../CheckOut/components/ModalCard/Backbone'
import AlertDialogRedirect from '../../components/AlertDialogRedirect/AlertDialogRedirect'
import AlertDialog from '../../components/AlertDialog/AlertDialog'
import { useTimeOut } from '../../utils/useTimeOut'
import "./styles.scss";

ReactGA.plugin.require('ec')

export const Klap = (props: any) => {
  const {
    updateOrderform,
    isLoading,
    updateLoading,
    errorService,
    setDataKlap,
    setLoadingKlap,
    updateErrorService
  } = useContext(OrderformContext)

  const location = useLocation()
  const [openDialogAlertPayments, setOpenDialogAlertPayments] = useState({ message: '', state: false })
  const [tempRedirect, setTempRedirect] = useState(false)
  const [PaymentModalCodeError, setPaymentModalCodeError] = useState('' as any)
  const [flagService, setFlagService] = useState(false)
  const [gtmInit, setGtmInit] = useState(false)
  const [rulesObject, setRulerObjext] = useState({} as any)
  const userIdGTM = useRef('')
  const query = new URLSearchParams(location.search)
  //validation of session
  useTimeOut({ errorService, setRulerObjext, tempRedirect })

  const changeCss = () => {
    const navElement = document.querySelector('.totalizer') as HTMLInputElement
    if (!navElement) return

    navElement.style.top = (window.scrollY > 50 ? window.scrollY : 0) - 50 + 'px'
  }

  const rulesEngine = async (payload: any) => {
    let response = true
    if (
      payload &&
      (payload.message === 'No token' ||
        payload.message === 'No Autorizado' ||
        (payload.data && payload.data.totalizers.total_items < 1))
    ) {
      setRulerObjext({
        isOpen: true,
        title: 'Ingreso Incorrecto',
        action: helpers.redirectToHome,
        redirectTo: 'home',
        justify: 'justify',
        message: 'Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.',
      })

      response = false
    } else if (
      payload &&
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === '0002'
      )
    ) {
      // setRulerObjext({
      //   isOpen: true,
      //   title: 'No hay capacidad de despacho',
      //   action: helpers.redirectToCartWithSL,
      //   redirectTo: 'carro',
      //   justify: '',
      //   message:
      //     ' Lo sentimos, por ahora no tenemos capacidad para atender tu pedido. Por favor, intenta con otra dirección.',
      // })
      response = false
    } else if (
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === '0003'
      ) &&
      !payload.data?.exceptions?.find(
        (exception: any) => exception.code === '0002'
      )
    ) {
      setRulerObjext({
        isOpen: true,
        title: 'No tenemos cobertura de despacho',
        action: helpers.redirectToCartWithSL,
        redirectTo: 'carro',
        justify: 'justify',
        message: 'Lo sentimos, por ahora no tenemos cobertura de despacho para tu dirección. Por favor prueba con una distinta.',
      })
      response = false
    }

    return response
  }

  const processResponse = async (responseCheckout: any) => {
    const rulesResult = await rulesEngine(responseCheckout)
    if (rulesResult) {
      setLoadingKlap(true)
      if (gtmInit === false) {
        userIdGTM.current = responseCheckout.data.userProfileId
        analytics.step2ChekcoutMark(responseCheckout.data)
        analytics.paginaVirtualCheckoutMark(responseCheckout.data)
        analytics.addShipingInfo(responseCheckout.data)
      }
      MessagesPaymentsError(responseCheckout.data?.configurationFiles)
      setGtmInit(true)
      const { shippingData } = responseCheckout.data

      if (!shippingData) return
      let params = {}
      if (location.search) params = Object.fromEntries(new URLSearchParams(location.search))
      const paymentSystem = parseInt(params?.['payment_system'])
      if (!paymentSystem) {
        setRulerObjext({
          isOpen: true,
          title: 'Ingreso Incorrecto',
          action: helpers.redirectToHome,
          redirectTo: 'home',
          message:
            'Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.',
        })
        return
      }

      await CheckOut.updatePayment({ paymentSystem })
        .then(async (res) => {
          if (res.error) return

          await CheckOut.updateSelectedPaymentType({
            typeCard: paymentSystem === 203 ? 'debit' : 'credit',
            typePayment: 'Klap'
          }).then(async (response: any) => {
            if (response) window["KLAP"]?.init({ useBinLookup: true });

            const updateCheckout = await CheckOut.ApiCheckout()
            updateOrderform(updateCheckout.data)
          }).catch((response) => {
            if (response?.error) {
              setDataKlap({ orderId: '', paymentOrderId: '' })
            }
          })
        })
    }
    setLoadingKlap(false)
  }

  const modalErrorService = () => {
    updateErrorService({ ...errorService, openErrorService: true })
    const countRefresh: any = window.localStorage.getItem('refrehCount')
    let newContRefresh: any = parseInt(countRefresh) + 1
    window.localStorage.setItem('refrehCount', newContRefresh)
    if (newContRefresh > 3) {
      updateErrorService({ ...errorService, openErrorService: false })
      window.localStorage.setItem('refrehCount', '1')
      setTempRedirect(true)
    }
  }

  const regenerateOrderForm = async (params: any) => {
    CheckOut.regenerateOrderForm(params)
      .then(async (response: any) => {
        window.location.href = response.url
      })
      .catch((error) => {
        console.log(error, 'Fallo al regenerar el OrderForm')
      })
  }

  const initMode = async () => {
    await CheckOut.ApiCheckout()
      .then(async (response: any) => {
        if (response.error) {
          if (response.error.name === 'TokenExpiredError') {
            setRulerObjext({
              isOpen: true,
              title: 'Ingreso Incorrecto',
              action: helpers.redirectToHomeTokenExpired,
              redirectTo: 'home',
              message:
                ' Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.',
            })
          } else {
            if (response.error.code === 424) {
              const dataSendRegenerate = response.error.data
              regenerateOrderForm(dataSendRegenerate)
            } else {
              modalErrorService()
            }
          }
        } else {
          processResponse(response)
        }
      }).catch(() => {
        modalErrorService()
      })
  }

  const MessagesPaymentsError = async (error: any = []) => {
    const registrationStatus = query.get('error')
    setPaymentModalCodeError(registrationStatus)
    if (registrationStatus) {
      if (
        error.paymentsError?.find(
          (valor: any) => valor.code === parseInt(registrationStatus)
        )
      ) {
        const messageError = error.paymentsError?.find(
          (valor: any) => valor.code === parseInt(registrationStatus)
        ).message
        setOpenDialogAlertPayments({ state: true, message: messageError })
        analytics.modalPaymentError(registrationStatus, userIdGTM.current)
      }
    }
  }

  const closePaymentErrorModal = () => {
    analytics.acceptPaymentError('Cerrar', PaymentModalCodeError)
  }

  const acceptPaymentErrorModal = () => {
    analytics.acceptPaymentError('Aceptar', PaymentModalCodeError)
  }

  useEffect(() => {
    window.addEventListener('scroll', changeCss, false)

    return () => {
      window.removeEventListener('scroll', changeCss, false)
    }
  }, [])

  useEffect(() => {
    updateLoading(true)
    if (!window.localStorage.getItem('refrehCount')) {
      window.localStorage.setItem('refrehCount', '1')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search])

  useEffect(() => {
    if (isLoading && parseInt(helpers.getCookie('co_sc')) > 0 && !flagService) {
      setFlagService(true)
      initMode()
    }
    //eslint-disable-next-line
  }, [flagService, isLoading])

  useEffect(() => {
    const url = helpers.API_ROUTES.KLAP_PAYMENT_SCRIPT[process.env.REACT_APP_ENVIRONMENT || "error"];
    const script = window.document.createElement('script');
    script.src = url;

    const handleScriptLoad = () => {
      if (window?.['KLAP']) {
        window["KLAP"]?.init({ useBinLookup: true });
      }
    };

    script.addEventListener('load', handleScriptLoad);
    window.document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', handleScriptLoad);
      window.document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='container-klap'>

      <AlertDialog
        btnText='Aceptar'
        btnTextSecond='Cancelar'
        handleClose={() => {
          setOpenDialogAlertPayments({ state: false, message: '' })
          closePaymentErrorModal()
        }}
        handleSubmit={() => {
          setOpenDialogAlertPayments({ state: false, message: '' })
          acceptPaymentErrorModal()
        }}
        handleSubmitSecond={() => {
          setOpenDialogAlertPayments({ state: false, message: '' })
          closePaymentErrorModal()
        }}
        isOpen={openDialogAlertPayments.state}
        modalType='alert'
        title='Error en la transacción'
      >
        <p style={{ textAlign: 'left' }}>{openDialogAlertPayments.message}</p>
      </AlertDialog>
      {rulesObject.isOpen && (
        <AlertDialogRedirect
          isOpen={rulesObject.isOpen}
          title={rulesObject.title}
          timeout={5}
          handleDialogAction={rulesObject.action}
          redirectTo={rulesObject.redirectTo}
        >
          <p
            style={{
              textAlign: rulesObject.justify,
              whiteSpace: 'pre-line',
            }}
          >
            {rulesObject.message}
          </p>
        </AlertDialogRedirect>
      )}
      <AlertDialogRedirect
        isOpen={tempRedirect}
        title='Problemas al cargar'
        timeout={5}
        handleDialogAction={helpers.redirectToHome}
        redirectTo='home'
      >
        <p
          style={{
            textAlign: 'justify',
            whiteSpace: 'pre-line',
            margin: 0,
          }}
        >
          Lo sentimos tenemos problemas al cargar la información de compra.
          Por favor intenta más tarde.
        </p>
      </AlertDialogRedirect>
      {isLoading ? <Backbone /> : <ModalCard showTitle={false} />}
    </div>
  )
}
