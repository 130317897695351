import React from "react"
import { LogoHeaderUnimarcCL } from "../SvgComponent/SvgIcons/LogoHeaderUnimarcCL"
import { 
Container, 
Link, 
makeStyles,
} from "@material-ui/core"
import helpers from '../../utils/helpers'

const useStyles = makeStyles(({
    header: {
        height: '74px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100vw',
        boxShadow: '0px 0px 8px 0px rgba(27, 33, 56, 0.06)',
        position: 'relative',
        zIndex: 2
    }
}))

export const Header = () => {
    const classes = useStyles()
    const sizeMap = 150

    return (
        <Container component='header' className={classes.header}>
            <Link href={helpers.REDIRECT_URL_HOME} rel="noopener">
                <LogoHeaderUnimarcCL sizemap={sizeMap} />
            </Link>
        </Container>
    )
}