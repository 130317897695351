import React from 'react';
import CoButton from '../../../../components/Buttons/CoButton';

export const PayBtn = ({
  isReadyToPay,
  isSelectingDate,
  paymentData,
  selectedPaymentMethod,
  isLoadingSelectCard,
  state,
  handleSubmit = () => { return },
  setter = () => { return }
}) => {

  let btnContent = { disabled: true, payText: 'Pagar' }
  switch (selectedPaymentMethod.toLowerCase()) {
    case 'webpay':
    case 'klap':
      btnContent = { disabled: false, payText: 'Pagar' }
      break;
    case 'banco_estado':  
    case 'oneclick':
    case 'debit':
    case 'credit':
      const emptyCards = paymentData?.payments?.length === 0 || paymentData?.payments[0]?.paymentSystem === '30'
        || paymentData?.cardsOneclick?.length === 0
      btnContent = { disabled: false, payText: emptyCards ? 'Agregar nueva tarjeta' : 'Pagar' }
      break;
    case 'giftcard':
      const emptyGiftCards = paymentData.giftCards.length === 0    
      btnContent = { disabled: emptyGiftCards, payText: 'Pagar' }
      break;
  }

  const disabledCondition = !isReadyToPay || isSelectingDate || btnContent.disabled || !state
  const isDisabled = btnContent.disabled || isSelectingDate || !state

  return (
    <CoButton
      isLoading={isLoadingSelectCard}
      disabled={btnContent.payText === 'Agregar nueva tarjeta' ? isDisabled : disabledCondition}
      handleClick={() => {
        return btnContent.payText === 'Pagar' ? handleSubmit() : setter()
      }}>
      {btnContent.payText}
    </CoButton>
  )
}
