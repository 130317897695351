import React from 'react'
import './NewPaymentLoader.styles.scss'

export const NewPaymentLoader = () => {
    return (
        <div className='backbones-container'>
            <div className='card-loader methodPayment' title='loading' />
            <div className='card-loader methodPayment' title='loading' />
            <div className='card-loader methodPayment' title='loading' />
        </div>
    )
}
