import React from 'react';
import "./container.styles.scss"

const Container = (props: any) => {
  return (
    <div className="container">
      <div className='container__head'>
        {props.title && <h3 className="container__title">{props.title}</h3>}
        {props.componentTittle ?? null}
      </div>
      {props.hasLine && <div className="horizontal-line" /> }
      <div>
        {props.children}
      </div>
    </div>
  )
}

export default Container;
