import React, { useContext } from "react";
import AlertDialog from "../AlertDialog/AlertDialog";
import { useWindowSize } from "../windowSize/use-window-size";
import DraggableContainer from "../DraggableContainer";
import { OrderformContext } from "../../scenes/CheckOut/contexts/orderform.provider";

export interface AlertTermAndConditionsProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onOpenTerms: () => void;
  onOpenPrivacy: () => void;
}

export const AlertTermAndConditions: React.FC<AlertTermAndConditionsProps> = ({
  open,
  onSubmit,
  onClose,
  onOpenPrivacy,
  onOpenTerms,
}) => {
  const { activeToast } = useContext(OrderformContext);
  const isMobile = useWindowSize().width <= 1024;
  const ref = React.useRef(null);

  const handleClose = () => {
    activeToast({
      IconColor: "info",
      message:
        "Para pagar acepta  los términos y condiciones y políticas de privacidad.",
      statusOk: false,
      timeOutVal: 5000,
    });
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  if (isMobile) {
    return (
      <DraggableContainer
        halfScreen={true}
        zIndex={1000}
        isOpen={open}
        onClose={() => {
          handleClose();
        }}
        modalRef={ref}
        padding={0}
        minHeight="275px"
        showCloseButton={true}
      >
        <div
          className="modal"
          style={{
            height: 165,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="modal-header_title">
              <h2>¡Falta poco!</h2>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "0 20px",
            }}
          >
            <p>
              Para continuar comprando en Unimarc, deberás aceptar{" "}
              <span
                style={{
                  color: "#282828",
                  fontSize: 16,
                  fontWeight: 600,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={onOpenTerms}
              >
                los términos y condiciones
              </span>{" "}
              <span
                style={{
                  color: "#282828",
                  fontSize: 16,
                  fontWeight: 600,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={onOpenPrivacy}
              >
                y las políticas de privacidad.
              </span>
            </p>
          </div>
          <div className="modal-footer" style={{ padding: "12px 16px" }}>
            <button
              onClick={handleSubmit}
              style={{
                width: 343,
                height: 40,
                borderRadius: "100px",
                backgroundColor: "#000000",
                color: "#ffffff",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </DraggableContainer>
    );
  }

  return (
    <AlertDialog
      btnText="Aceptar"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleSubmitSecond={handleSubmit}
      isOpen={open}
      modalType="form"
    >
      <div style={{ width: 380, height: 113 }}>
        <p
          style={{
            color: "#282828",
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          ¡Falta poco!
        </p>

        <p
          style={{
            color: "#282828",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Para continuar comprando en Unimarc, deberás aceptar{" "}
          <span
            style={{
              color: "#282828",
              fontSize: 16,
              fontWeight: 600,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={onOpenTerms}
          >
            los términos y condiciones
          </span>{" "}
          <span
            style={{
              color: "#282828",
              fontSize: 16,
              fontWeight: 600,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={onOpenPrivacy}
          >
            y las políticas de privacidad.
          </span>
        </p>
      </div>
    </AlertDialog>
  );
};
