
import React from 'react'
import './card.styles.scss'

interface FlipCardProps {
  backChild?: React.ReactNode;
  buttonClose?: boolean;
  disabled?: boolean;
  flipped?: boolean;
  frontChild?: React.ReactNode;
  onClick?: () => void;
  setFlipped?: (flipped: boolean) => void;
}
export const FlipCard = ({
  backChild,
  buttonClose = false,
  disabled,
  flipped = false,
  frontChild,
  onClick = () => { return },
  setFlipped = () => { return }
}: FlipCardProps) => {

  return (
    <div className='content_modal' onClick={() => { return onClick() }}>
      <div className={`flip-card ${flipped ? 'flipped' : ''}`}>
        <div className='flip-card-inner'>
          <div className={`flip-card-front${disabled ? '__disable-card' : ''}`}>
            {frontChild}
          </div>
          <div className={`flip-card-back${disabled ? '__disable-card' : ''}`}>
            {backChild}
            {buttonClose &&
              <div className='flip-icon icon-back' onClick={() => { setFlipped(!flipped) }} >
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
