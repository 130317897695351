import React, { useContext, useRef } from 'react'
import AlertDialogModal from '../../../../components/AlertDialogModal/AlertDialogModal'
import DraggableContainer from '../../../../components/DraggableContainer'
import { useWindowSize } from '../../../../components/windowSize/use-window-size'
import { OrderformContext } from '../../contexts/orderform.provider'

export const ContainerCard = (props) => {
    const isMobile = useWindowSize().width <= 1024
    const ref = useRef<HTMLDivElement>(null)
    const { setOpenModalCard, openModalCard } = useContext(OrderformContext)

    return (
        <>
            {isMobile ?
                <DraggableContainer
                    isOpen={openModalCard}
                    onClose={() => { return setOpenModalCard(false) }}
                    modalRef={ref}
                    padding={0}
                >
                    {props.children}
                </DraggableContainer> :
                <AlertDialogModal
                    isOpen={openModalCard}
                    header={false}
                    handleClose={() => { return setOpenModalCard(false) }}
                >
                    {props.children}
                </AlertDialogModal>
            }
        </>
    )
}
