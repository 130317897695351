import React from "react";
import "./wait-message.styles.scss";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      margin: "20px",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
    },
    "&.MuiButtonBase-root": {
      float: "right",
    },
  },
}));

const errorIconStyle = {
    width: "20px",
    height: "20px",
    backgroundSize:"20px",
    marginRight:"10px"
  }
  
  const errorIconStyleMobile = {
    width: "30px",
    height: "20px",
    backgroundSize:"20px",
    marginRight:"10px"
  }
  

const WaitMessage = (props: any) => {
  const {
    title,
    isOpen,
  } = props;
  const classes = useStyles();
  const isMobile = useWindowSize().width <= 711;

  return (
    <Dialog
      open={isOpen}
      maxWidth={isMobile ? "xl" : "sm"}
      className={isMobile ? classes.root : ""}
    >
      <div className="dialog-wait-message">
        <div className="dialog-wait-message__content">
          {title && (
            <div className="dialog-wait-message__title-section">
              <div
                className="icon-lock"
                style={isMobile ? errorIconStyleMobile : errorIconStyle}
              ></div>
              <div className="dialog-wait-message__title-text">{title}</div>
            </div>
          )}
          <div className="dialog-wait-message__body-text">{props.children}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default WaitMessage;
