import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import OrderformProvider from './scenes/CheckOut/contexts/orderform.provider'
import './App.scss';


ReactDOM.render(
    <OrderformProvider>
        <App />
    </OrderformProvider> ,document.getElementById('root'));

serviceWorker.unregister();
