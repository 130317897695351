import React, {
    useContext,
    useEffect
} from 'react'
import analytics from '../../../../../utils/analytics'
import CoButton from '../../../../../components/Buttons/CoButton'
import { OrderformContext } from './../../../contexts/orderform.provider'
import errorIcon from './../../../../../assets/images/errorIcon.svg'
import closeIcon from './../../../../../assets/images/close.svg'
import IconButton from '@material-ui/core/IconButton'
import './modalAlert.styles.scss'

export const ModalAlert = () => {
    const { setOpenAlertModalKlap, modalAlertKlap } = useContext(OrderformContext)
    const { title, description } = modalAlertKlap

    useEffect(() => {
        analytics.interactiveEvent({
            event: 'interacciones_checkout',
            category:  'checkout',
            action: 'modal_error',
            label: 'error_transaccion',
            additionalInfo: {
                payment_type: 'Klap',
                card_type: 'Klap'
            }
        })
    }, [])

    return (
        <>
            <div className='modal-alert__overlay' ></div>
            <div className='modal-alert__content' >
                <div className='modal-alert'>
                    <img className='modal-alert__icon' src={errorIcon} alt='' />
                    <IconButton onClick={() => {
                        return setOpenAlertModalKlap((prev) => {
                            return {
                                ...prev,
                                open: false
                            }
                        })
                    }}
                        className='modal-alert__btn-close' >
                        <img
                            alt=''
                            className='modal-alert__icon-close'
                            src={closeIcon}
                        />
                    </IconButton>
                    <div>
                        <h3 className='title'>
                            {title}
                        </h3>
                    </div>
                    <div>
                        <p className='description'>
                            {description}
                        </p>
                    </div>
                    <div className='modal-alert-footer'>
                        <CoButton
                            disabled={false}
                            handleClick={() => {
                                return setOpenAlertModalKlap((prev) => {
                                    return {
                                        ...prev,
                                        open: false
                                    }
                                })
                            }}
                            isLoading={false}
                            className='modal-alert__button-action'
                        >
                            Reintentar
                        </CoButton>
                    </div>
                </div>
            </div>
        </>
    )
}
