import React, { useContext, useEffect, useState } from "react";
import { IPaymentMethod } from "../INewPayment";
import { OrderformContext } from "../../../contexts/orderform.provider";
import PaymentMethodCard from "../PaymentMethodCard";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckOut from "../../../services/CheckOut.service";
import { useWindowSize } from "../../../../../components/windowSize/use-window-size";
import CoButton from "../../../../../components/Buttons/CoButton";
import ArrowLeft from "../../../../../assets/images/arrowLeft.svg";
import analytics from "../../../../../utils/analytics";
import helpers from "../../../../../utils/helpers";
import { RuleObject } from "../../../../CheckOut";
import styles from "../PaymentMethodCard/PaymentMethodCard.module.css";
import styles2 from "./PaymentsModal.module.css";
import { BANCO_ESTADO_CREDIT } from "../../../../../ constants";

interface PaymentsModalProps {
  cardsPayments?: IPaymentMethod[];
  haveSavedCards?: boolean;
  isCard?: boolean;
  isKlap?: boolean;
  paymentSelected: IPaymentMethod;
  changePayment?: () => void;
  onBack?: () => void;
  onContinue?: () => void;
  onSelectPayment?: (payment: IPaymentMethod) => void;
  setPaymentSelected?: (payment: IPaymentMethod) => void;
  setRulerObjext: React.Dispatch<React.SetStateAction<RuleObject>>;
  handleSelectPayment?: (payment: IPaymentMethod) => void;
}
const METHOD_NAMES = ["Webpay", BANCO_ESTADO_CREDIT];

const PaymentsModal = ({
  cardsPayments = [],
  haveSavedCards = false,
  isCard = false,
  isKlap = false,
  paymentSelected,
  changePayment,
  onBack = () => {
    return;
  },
  onContinue = () => {
    return;
  },
  onSelectPayment = () => {
    return;
  },
  handleSelectPayment = () => {
    return;
  },
  setPaymentSelected = () => {
    return;
  },
  setRulerObjext = () => {
    return;
  },
}: PaymentsModalProps) => {
  const {
    setOpenModalCard,
    setLoadingKlap,
    updateOrderform,
    orderform: { paymentData, totalizerData },
    errorService,
    updateErrorService,
    setIsOpenWaitMessage,
  } = useContext(OrderformContext);
  const [localPaymentSelected, setLocalPaymentSelected] =
    useState<IPaymentMethod>(paymentSelected);
  const [methodsPaymentFiltered, setMethodsPaymentFiltered] =
    useState<IPaymentMethod[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useWindowSize().width <= 1024;
  const coButtonDisabled =
    !localPaymentSelected ||
    localPaymentSelected?.paymentId === 0 ||
    !methodsPaymentFiltered?.find(
      (method) => method?.paymentId === localPaymentSelected?.paymentId
    );

  const responseService = async (response: any) => {
    if (response.status !== "OK") {
      console.log(response.status);
    } else {
      await updateOrderform(response.data);
    }
  };
  const setter = () => {
    helpers.addCard();
    analytics.interactiveEvent({
      action: "Click",
      label: "Botón agregar nueva tarjeta",
    });
    analytics.interactiveEvent({
      action: "editar-pago",
      category: "checkout",
      event: "interacciones_checkout",
      label: "agregar-nueva-tarjeta",
    });
  };

  const selectPaymentMethodTag = (isWebpay = false) => {
    analytics.interactiveEvent({
      event: "interacciones_checkout",
      category: "checkout",
      action: "selecciona_metodo_pago",
      additionalInfo: {
        payment_type: isWebpay ? "crédito con cuotas" : "débito, crédito",
        context: `tarjeta_${haveSavedCards ? "existente" : "nueva"}`,
      },
    });
  };

  const handleContinue = async () => {
    setIsLoading(true);

    let needContinue = false;

    if (localPaymentSelected.id === "Webpay") {
      selectPaymentMethodTag(true);
      setIsOpenWaitMessage(true);
      await CheckOut.updateSelectedPaymentType({
        typePayment: "webPay",
      })
        .then(async (response) => {
          await responseService(response);
          await CheckOut.generateTransaction({ giftCardVariant: false })
            .then((responseTransaction) => {
              if (responseTransaction.data.url) {
                window.location.href = responseTransaction.data.url;
              } else {
                updateErrorService({ ...errorService, openErrorService: true });
              }
            })
            .catch(() => console.log("Fallo al realizar pago"));
        })
        .catch(() => {
          console.log("Fallo al actualizar el método de pago");
        })
        .finally(() => {
          needContinue = true;
        });
    } else if (
      localPaymentSelected?.code?.includes(202) ||
      localPaymentSelected?.code?.includes(204)
    ) {
      selectPaymentMethodTag(false);
      await CheckOut.updateSelectedPaymentType({
        typeCard: localPaymentSelected.id?.toLowerCase(),
        idCard: localPaymentSelected.paymentId,
        typePayment: "oneClick",
      }).finally(() => {
        if (!isCard) setter();
      });
    } else if (
      localPaymentSelected?.code?.includes(
        parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_CREDIT)
      ) ||
      localPaymentSelected?.code?.includes(
        parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_DEBIT)
      )
    ) {
      if (!isCard) {
        setter();
        return;
      }
      const paymentSystem = localPaymentSelected?.code[0];
      await CheckOut.updatePayment({ paymentSystem }).then(async (res) => {
        if (res.error) return;
        await CheckOut.updateSelectedPaymentType({
          typeCard: localPaymentSelected.type?.toLowerCase(),
          idCard: localPaymentSelected.paymentId,
          typePayment: "Banco_Estado",
        });
      });
    } else {
      const paymentSystem = localPaymentSelected?.code[0];
      if (localPaymentSelected?.id !== "giftcard") {
        selectPaymentMethodTag(false);
        setLoadingKlap(true);
        await CheckOut.updatePayment({ paymentSystem })
          .then(async (res) => {
            if (res.error) return;
            await CheckOut.updateSelectedPaymentType({
              typeCard: localPaymentSelected.type?.toLowerCase(),
              idCard: localPaymentSelected.paymentId,
              typePayment: "Klap",
            });
          })
          .finally(() => {
            needContinue = true;
            setLoadingKlap(false);
          });
      }
    }

    await CheckOut.ApiCheckout().then((response) => {
      updateOrderform(response.data);
      setIsLoading(false);
    });

    const isValidToContinue = needContinue && !localPaymentSelected?.isCard;
    setOpenModalCard(isValidToContinue);
    if (localPaymentSelected?.isCard) onSelectPayment(localPaymentSelected);
    if (isValidToContinue) onContinue();
  };

  useEffect(() => {
    const codes = isKlap ? [203, 205] : [202, 204];
    const tempMethodsPayments = codes.map((code) => {
      const name = [205, 202].includes(code) ? "Crédito" : "Débito";
      const type = [205, 202].includes(code) ? "credit" : "debit";
      return {
        type,
        code: [code],
        description: "Tu tarjeta se guardará",
        id: isKlap ? "Klap" : "oneClick",
        name,
        paymentId: code,
        state: false,
      };
    });

    tempMethodsPayments.push(
      ...paymentData?.paymentSystems
        .filter((method) => {
          return METHOD_NAMES.includes(method.name);
        })
        .map((method) => {
          if (!METHOD_NAMES.includes(method.name)) {
            return {
              ...method,
              code: [parseInt(method.stringId)],
              paymentId: parseInt(method.stringId),
              description: "Tu tarjeta no se guardará",
              id: method.name,
              info: "-",
              name: "Crédito con cuotas",
            };
          }

          if (method.name === BANCO_ESTADO_CREDIT) {
            return {
              ...method,
              name: "Banco Estado",
              description: "Descuento cliente exclusivo",
              code: [parseInt(method.stringId)],
              paymentId: parseInt(method.stringId),
              id: method.name,
            };
          }

          return {
            ...method,
            code: [parseInt(method.stringId)],
            paymentId: parseInt(method.stringId),
            id: method.name,
          };
        })
    );

    const dataCards = isCard ? cardsPayments : tempMethodsPayments;
    setMethodsPaymentFiltered(dataCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCard]);

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header_title">
          {!isCard &&
            isMobile &&
            paymentData?.cardsOneclick?.length > 0 &&
            methodsPaymentFiltered?.find((method) => {
              return method?.isCard;
            }) && (
              <IconButton className="modal-header_button" onClick={onBack}>
                <img src={ArrowLeft} alt="" className="modal-image__card" />
              </IconButton>
            )}
          <h2 className="modal-title">
            {isCard ? "Mis tarjetas" : "Seleccionar método de pago"}
          </h2>
          <hr />
        </div>
        {!isMobile && (
          <IconButton
            className="modal-header_button"
            onClick={() => setOpenModalCard(false)}
            style={{ float: "right", backgroundColor: "#F5F5F5" }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <div
        className={`payment-containers payment-containers--mobile${
          !isCard ? "--scrool" : ""
        }`}
      >
        {isKlap && !isCard && !isMobile && (
          <div className={styles2["payment-alert-klap"]}>
            <p>¡Tenemos una nueva experiencia para ti!</p>
            <p>Agrega tu tarjeta y finaliza tu compra 🎉</p>
          </div>
        )}
        {isKlap && !isCard && isMobile && (
          <div className={styles2["payment-alert-klap"]}>
            <p>
              ¡Tenemos una nueva experiencia para ti! Agrega tu tarjeta y
              finaliza tu compra 🥳
            </p>
          </div>
        )}
        <div
          className={`payment-containers--wapper${isCard ? "--scrool" : ""}`}
        >
          {methodsPaymentFiltered?.map((payment) => (
            <PaymentMethodCard
              isSimple={true}
              isModal={true}
              key={`payment__method__${payment?.id}__${payment?.paymentId}`}
              payment={payment}
              radio={true}
              setRulerObjext={setRulerObjext}
              selected={payment?.paymentId === localPaymentSelected?.paymentId}
              setPaymentSelected={setPaymentSelected}
              onPaymentMethodCard={setLocalPaymentSelected}
              handleSelectPayment={handleSelectPayment}
              total={totalizerData?.totalPayment?.total}
              isSelectPaymentMethod={!isCard}
            />
          ))}
        </div>
      </div>
      <div className={styles.newPayment__shieldContainer}>
        <div className={styles.newPayment__shieldIcon} />
        <p className={styles.newPayment__shieldText}>
          Tu información de pago está segura con nosotros
        </p>
      </div>
      <div className="form-group__submit form-group__buttons">
        <div className="form-co__containers">
          <CoButton
            className={`form-group__btn ${
              !coButtonDisabled && "form-group__btngrey"
            }`}
            disabled={coButtonDisabled}
            handleClick={handleContinue}
            id="payBtn"
            isLoading={isLoading}
          >
            Continuar
          </CoButton>
          {isCard && (
            <CoButton
              className={`form-group__btn form-group__btnoutlinegrey`}
              disabled={isLoading}
              handleClick={changePayment}
              id="payBtn"
              isLoading={false}
            >
              Seleccionar otro método de pago
            </CoButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentsModal;
