import React, { createContext, useState, useEffect } from "react";

interface ErrorObject {
  message: string;
  statusOk?: boolean;
  IconColor?: string;
  timeOutVal?: number;
}

type ContextProps = {
  isValidSections: any;
  dataKlap: any;
  errorService: any;
  giftCard: any;
  isLoading: boolean;
  isLoadingSelectCard: any;
  isReadyToPay: boolean;
  loadingKlap: any;
  modalAlertKlap: any;
  openModalCard: boolean;
  orderform: any;
  setDataKlap: any;
  setIsLoadingSelectCard: any;
  setLoadingKlap: any;
  setOpenAlertModalKlap: any;
  setOpenModalCard: any;
  setShippingData: any;
  updateErrorService: any;
  updateGiftCard: any;
  updateLoading: any;
  updateOrderform: any;
  updateOrderFormData: any;
  updateValidSections: any;
  error: ErrorObject | null;
  isOpenWaitMessage: any;
  setIsOpenWaitMessage: any;
  activeToast: (err: ErrorObject) => void;
  setError: (err: ErrorObject) => void;
};

export const OrderformContext = createContext<Partial<ContextProps>>({});

const OrdeformProvider = (props: { children: React.ReactNode }) => {
  const [error, setError] = useState<ErrorObject | null>(null);

  /**
   * Function to set the error and display the alert box.
   * @param {ErrorObject} err - The error object to be displayed.
   */
  const activeToast = (err: ErrorObject): void => {
    setError(err);
  };

  const [openModalCard, setOpenModalCard] = useState<boolean>(false);
  const [isLoadingSelectCard, setIsLoadingSelectCard] = useState<boolean>(true);
  const [isOpenWaitMessage, setIsOpenWaitMessage] = useState(false);
  const [orderform, setOrderform] = useState({
    clientProfileData: null,
    configurationFiles: null,
    corporateData: null,
    exceptions: null,
    marketingData: null,
    middlewarecard: null,
    paymentData: null,
    shippingData: null,
    totalizerData: null,
    updateSelectedPaymentType: null,

    // corporateData: null
  } as any);
  const [isReadyToPay, setIsReadyToPay] = useState(false);
  const [isValidSections, setIsValidSections] = useState({
    profile: null,
    shipping: true,
    payment: false,
    totalizer: null,
  });
  const [modalAlertKlap, setOpenAlertModalKlap] = useState({
    open: false,
    title: "Problemas al cargar la información",
    description:
      "Lo sentimos, tuvimos un error al cargar tu información, por favor, intenta de nuevo.",
  });
  const [giftCard, setGiftCard] = useState({} as any);
  const [dataKlap, setDataKlap] = useState({});
  const [loadingKlap, setLoadingKlap] = useState(false);
  const [errorService, setErrorService] = useState({
    openErrorService: false,
    btnTexto: null,
    title: null,
    refreshPage: true,
  } as any);

  const [isLoading, setIsLoading] = useState(true);

  const updateValidSections = (sectionName: string, isValid: boolean) => {
    setIsValidSections((prevState) => {
      return {
        ...prevState,
        [sectionName]: isValid,
      };
    });
  };

  const updateGiftCard = (giftCard: any) => {
    setGiftCard(giftCard);
  };

  const updateErrorService = (errorService: any) => {
    setErrorService(errorService);
  };

  const updateOrderform = (updatedOrderform: any) => {
    let corporateData = null;
    let middlewarecard = null;
    let cardsOneclickToUpdate = null;

    if (!updatedOrderform?.isLoginTemp) {
      if (
        updatedOrderform?.customData?.customApps?.find(
          (customApp: any) => customApp.id === "clientprofiledata"
        )
      ) {
        corporateData = {
          ...JSON.parse(
            updatedOrderform?.customData?.customApps?.find(
              (customApp: any) => customApp.id === "clientprofiledata"
            ).fields?.corporateData
          ),
        };
      }

      if (
        updatedOrderform.customData?.customApps?.find(
          (customApp: any) => customApp.id === "middlewarecard"
        )
      ) {
        middlewarecard = {
          ...updatedOrderform.customData?.customApps?.find(
            (customApp: any) => customApp.id === "middlewarecard"
          ).fields,
        };
      }

      if (updatedOrderform?.paymentData?.cardsOneclick === "") {
        cardsOneclickToUpdate = [];
      } else if (updatedOrderform?.paymentData?.cardsOneclick === undefined) {
        cardsOneclickToUpdate = orderform?.paymentData?.cardsOneclick;
      } else {
        cardsOneclickToUpdate = updatedOrderform.paymentData.cardsOneclick;
      }
    }
    let cardsKlap = [];
    if (orderform?.paymentData?.cardsKlap)
      cardsKlap = [...cardsKlap, ...orderform?.paymentData?.cardsKlap];
    if (updatedOrderform?.paymentData?.cardsKlap)
      cardsKlap = [...cardsKlap, ...updatedOrderform?.paymentData?.cardsKlap];
    let gifcardData = {};
    gifcardData =
      updatedOrderform?.customData?.customApps?.find(
        (customApp) => customApp.id === "giftcarddata"
      )?.fields || {};

    setOrderform({
      clientProfileData: updatedOrderform.clientProfileData,
      totalizerData: updatedOrderform.totalizers,
      shippingData: updatedOrderform.shippingData,
      corporateData: corporateData,
      marketingData: updatedOrderform.marketingData,
      paymentData: {
        cardsOneclick: cardsOneclickToUpdate,
        cardsKlap,
        ...updatedOrderform.paymentData,
      },
      configurationFiles:
        updatedOrderform.configurationFiles || orderform.configurationFiles,
      gifcardData,
      middlewarecard: middlewarecard,
      exceptions: updatedOrderform.exceptions,
      updateSelectedPaymentType: updatedOrderform.updateSelectedPaymentType,
      isLoginTemp: updatedOrderform.isLoginTemp || false,
      orderFormId: updatedOrderform?.orderForm || orderform?.orderFormId,
    });
  };

  const updateOrderFormData = (data: any) => {
    let newOrderForm = { ...orderform, ...data };
    let corporateData = newOrderForm?.corporateData;
    let middlewarecard = newOrderForm?.middlewarecard;
    const findCustomApps = newOrderForm?.customData?.customApps?.find(
      (customApp: any) => customApp.id === "clientprofiledata"
    );
    const findMiddleware = newOrderForm?.customData?.customApps?.find(
      (customApp: any) => customApp.id === "middlewarecard"
    );

    if (findCustomApps) {
      corporateData = { ...JSON.parse(findCustomApps.fields?.corporateData) };
    }

    if (findMiddleware) {
      middlewarecard = { ...findMiddleware.fields };
    }
    let gifcardData = {};
    gifcardData =
      newOrderForm?.customData?.customApps?.find(
        (customApp) => customApp.id === "giftcarddata"
      )?.fields || {};

    setOrderform({
      ...newOrderForm,
      middlewarecard,
      corporateData,
      gifcardData,
    });
  };
  const setShippingData = (shippingData: any) => {
    setOrderform({
      ...orderform,
      shippingData: shippingData,
    });
  };
  const updateLoading = (loadingStatus: boolean) => {
    setIsLoading(loadingStatus);
  };

  //loading status on orderform
  useEffect(() => {
    if (
      orderform.clientProfileData &&
      orderform.totalizerData &&
      orderform.shippingData
    ) {
      setIsLoading(false);
    }
  }, [orderform]);

  //Set of ReadyToPay status, used in Pagar button
  useEffect(() => {
    if (!Object.values(isValidSections).includes(false)) {
      setIsReadyToPay(true);
    } else {
      setIsReadyToPay(false);
    }

    // if(clientProfileValid, Shipping, Payment, termsAndConditions)
  }, [isValidSections]);

  useEffect(() => {
    if (loadingKlap) return;
    if (error?.message) {
      const timeout = setTimeout(() => {
        setError(null);
      }, error.timeOutVal || 4000);

      return () => clearTimeout(timeout);
    }
  }, [error, loadingKlap]);

  return (
    <OrderformContext.Provider
      value={{
        isValidSections,
        dataKlap,
        errorService,
        giftCard,
        isLoading,
        isReadyToPay,
        loadingKlap,
        error,
        modalAlertKlap,
        openModalCard,
        orderform,
        isLoadingSelectCard,
        isOpenWaitMessage,
        setIsOpenWaitMessage,
        activeToast,
        setDataKlap,
        setError,
        setIsLoadingSelectCard,
        setLoadingKlap,
        setOpenAlertModalKlap,
        setOpenModalCard,
        setShippingData,
        updateErrorService,
        updateGiftCard,
        updateLoading,
        updateOrderform,
        updateOrderFormData,
        updateValidSections,
      }}
    >
      {props.children}
    </OrderformContext.Provider>
  );
};

export default OrdeformProvider;
