import React, { useEffect } from 'react'
import { Button } from '@material-ui/core';
import LogoVisa from '../../../../../assets/images/LogoVisa.svg'
import LogoMaster from '../../../../../assets/images/LogoMaster.svg'
import LogoAmex from '../../../../../assets/images/LogoAmex.svg'
import LogoDiscover from '../../../../../assets/images/LogoDiscover.png'
import CloseIcon from '@material-ui/icons/Close'
import './card.styles.scss'

interface FrontProps {
  cardType: string;
  disabled?: boolean;
  dueDate?: string;
  image?: boolean;
  numberCard: string;
  numOnCard?: string;
  userInfo?: string;
  setNumberCard?: (value: string) => void;
}

export const Front = ({
  cardType,
  disabled,
  dueDate = 'MM/AA',
  image,
  numOnCard,
  numberCard,
  setNumberCard = () => { return },
  userInfo = ''
}: FrontProps) => {

  const classDisabled = `${disabled ? '__is-disabled' : ''}`
  const showUserName = false
  const cardLogo = {
    VISA: LogoVisa,
    AMEX: LogoAmex,
    MASTERCARD: LogoMaster,
    MAESTRO: LogoMaster,
    DISCOVER: LogoDiscover
  }
  useEffect(() => {
    (function () {
      let number = numOnCard
      let preview = false
      let maxLength = 19;
      let nextNumber = typeof number === 'number' ? number : number.replace(/[A-Za-z]| /g, '');
      if (isNaN(parseInt(nextNumber, 10)) && !preview) {
        nextNumber = ''
        setNumberCard('')
      }
      if (maxLength > 16) {
        maxLength = nextNumber.length <= 16 ? 16 : maxLength
      }
      if (nextNumber.length > maxLength) {
        nextNumber = nextNumber.slice(0, maxLength);

      }
      while (nextNumber.length < maxLength) {
        nextNumber += '*'
      }
      if (['amex', 'dinersclub'].includes('')) {
        const format = [0, 4, 10];
        const limit = [4, 6, 5];
        nextNumber = `${nextNumber.substring(format[0], limit[0])} ${nextNumber.substring(format[1], limit[1])} ${nextNumber.substring(format[2], limit[2])}`;
      }
      else if (nextNumber.length > 16) {
        const format = [0, 4, 8, 12];
        const limit = [4, 7];
        nextNumber = `${nextNumber.substring(format[0], limit[0])} ${nextNumber.substring(format[1], limit[0])} ${nextNumber.substring(format[2], limit[0])} ${nextNumber.substring(format[3], limit[1])}`;
      }
      else {
        for (let i = 1; i < (maxLength / 4); i++) {
          const space_index = (i * 4) + (i - 1);
          nextNumber = `${nextNumber.slice(0, space_index)} ${nextNumber.slice(space_index)}`;
        }
      }
      return setNumberCard(nextNumber);
    })()
  }, [numOnCard, setNumberCard])

  const url = cardLogo[cardType]

  return (
    <div className='card-front'>
      <div className='card-front__circle' >
        <Button>
          <CloseIcon />
        </Button>
      </div>
      <div className={`card-front__rectangle${classDisabled}`} />
      {(image && url) ?
        <div className='card-front-container-image'>
          <img src={url} alt='' className='card-front__img' />
        </div>
        :
        <h2 className='card-front__title text'>
          Nueva tarjeta
        </h2>
      }
      <div className='card-front__content-dots'>
        {numOnCard?.length > 0 ? 
        numberCard : 
        Array.from({ length: 16 }, (_, index) => {
          const spacesIn = (index + 1) % 4 === 0
          return (
            <div key={index}>
              <div className={`card-front__content-dots__dot ${spacesIn ? 'space' : ''}`} />
            </div>
          );
        })
        }
      </div>
      <div className='card-front__content__info'>
        <h3 className='card-front__name text'>
          {showUserName && userInfo ? userInfo : ''}
        </h3>
        <h3 className='card-front__due text'>
          {dueDate ? dueDate : 'MM/AA'}
        </h3>
      </div>
    </div>
  )
}
