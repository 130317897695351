import React from 'react'

export const TotalizerRow = (data: any) => {
  const {
    item,
    value,
    isTotal
  } = data;
  return (
    <div className='totalizer-row' style={isTotal ? { fontWeight: 600 } : {}} >
      <div className='totalizer-row__item'>{item}</div>
      <div className='value' style={isTotal ? { fontWeight: 600 } : {}}>{value}</div>
    </div>
  );
};