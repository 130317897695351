import React, { useState, useContext } from "react";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { useWindowSize } from "../../../../../components/windowSize/use-window-size";
import helpers from "../../../../../utils/helpers";
import { IPaymentMethod } from "../INewPayment";
import GiftCardForm from "../../GiftcardForm";
import CheckOut from "../../../services/CheckOut.service";
import { OrderformContext } from "../../../contexts/orderform.provider";
import { RuleObject } from "../../..";
import styles from "./PaymentMethodCard.module.css";
import { useInstallments } from "../../../../../utils/useInstallments";
import { BANCO_ESTADO } from "../../../../../ constants";
import { useOnOffCudardaBancoEstado } from "../../../../../utils/useContentFul";

const useStyles = makeStyles({
  root: {
    "& .Mui-checked": {
      color: "#282828 !important",
    },
  },
});

interface PaymentMethodCardProps {
  isSimple?: boolean;
  isModal?: boolean;
  payment: IPaymentMethod;
  radio?: boolean;
  blocked?: boolean;
  selected?: boolean;
  total: number;
  paymentSelected?: IPaymentMethod;
  isSelectPaymentMethod?: boolean;
  onLoading?: (loading: boolean) => void;
  handleSelectPayment?: (payment: IPaymentMethod) => void;
  setPaymentSelected?: (payment: IPaymentMethod) => void;
  setRulerObjext: React.Dispatch<React.SetStateAction<RuleObject>>;
  setBlocked?: (value: boolean) => void;
  onPaymentMethodCard: (payment: IPaymentMethod) => void;
}

const selectIconBancoEstado = (isSelectPaymentMethod: boolean) => {
  if (isSelectPaymentMethod) {
    return "bancoEstadoCard";
  }

  return helpers.formatText(BANCO_ESTADO);
};

const validIconForType = (payment: IPaymentMethod) => {
  if (
    payment?.id?.toLocaleLowerCase() === "oneclick" &&
    payment.type === "debit"
  ) {
    return "oneclickDebit";
  } else if (
    payment?.id?.toLocaleLowerCase() === "oneclick" &&
    payment.type === "credit"
  ) {
    return "oneclickCredit";
  }

  return payment?.id?.toLowerCase();
};

const PaymentMethodCard = ({
  blocked = false,
  isModal = false,
  isSimple = false,
  onPaymentMethodCard,
  payment,
  paymentSelected,
  radio = false,
  selected = false,
  total = 0,
  isSelectPaymentMethod = false,
  handleSelectPayment = () => {
    return;
  },
  setPaymentSelected = () => {
    return;
  },
  setRulerObjext = () => {
    return;
  },
  setBlocked = () => {
    return;
  },
}: PaymentMethodCardProps) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [installment, setInstallment] = useState(1);
  const windowSize = useWindowSize();
  const isMobile = windowSize?.width <= 1024;
  const hideDescriptionCard = windowSize?.width <= 380;
  const {
    updateOrderform,
    orderform,
    activeToast,
    setIsLoadingSelectCard,
    dataKlap,
    setDataKlap,
  } = useContext(OrderformContext);
  const onOrOffCucarda = useOnOffCudardaBancoEstado();
  const borderClassName =
    isSimple || (isMobile && isSimple) || !isMobile
      ? `${styles["paymentCard__container--border"]}`
      : "";
  const borderClassNameSelected = selected
    ? styles["paymentCard__container--selected"]
    : borderClassName;
  const cardClassName = payment.isCard
    ? styles["paymentCard__container--clickable"]
    : "";
  const classes = useStyles();
  const blockedGiftCar = blocked && payment.id === "giftcard";
  const isBlockedPaymentCard = blockedGiftCar ? styles["blocked-payment"] : "";
  const buttonTextOptions = isOpenForm
    ? "Cancelar"
    : !!orderform?.gifcardData?.id
      ? ""
      : "Agregar";
  const buttonText = blockedGiftCar ? "Agregar" : buttonTextOptions;
  const classNameIcon =
    payment?.id?.includes(BANCO_ESTADO) ||
    helpers.formatText(payment?.entity) === helpers.formatText(BANCO_ESTADO)
      ? selectIconBancoEstado(isSelectPaymentMethod)
      : validIconForType(payment);
  const classToCard = blockedGiftCar
    ? styles[`paymentCard__${classNameIcon}__disable`]
    : styles[`paymentCard__${classNameIcon}`];
  const classToContainer = isMobile
    ? `${styles.paymentCard__container} ${
        styles["paymentCard__container--mobile"]
      } ${isBlockedPaymentCard}
            ${borderClassNameSelected} ${
              (payment.isMenu ||
                (payment.isCard && payment.state && !isModal)) &&
              styles["paymentCard__container--modal-mobile"]
            } ${isModal && styles["paymentCard__container--modal"]} `
    : `${
        styles.paymentCard__container
      } ${borderClassNameSelected} ${cardClassName} ${isBlockedPaymentCard}
            ${isModal && styles["paymentCard__container--modal"]} `;
  const classToName = isMobile
    ? `${styles.paymentCard__name} ${styles["paymentCard__name--mobile"]} `
    : `${styles.paymentCard__name} ${
        isModal && styles["paymentCard__name--modal"]
      } `;
  const classToDescription = isMobile
    ? `${styles.paymentCard__infoText} ${styles["paymentCard__infoText--mobile"]} `
    : `${styles.paymentCard__infoText} ${
        isModal && styles["paymentCard__infoText--modal"]
      } `;
  const installmentsOptions = useInstallments();
  const amount =
    orderform?.paymentData?.paymentSystems?.find((item) =>
      payment.code.includes(item.id),
    )?.amount || total;
  const isBancoEstado = Boolean(
    (payment?.id?.includes(BANCO_ESTADO) ||
      helpers.formatText(payment?.entity) ===
        helpers.formatText(BANCO_ESTADO)) &&
      amount,
  );
  const amountEfectivo = orderform?.paymentData?.paymentSystems.find(
    (item) => item.id === parseInt(process.env.REACT_APP_CODE_PAYMENT_EFECTIVO),
  )?.amount;
  const discount = isBancoEstado
    ? Math.round(Math.abs((100 * (amountEfectivo - amount)) / amountEfectivo))
    : 0;

  const addGiftCard = () => {
    onPaymentMethodCard(payment);
    setIsOpenForm(false);
    handlePaymentMethod();
  };

  const handleCLickButton = () => {
    if (orderform?.gifcardData?.id) return;
    if (!blockedGiftCar) {
      setIsOpenForm(!isOpenForm);
      return;
    }
    activeToast({
      IconColor: "red",
      message:
        "Por seguridad la Giftcard fue bloqueada, vuelve a intentar en 30 min.",
      statusOk: false,
      timeOutVal: 5000,
    });
  };

  const handleSelectCard = async () => {
    if (isModal) return;
    const isDifferentMethod = paymentSelected?.id !== payment?.id;
    const paymentGiftCardSend = orderform?.gifcardData;
    let update = false;

    if (
      payment?.code?.includes(16) &&
      isDifferentMethod &&
      paymentGiftCardSend?.id
    ) {
      update = true;
      setIsLoadingSelectCard(true);
      const valuesGC = JSON.parse(
        helpers.getStorage("giftCardStorage") || "{}",
      );

      await CheckOut.giftCardPayment({
        ...valuesGC,
        value: orderform.totalizerData.totalPayment.total,
        orderForm: orderform?.orderFormId,
      });
    }
    if (!isMobile && payment.isCard) {
      update = true;
      setIsLoadingSelectCard(true);
      const newPaymentMethod = { ...payment, installments: installment };
      setPaymentSelected(newPaymentMethod);
      handleSelectPayment(newPaymentMethod);
      let typePayment =
        payment?.code?.includes(202) || payment?.code?.includes(204)
          ? "oneClick"
          : "Klap";
      if (
        payment?.code?.includes(
          parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_CREDIT),
        ) ||
        payment?.code?.includes(
          parseInt(process.env.REACT_APP_CODE_BANCO_ESTADO_DEBIT),
        )
      ) {
        typePayment = "Banco_Estado";
      }

      await Promise.all([
        CheckOut.updatePayment({ paymentSystem: payment?.code[0] }),
        CheckOut.updateSelectedPaymentType({
          typeCard: payment?.type?.toLowerCase(),
          idCard: payment.paymentId,
          typePayment,
        }),
      ]);
    }

    if (update) {
      await CheckOut.ApiCheckout().then((response) => {
        setIsLoadingSelectCard(false);
        updateOrderform(response.data);
      });
    }
  };

  const handlePaymentMethod = () => {
    onPaymentMethodCard(payment);
    handleSelectCard();
  };

  const handleInstallmentChange = (event) => {
    const installment = event.target.value;
    setDataKlap((prevState) => ({ ...prevState, installments: installment }));
    setInstallment(installment);
  };

  const renderedItems = installmentsOptions.map(
    ({ id, value }: { id: number; value: number }) => {
      if ([0, 1].includes(value)) {
        return (
          <MenuItem key={id} value={null}>
            Sin cuotas
          </MenuItem>
        );
      }

      return (
        <MenuItem key={id} value={value}>
          {value} cuotas
        </MenuItem>
      );
    },
  );

  return (
    <div
      className={classToContainer}
      onClick={() => {
        if (!blockedGiftCar) {
          handlePaymentMethod();
        }
      }}
      id={`card__${payment.id}--${selected}`}
    >
      <div className={styles.paymentCard__info}>
        <div className={styles.paymentCard__paymentIcon}>
          <div className={classToCard} />
        </div>
        <div className={styles.paymentCard__containerTexts}>
          <div className={styles.paymentCard__detail}>
            <div className={styles.paymentCard__containerName}>
              <p className={classToName}>{payment?.name}</p>
              {isBancoEstado && Boolean(discount) && onOrOffCucarda && (
                <div className={styles.content_promotions}>-{discount}%</div>
              )}
              {isMobile && payment.description && !isSimple && (
                <div className={styles.paymentCard__infoIcon} />
              )}
              {!isMobile && payment?.info && (
                <div className={styles.paymentCard__infoIcon} />
              )}
            </div>
            <div className={styles.paymentCard__splitPayment}>
              {(payment.description || payment?.isCard) &&
              payment?.code.includes(205) &&
              !isModal ? (
                <>
                  {!hideDescriptionCard && payment?.description && (
                    <p className={classToDescription}>
                      {payment?.description} |{" "}
                    </p>
                  )}
                  <p className={classToDescription}>Cuotas:</p>
                  <Select
                    onClick={(event) => event.stopPropagation()}
                    labelId={`card-${payment.id}-installments`}
                    id={`card-${payment.id}-id`}
                    value={dataKlap?.installments || 1}
                    fullWidth={false}
                    name={`card-${payment.id}`}
                    onChange={handleInstallmentChange}
                    renderValue={(selected) => {
                      let selectedLabel = installmentsOptions.find(
                        ({ id }) => id === selected,
                      )?.id;
                      if ([0, 1].includes(selectedLabel))
                        selectedLabel = "Sin cuotas";
                      return `${selectedLabel || ""}`;
                    }}
                  >
                    {renderedItems}
                  </Select>
                </>
              ) : (
                <p className={classToDescription}>{payment?.description}</p>
              )}
            </div>
          </div>
          <div className={styles.paymentCard__containerName}>
            {(radio || payment?.total) && (
              <p
                className={`${styles.paymentCard__price} ${
                  isModal
                    ? styles["paymentCard__price--modal"]
                    : styles["paymentCard__price--mobile"]
                } ${amount < total && styles["paymentCard__price--discount"]}`}
              >
                {`$${helpers.priceFilter(amount)} `}
              </p>
            )}
            {payment?.isMenu && (
              <div
                className={`${styles.paymentCard__arrowRightIcon} ${styles["paymentCard__arrowRightIcon--mobile"]}`}
                onClick={handlePaymentMethod}
              />
            )}
            {!payment?.isMenu && !radio && !payment.total && (
              <p
                className={
                  isOpenForm
                    ? styles.paymentCard__cancel
                    : styles.paymentCard__add
                }
                onClick={handleCLickButton}
              >
                {buttonText}
              </p>
            )}
            {radio && (
              <FormControl component="fieldset" className={classes.root}>
                <RadioGroup
                  aria-label="radio-button-despacho"
                  name="radio-button-despacho"
                >
                  <Radio
                    checked={selected}
                    name="radio-button-despacho"
                    onClick={handlePaymentMethod}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </div>
        </div>
      </div>
      {payment?.id === "giftcard" && (
        <GiftCardForm
          activeToast={activeToast}
          blocked={blocked}
          onPaymentMethodCard={onPaymentMethodCard}
          handleSelectPayment={handleSelectPayment}
          isMobile={isMobile}
          isOpenGiftcard={isOpenForm}
          onAddGiftCard={addGiftCard}
          setBlocked={setBlocked}
          setIsOpenForm={setIsOpenForm}
          setPaymentSelected={setPaymentSelected}
          setRulerObjext={setRulerObjext}
          payment={payment}
        />
      )}
    </div>
  );
};

export default PaymentMethodCard;
