import React from 'react'
import Routes from './routes/routes'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-46206544-6')
const tagManagerArgs = {
    gtmId: 'GTM-TSJPRBR'
}

TagManager.initialize(tagManagerArgs)

const App = () => (
    <Routes />
)

export default App
